import React from 'react'
import { colorCodes } from '../../../constant/colors'

const RightArrow = ({
    fill = colorCodes.BLUE_3,
    height = 13.992,
    width = 8,
    style = {},
    viewBox = '0 0 8 13.992',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <path d="M16.835,13.19,11.54,7.9a1,1,0,0,1,0-1.412,1.008,1.008,0,0,1,1.416,0l6,5.995a1,1,0,0,1,.029,1.379L12.961,19.9a1,1,0,1,1-1.416-1.412Z" transform="translate(-11.246 -6.196)" fill={fill} />
    </svg>
)

export default RightArrow