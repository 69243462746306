import LabelSelectBox from "../../common/LabelSelectBox"

const ReduxSelectField = ({
    isSearchable = false, handleOnChange,
    input, label, options, disabled = false, readOnly = false,
    meta: { touched, visited, active, error }, parentClassName = '',
}) => (
    <LabelSelectBox label={label} options={options}
        errClass={((!active && (visited || touched)) && error) ? 'error_border' : ''}
        errMsg={((!active && (visited || touched)) && error) ? error : ''}
        active={active}
        isSearchable={isSearchable}
        parentClassName={parentClassName}
        handleOnChange={param => {
            !!handleOnChange && handleOnChange(param)
            input.onChange(param.value);
        }}
        disabled={disabled}
        readOnly={readOnly}
        {...input}
    />
)

export default ReduxSelectField