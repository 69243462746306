import { useState, useEffect } from "react";
import { fromLonLat } from "ol/proj";
import MVT from "ol/format/MVT";
import * as olSource from "ol/source"; //sources
import VectorTileSource from "ol/source/VectorTile"; //sources

//Map Components
import { Map } from "../../common/Map/Map";
import Layers from "../../common/Layers/Layers";
import TileLayer from "../../common/Layers/TileLayer";
import VectorTileLayer from "../../common/Layers/VectorTileLayer";
import Controls from "../../common/Controls/Control";
import FullScreenControl from "../../common/Controls/FullScreenControl";
// import FeatureStyles from "../../common/Features/Style";
import OverlayDiv from "../../common/Overlay/OverlayDiv";
import { mapAttributeFilter } from "../../constant/helpers";
import { mapList } from "./MapWidget";

//Material UI
import Box from "@mui/material/Box";

//constant Data
import MapInput from "./MapInput";
import './index.scss';

//Redux
import { useSelector } from "react-redux";
import { REACT_APP_API_URL, REACT_APP_GEOSERVER_URL } from "../../constant";
import MapWidget from "./MapWidget";
import NetworkWidget from "./NetworkWidget";
import Settings from "../Settings";

export function Home() {

  //let showLayer1 = false;
  //const showLayer2 = false;
  //const showLayer3 = false;
  const testStyle = 0;
  // const [defaultColor, setDefaultColor] = useState(null);

  const { settingPage } = useSelector(state => state.settings);
  const map_overlay = useSelector(state => state.roadReducer.map_overlay);
  const roadName = useSelector(state => state.roadReducer.road_name);
  const startDis = useSelector(state => state.roadReducer.start_distance);
  const endDis = useSelector(state => state.roadReducer.end_distance);
  const strategyObjectList = useSelector(state => state.strategies.strategyObjectList);
  const hovedPlanSection = useSelector(state => state.plans.selectedPlanSections);
  const { mapLatLong, mapZoom } = useSelector(state => state.global);
  const { mapType } = useSelector(state => state.global);

  console.log("hovedPlanSection ", hovedPlanSection)
  const [rldLayer, setRldLayer] = useState(false);
  const [visualLayer, setVisualLayer] = useState(false);
  const [mobileeyeLayer, setMobileeyeLayer] = useState(false);
  const [roadSearchlayer, setRoadSearchlayer] = useState(false);
  const [strategy, setStrategy] = useState(false);
  const [_10698Layer, set_10698Layer] = useState(false);
  const [_1081Layer, set_1081Layer] = useState(false);
  const [_10945Layer, set_10945Layer] = useState(false);
  const [_1114Layer, set_1114Layer] = useState(false);
  const [_1216Layer, set_1216Layer] = useState(false);
  const [_2021Layer, set_2021Layer] = useState(false);
  const [_4623Layer, set_4623Layer] = useState(false);
  const [_5178Layer, set_5178Layer] = useState(false);
  const [_5264Layer, set_5264Layer] = useState(false);
  const [_5555Layer, set_5555Layer] = useState(false);
  const [_86Layer, set_86Layer] = useState(false);
  const [_9500Layer, set_9500Layer] = useState(false);
  const [_9502Layer, set_9502Layer] = useState(false);
  const [_1Layer, set_1Layer] = useState(false);
  const [_9537Layer, set_9537Layer] = useState(false);
  const [_11801Layer, set_11801Layer] = useState(false);
  const [hovedPlanLayer, setHovedPlanLayer] = useState(false);
  const [baseLayerSource, setBaseLayerSource] = useState('');


  const LayerObject =
  {
    "rldLayer": {
      layer: rldLayer,
      url: 'mv_measurement_values_1111_new@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "visualLayer": {
      layer: visualLayer,
      url: 'mv_measurement_values_1112_new@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "mobileeyeLayer": {
      layer: mobileeyeLayer,
      url: 'mv_measurement_values_1113_new@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "roadSearchlayer": {
      layer: roadSearchlayer,
      url: 'mv_road_address_search@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_10698Layer": {
      layer: _10698Layer,
      url: 'mv_road_attributes_10698@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_1081Layer": {
      layer: _1081Layer,
      url: 'mv_road_attributes_1081@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_10945Layer": {
      layer: _10945Layer,
      url: 'mv_road_attributes_10945@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_1114Layer": {
      layer: _1114Layer,
      url: 'mv_road_attributes_1114@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_1216Layer": {
      layer: _1216Layer,
      url: 'mv_road_attributes_1216@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_2021Layer": {
      layer: _2021Layer,
      url: 'mv_road_attributes_2021@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_4623Layer": {
      layer: _4623Layer,
      url: 'mv_road_attributes_4623@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_5178Layer": {
      layer: _5178Layer,
      url: 'mv_road_attributes_5178@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_5264Layer": {
      layer: _5264Layer,
      url: 'mv_road_attributes_5264@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_5555Layer": {
      layer: _5555Layer,
      url: 'mv_road_attributes_5555@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_86Layer": {
      layer: _86Layer,
      url: 'mv_road_attributes_86@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_9500Layer": {
      layer: _9500Layer,
      url: 'mv_road_attributes_9500@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    // HINT: Map Layers not found
    "_9502Layer": {
      layer: _9502Layer,
      url: 'mv_road_attributes_9502@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_1Layer": {
      layer: _1Layer,
      url: 'mv_road_attributes_1@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_9537Layer": {
      layer: _9537Layer,
      url: 'mv_road_attributes_9537@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "_11801Layer": {
      layer: _11801Layer,
      url: 'mv_road_attributes_11801@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "strategy": {
      layer: strategy,
      url: 'mv_measurement_combined_good_bad@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    },
    "hovedPlan": {
      layer: hovedPlanLayer,
      url: 'mv_rmms_maintenanceplan_section@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf'
    }

  };

  // const attributions =
  //   '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
  //   '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

  const ListLayerSetter = [setRldLayer, setVisualLayer, setMobileeyeLayer, setRoadSearchlayer, set_10698Layer, set_1081Layer, set_10945Layer,
    set_1114Layer, set_1216Layer, set_2021Layer, set_4623Layer, set_5178Layer, set_5264Layer, set_5555Layer, set_86Layer, set_9500Layer, set_9502Layer, set_1Layer, set_9537Layer, set_11801Layer, setStrategy, setHovedPlanLayer];

  function setLayerOn(layer) {
    for (let index = 0; index < ListLayerSetter.length; index++) {
      if (layer === ListLayerSetter[index]) ListLayerSetter[index](true);
      else ListLayerSetter[index](false);
    }
  }


  useEffect(() => {
    switch (map_overlay.toString()) {
      case "RL(d)":
        setLayerOn(setRldLayer);
        break;
      case "Visual":
        setLayerOn(setVisualLayer);
        break;
      case "MobilEye":
        setLayerOn(setMobileeyeLayer);
        break;
      case "roadSearch":
        setLayerOn(setRoadSearchlayer);
        break;
      case "10698":
        setLayerOn(set_10698Layer);
        break;
      case "1081":
        setLayerOn(set_1081Layer);
        break;
      case "10945":
        setLayerOn(set_10945Layer);
        break;
      case "1114":
        setLayerOn(set_1114Layer);
        break;
      case "1216":
        setLayerOn(set_1216Layer);
        break;
      case "2021":
        setLayerOn(set_2021Layer);
        break;
      case "4623":
        setLayerOn(set_4623Layer);
        break;
      case "5178":
        setLayerOn(set_5178Layer);
        break;
      case "5264":
        setLayerOn(set_5264Layer);
        break;
      case "5555":
        setLayerOn(set_5555Layer);
        break;
      case "86":
        setLayerOn(set_86Layer);
        break;
      case "9500":
        setLayerOn(set_9500Layer);
        break;
      // HINT: Map Layers not found
      case "9502":
        setLayerOn(set_9502Layer);
        break;
      case "1":
        setLayerOn(set_1Layer);
        break;
      case "9537":
        setLayerOn(set_9537Layer);
        break;
      case "11801":
        setLayerOn(set_11801Layer);
        break;
      case "strategy":
        setLayerOn(setStrategy);
        break;
      case "hovedPlan":
        setLayerOn(setHovedPlanLayer);
        break;
      default:
        console.log(`No maplayer found ${map_overlay}`);
        setLayerOn("NoLayer");
    }

    // eslint-disable-next-line
  }, [map_overlay]);

  useEffect(() => {
    if (mapType === 'default') {
      setBaseLayerSource(new olSource.OSM());
    }
    else {
      const baseLayerUrl = mapList.find((item) => item.id === mapType).apiUrl;
      setBaseLayerSource(new olSource.XYZ({
        //url: baseLayerUrl + '?key=' + process.env.REACT_APP_MAPTILER_KEY, //This needs to be implemented after DEMO
        url: baseLayerUrl + '?key=yAc0z88j4aRTqueGjZxB',
        maxZoom: 22,
      }));
    }
  }, [mapType]);


  return (
    !!mapLatLong && !!mapZoom &&
    <Box sx={{ display: 'grid', flexGrow: 1, alignItems: "center" }}>
      <div className="home-container ">
        <Map center={fromLonLat(mapLatLong)} zoom={mapZoom}>
          <Layers>
            {/* <TileLayer source={new olSource.OSM()} zIndex={0} /> */}
            <TileLayer source={baseLayerSource} zIndex={0}></TileLayer>
            <OverlayDiv />
            {Object.keys(LayerObject).map((key) => {
              return (LayerObject[key]["layer"] ?
                <VectorTileLayer
                  key={key}
                  source={{
                    "format": new MVT(),
                    "url": `${REACT_APP_API_URL.replace('/graphql', '/geoserver')}/${LayerObject[key]["url"]}`,
                    // "url": `${REACT_APP_GEOSERVER_URL}/${LayerObject[key]["url"]}`,

                  }}
                  style={(feature) => {
                    // setDefaultColor(FeatureStyles.Polygon_geoserver_green);
                    const formParameterObject = { roadname: roadName, start_distance: startDis, end_distance: endDis };
                    return mapAttributeFilter(feature, map_overlay, formParameterObject, strategyObjectList, hovedPlanSection);
                  }}
                  setStyle={testStyle}
                />
                :
                ""
              );
            })}
          </Layers>
          <Controls>
            <FullScreenControl />
          </Controls>
          <MapWidget />
          <NetworkWidget />
        </Map>
      </div>
      {!!settingPage ? <Settings /> : <MapInput />}
    </Box>
  );
}
