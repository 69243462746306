import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import LabelInput from "../../../components/common/LabelInput";
import SpinLoader from "../../../components/common/SpinLoader";
import WaywizeButton from "../../../components/common/WaywizeButton";
import { handleRequest } from "../../../constant/helpers";
import { changeMapOverlay, changeRoadName, showRoadOnMap } from "../../../actions/roads";
import { setMapZoom } from "../../../actions/global";
import "./index.scss";

const RoadForm = () => {
  const [road, setRoad] = useState('');
  //const [roadName, setRoadName] = useState('');
  const section = ''
  // const [section, setSection] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [roadArr, setRoadsArr] = useState([]);

  const roadName = useSelector(state => state.roadReducer.road_name);

  let dispatch = useDispatch();
  const { t } = useTranslation()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(changeMapOverlay('roadSearch'))
    dispatch(setMapZoom())
    try {
      const resp = await handleRequest("post", false, {
        query: `
          query roadSearch($roadSearchParameter: roadSearchParameter!) {
            roadSearch(roadSearchParameter: $roadSearchParameter) {
              roadname,
                road,
                section
            }
          }`,
        variables: {
          roadSearchParameter: {
            "roadname": roadName,
            ...(!!road && { road }),
            ...(!!section && { section })
          }
        }
      });

      setIsLoading(false);
      if (!!resp && !!resp.roadSearch) {
        // setRoadsArr(resp.roadSearch);
        if (roadName) {
          dispatch(showRoadOnMap(roadName))
        }
        toast.info(`${resp.roadSearch.length} Results`);
      } else {
        toast.info(`0 Result`);
        // setRoadsArr([]);
      }
    } catch (err) {
      console.log('Road Submit Search Error:', err);
      setIsLoading(false);
    }
  };

  const handleRoadInput = (e, key) => {
    const { value } = e.target;
    if (key === 'road') {
      setRoad(value);
    } else if (key === 'roadName') {
      console.log(value);
      dispatch(changeRoadName(value));
    }
  };

  // const isValid = (!!roadName) || (!!road && !!section);

  return (
    <>
      <form onSubmit={handleSubmit} className="form-card-container center-flex">
        {/*<div className="mb-2 optional-input-holder">
          <LabelInput label="Road" value={road} onChange={e => handleRoadInput(e, "road")}
            readOnly={!!roadName} disabled={!!roadName} placeholder="Ex. RV36" />
          <LabelInput label="Section" value={section} onChange={e => setSection(e.target.value)}
            readOnly={!!roadName} disabled={!!roadName} placeholder="Ex. S5D1" />
        </div>
  <div className="center-flex mt-2 or-div">OR</div>*/}
        {isLoading && <SpinLoader />}
        <LabelInput label={t('form_input.rd_name')} value={roadName} onChange={e => handleRoadInput(e, "roadName")}
          readOnly={!!road} disabled={!!road} placeholder={t('form_input.rd_name_ex')} />
        {/* <WaywizeButton type="submit" title={isLoading ? "Loading..." : "Search"} disabled={!isValid} /> */}
        <WaywizeButton type="submit" className="mt-17" title={isLoading ? `${t("button.loading")}...` : t("button.search")} />
      </form>
    </>
  );
};

export default RoadForm;;