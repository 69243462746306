import { MOVEABLE_TOP_POS } from "../constant"

export const SET_ACTIVE_NAV = 'SET_ACTIVE_NAV'
export const SET_CLICKED_NAVS = 'SET_CLICKED_NAVS'
export const SET_MENU_STATE = 'SET_MENU_STATE'

export const setActiveNav = active => ({
  type: SET_ACTIVE_NAV,
  payload: { active }
})

export const setClickedNavs = clickedNavs => ({
  type: SET_CLICKED_NAVS,
  payload: { clickedNavs }
})

export const setMenuState = menuState => ({
  type: SET_MENU_STATE,
  payload: { menuState }
})

export const reArrangeIconPos = () => {
  setTimeout(() => {
    const allMinimizeDiv = document.getElementsByClassName('moveable-window-container')
    let counter = 0
    for (let i = 0; i < allMinimizeDiv.length; i++) {
      if (allMinimizeDiv[i].classList.contains('hide-info-tile')) {
        allMinimizeDiv[i].style.top = MOVEABLE_TOP_POS + (60 * counter) + 'px'
        counter++;
      } else {
        allMinimizeDiv[i].style.top = `${MOVEABLE_TOP_POS}px`
      }
    }
  }, 700)
}

export const onClickNavMenu = (val, clickedNavs) => dispatch => {
  const clickeNavList = clickedNavs.includes(val) ? clickedNavs : [...clickedNavs, val]
  const menuStatus = clickedNavs.filter(c => c !== val).map(c => ({ name: c, status: false }))
  dispatch(setClickedNavs(clickeNavList))
  dispatch(setActiveNav(val))
  dispatch(setMenuState([...menuStatus, { name: val, status: true }]))
  reArrangeIconPos()
}