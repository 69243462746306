import React from 'react'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import Icon from '../Icons'
import './index.scss'

const DropdownIndicator = ({ selectProps, ...rest }) => (
    <components.DropdownIndicator {...rest}>
        <Icon name='caret' className={!selectProps.menuIsOpen ? 'caret-down' : ''} />
    </components.DropdownIndicator>
)

const LabelSelectBox = ({
    name,
    label,
    value,
    errClass = '',
    errMsg = '',
    options = [],
    isMulti = false,
    placeholder = '',
    disabled = false,
    isSearchable = false,
    isClearable = false,
    className = '',
    classNamePrefix = '',
    parentClassName = '',
    handleOnChange
}) => {
    const { t } = useTranslation()
    return (
        <div className={`waywize-select-input-container ${parentClassName} ${errClass}`}>
            {!!label && <label className="waywize-select-input-label" htmlFor={name}>{(label + (!!errMsg ? ' - ' + t(errMsg) : ''))}</label>}
            <Select
                isDisabled={disabled}
                isClearable={isClearable}
                components={{ DropdownIndicator }}
                name={name} className={className}
                placeholder={placeholder || label}
                isMulti={isMulti} isSearchable={isSearchable}
                classNamePrefix={`waywize-react-select ${classNamePrefix}`}
                value={(!!value && !!options) ? options.find(opt => (opt.value).toString() === (value).toString()) : ''}
                onChange={e => !!handleOnChange && handleOnChange(e)}
                options={options}
            />
            {!!errMsg && <span className="err-msg-span">{t(errMsg)}</span>}
        </div>
    )
}

export default LabelSelectBox