import { useTranslation } from "react-i18next"
import { Field } from "redux-form"
import { connect, useDispatch, useSelector } from "react-redux"
import { reduxForm } from "redux-form"
import { updateLimits } from "../../../actions/settings"
import SpinLoader from "../../../components/common/SpinLoader"
import { required } from "../../../components/redux-form/validations"
import ReduxLabelInput from "../../../components/redux-form/ReduxLabelInput"
import WaywizeButton from "../../../components/common/WaywizeButton"
import "./index.scss"

const EditUC = ({ submitting, handleSubmit, valid, onSuccess }) => {
  const isValid = (submitting || valid);
  const { isProcess } = useSelector(state => state.settings);
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    dispatch(updateLimits(data, onSuccess))
  }

  return (
    <div className="d-flex ai-center">
      {!!isProcess && <SpinLoader />}
      <form onSubmit={handleSubmit(onSubmit)} className="limit-form-container center-flex">
        <div className="d-flex pt-20">
          <Field name="value" label={t('settings.form.value')} type="number" component={ReduxLabelInput} placeholder="Ex. 3000" validate={[required]} />
        </div>
        <WaywizeButton type="submit" title={(!!isProcess || submitting) ? `${t('button.loading')}...` : t('button.update')} className="limit-form-submit-btn" disabled={!isValid} />
      </form>
    </div>
  )
}

export default connect((state, ownProps) => {
  return {
    initialValues: {
      ...ownProps.info
    }
  }
}, null)(reduxForm({
  form: 'edit-limit-form',
  enableReinitialize: true
})(EditUC))