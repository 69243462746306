import { connect, useDispatch } from "react-redux"
import { reduxForm } from "redux-form"
import { editUnitCost } from "../../../actions/unitCost"
import UCForm from "../UCForm"

const EditUC = ({ submitting, handleSubmit, valid, onSuccess }) => {
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    const { id, unit_cost } = data
    dispatch(editUnitCost({ id, unit_cost }, onSuccess))
  }

  return (
    <UCForm
      submitting={submitting}
      valid={valid}
      isEdit={true}
      handleSubmit={handleSubmit(onSubmit)} />
  )
}

export default connect((state, ownProps) => {
  return {
    initialValues: {
      ...ownProps.info
    }
  }
}, null)(reduxForm({
  form: 'edit-uc-form',
  enableReinitialize: true
})(EditUC))