import { handleRequest } from "../constant/helpers"
import { setMapLatLong } from "./global"

export const SET_MEASURMENT_RD_ARR = "SET_MEASURMENT_RD_ARR"

export const setMeasurementRdArr = roadArr => ({
    type: SET_MEASURMENT_RD_ARR,
    payload: { roadArr }
})

/**
 * Show Road Measurment On Map
 * @param {*} variable 
 * @param {*} roadName 
 * @param {*} startDis 
 * @param {*} endDis 
 * @returns 
 */
export const showRoadMeasurmentOnMap = (variable, roadName = false, startDis = false, endDis = false) => async (dispatch) => {
    try {
        const resp = await handleRequest("post", false, {
            query: `query getCoordinatesForRoadMeasurement($data: coordinateMeasurementInput) {
                getCoordinatesForRoadMeasurement(data: $data) {
                  x_coordinate
                  y_coordinate
                }
              }`,
            variables: {
                data: {
                    variable: variable.trim(),
                    ...(!!roadName && { "roadname": roadName }),
                    ...(!!startDis && { "start_distance": startDis }),
                    ...(!!endDis && { "end_distance": endDis }),
                }
            }
        })

        if (!!resp && !!resp.getCoordinatesForRoadMeasurement) {
            const { x_coordinate, y_coordinate } = resp.getCoordinatesForRoadMeasurement
            dispatch(setMapLatLong([x_coordinate, y_coordinate], 15))
        }
    } catch (err) {
        console.log('showRoadMeasurmentOnMap Error:', err);
    }
}