import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { useTranslation } from 'react-i18next'
import SpinLoader from "../../../components/common/SpinLoader";
import WaywizeButton from "../../../components/common/WaywizeButton";
import ReduxSelectField from "../../../components/redux-form/ReduxSelectField";
import ReduxLabelInput from "../../../components/redux-form/ReduxLabelInput";
import { jobTitels } from "../../../constant";
import { required, email } from "../../../components/redux-form/validations";
import { capitalizeWords } from "../../../constant/helpers";
import "./index.scss";

const UserForm = ({ submitting, handleSubmit, isLoading = false, isEdit = false }) => {
  const { isProcessing } = useSelector(state => state.users)
  const { roleList } = useSelector(state => state.global)
  const { t } = useTranslation()

  return (
    <div className="d-flex ai-center">
      {!!isProcessing && <SpinLoader />}
      <form onSubmit={handleSubmit} className="user-form-container center-flex">
        <div className="d-flex pt-20">
          <Field name="user_email" label={t('users.form.email')} type="email" disabled={isEdit} readOnly={isEdit} component={ReduxLabelInput} validate={[required, email]} />
        </div>
        <div className="d-flex">
          <Field name="job_title" label={t('users.form.job_title')} component={ReduxSelectField} options={jobTitels.map(j => ({ label: t(j), value: t(j) }))} validate={[required]} />
        </div>

        <div className="d-flex">
          <Field name="role_id" label={t('users.form.role')} component={ReduxSelectField} options={roleList.map(j => ({ label: capitalizeWords((j.user_type).replace("_", " ")), value: t(j.id) }))} validate={[required]} />
        </div>
        <WaywizeButton type="submit" title={(isLoading || submitting) ? `${t('button.loading')}...` : (!!isEdit ? t('button.update') : t('button.save'))} className="user-form-submit-btn" />
      </form>
    </div>
  )
}

export default UserForm