import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../Icons'
import "./index.scss"

const LabelInput = ({
    id = '', label = 'Label', className = '', min = "0", max = "1000000000",
    errClass = '', errMsg = '', onFocus, onBlur, showLabel = true,
    step = "any", type = 'text', value, onClick, onChange, required = false,
    disabled = false, readOnly = false, placeholder = '', helpText = false
}) => {
    const wrapperRef = useRef()
    const { t } = useTranslation()
    const [showHelpText, setShowHelpText] = useState(false)

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (!!wrapperRef.current) &&
            !wrapperRef.current.contains(event.target)) {
            setShowHelpText(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <label htmlFor={id} className={`waywize-label-input ${className} ${errClass}`}
            onClick={(e) => {
                !!onClick && onClick(e)
            }}>{showLabel && (<div className='label-info between-flex'>
                <div className={`ellipsised-label ${helpText ? 'help-txt-also' : ''}`}>{label + (!!errMsg ? ' - ' + t(errMsg) : '')}</div>
                {!!helpText && <>
                    <Icon name="question-icon" className="help-text-icon" onClick={() => setShowHelpText(!showHelpText)} />
                    {!!showHelpText && <div className={`help-text-content`} ref={wrapperRef}>{t(helpText)}</div>}
                </>}</div>)}
            {
                type === 'number' ? <input id={id} type='number' min={min} max={max} value={value || min}
                    disabled={disabled} readOnly={readOnly} placeholder={placeholder}
                    step={step} onChange={onChange} required={required} /> :
                    <input id={id} type={type} value={value || ''} onFocus={onFocus} onBlur={onBlur}
                        disabled={disabled} readOnly={readOnly} placeholder={placeholder}
                        onChange={onChange} required={required} max={type === "date" ? "2099-12-31" : ""} />
            }
            {!!errMsg && <span className="err-msg-span">{t(errMsg)}</span>}
        </label>
    )
}

export default LabelInput