import {
  SET_AUTO_OUT, SET_GLOBAL_IS_PROCESS, SET_IS_LICENSE, SET_LANGUAGE, SET_MAP_LAT_LONG,
  SET_MAP_TYPE, SET_MAP_ZOOM, SET_ROLES_ARR, SET_STATUS_ARR, SET_USER_INFO
} from '../actions/global';
import { NORWAY_CORDI } from '../constant';

const defaultLang = localStorage.getItem('rmms-lang');
const defaultState = {
  isProcess: false,
  isLicense: null,
  autoOut: false,
  userInfo: {},
  lang: !!defaultLang ? defaultLang : 'US',
  mapType: 'default',
  roleList: [],
  statusList: [],
  mapZoom: 7,
  mapLatLong: NORWAY_CORDI //HINT: We're using by Default Norway Lat long
};

const Global = (state = defaultState, action) => {
  switch (action.type) {
    case SET_GLOBAL_IS_PROCESS:
    case SET_IS_LICENSE:
    case SET_MAP_LAT_LONG:
    case SET_MAP_ZOOM:
    case SET_AUTO_OUT:
    case SET_USER_INFO:
    case SET_LANGUAGE:
    case SET_MAP_TYPE:
    case SET_ROLES_ARR:
    case SET_STATUS_ARR:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export default Global;
