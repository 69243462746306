
import { SET_SETTING_IS_PROCESS, SET_SETTING_PAGE, SET_LIMIT_SETTINGS } from '../actions/settings'

const defaultState = {
  isProcess: false,
  settingPage: false,
  limitSettings: []
}

const Settings = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SETTING_IS_PROCESS:
    case SET_SETTING_PAGE:
    case SET_LIMIT_SETTINGS:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default Settings
