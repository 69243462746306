import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactFlagsSelect from 'react-flags-select'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import msalObj from '../../../services/MsalService'
import Icon from '../../../components/common/Icons'
import { setLanguage } from '../../../actions/global'
import { setActiveNav, setClickedNavs, setMenuState } from '../../../actions/navbar'
import { setSettingPage } from '../../../actions/settings'
import './index.scss'

export function ProfileSection() {
    const wrapperRef = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { lang, userInfo } = useSelector(state => state.global);
    const { t, i18n } = useTranslation()

    const [isActive, setIsActive] = useState(false)

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null) &&
            !wrapperRef.current.contains(event.target)) {
            setIsActive(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        i18n.changeLanguage(lang === 'US' ? 'en' : 'no');
    }, [i18n, lang])

    const handleSettingClick = () => {
        dispatch(setClickedNavs([]))
        dispatch(setActiveNav(false))
        dispatch(setMenuState([]))
        dispatch(setSettingPage(true))
        setIsActive(false)
    }

    const handleLogout = () => {
        msalObj.logoutRedirect()
        localStorage.clear()
    }

    const handleUsersClick = () => {
        navigate('/rmms/users')
        dispatch(setClickedNavs([]))
        dispatch(setActiveNav(false))
        setIsActive(false)
    }

    const handleSettingBtn = () => {
        navigate('/rmms')
        handleSettingClick(true)
    }

    return (
        <div className='profile-section-container' ref={wrapperRef}>
            <div>
                <ReactFlagsSelect
                    selected={lang}
                    onSelect={code => dispatch(setLanguage(code))}
                    showSelectedLabel={true}
                    showSecondarySelectedLabel={true}
                    showOptionLabel={true}
                    showSecondaryOptionLabel={true}
                    placeholder={"Language"}
                    searchable={false}
                    customLabels={{ "US": { secondary: "Eng" }, "NO": { secondary: "Nor" } }}
                    countries={["US", "NO"]}
                    className='language-selector'
                />
            </div>
            <div id="profile-section" onClick={() => setIsActive(!isActive)}>
                <Icon name='user' height={28} width={28} />
            </div>
            <div className={`profile-menu-item-holder ${isActive ? 'is-active' : ''}`}>
                <div className='profile-menu-item'>
                    <div className='profile-name-title'>{userInfo.name || userInfo.email}</div>
                    <div className='profile-name-desg'>{userInfo.job_title || '-'}</div>
                </div>
                <div className='profile-menu-item' onClick={() => handleSettingBtn()}>{t("navbar.profile.setting")}</div>
                {
                    !!userInfo && !!userInfo.role_id && (userInfo.role_id === 1) &&
                    <div className='profile-menu-item' onClick={() => handleUsersClick()}>{t("navbar.profile.users")}</div>
                }
                <div className='profile-menu-item' onClick={() => handleLogout()}>{t("navbar.profile.logout")}</div>
            </div>
        </div>
    )
}
