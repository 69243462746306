import { useDispatch, useSelector } from "react-redux";
import { Field } from "redux-form";
import { useTranslation } from 'react-i18next'
import SpinLoader from "../../../../components/common/SpinLoader";
import ReduxLabelInput from "../../../../components/redux-form/ReduxLabelInput";
import WaywizeButton from "../../../../components/common/WaywizeButton";
import ReduxSelectField from "../../../../components/redux-form/ReduxSelectField";
import LabelTextArea from "../../../../components/common/LabelTextArea";
import ReduxCheckBox from "../../../../components/redux-form/ReduxCheckBox";
import { required } from "../../../../components/redux-form/validations";
import { showPlanOnMap } from "../../../../actions/plans";
import "./index.scss";

const renderTextArea = ({
  input, label, type,
  handleOnFocus, handleOnBlur,
  placeholder, disabled = false, readOnly = false,
  meta: { touched, visited, active, error }
}) => (
  <LabelTextArea label={label} type={type}
    errClass={((!active && (visited || touched)) && error) ? 'error_border' : ''}
    errMsg={((!active && (visited || touched)) && error) ? error : ''}
    placeholder={placeholder} rows={"unset"}
    active={active}
    onChange={param => {
      input.onChange(param.target.value);
    }}
    onFocus={e => {
      input.onFocus(e);
      !!handleOnFocus && handleOnFocus(e);
    }}
    onBlur={e => {
      input.onChange((e.target.value).trim());

      // HINT: capitalizeOnBlur function taking some time in calculation that's why we added setTimeOut
      input.onBlur(e);
      !!handleOnBlur && handleOnBlur(e);
    }}
    disabled={disabled}
    readOnly={readOnly}
    {...input} />
);

const PlanSectionForm = ({
  submitting, handleSubmit, valid, isLoading = false,
  isEdit = false, statusList, planSecList = []
}) => {
  const isValid = (submitting || valid);
  const { isProcessing } = useSelector(state => state.strategies)
  const { thicknessList } = useSelector(state => state.unitCost)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const yesNoOpt = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" }
  ]

  const dirList = [
    { label: "East", value: "east" },
    { label: "West", value: "west" },
    { label: "North", value: "north" },
    { label: "South", value: "south" }
  ]

  return (
    <div className="d-flex ai-center">
      {!!isProcessing && <SpinLoader />}
      <form onSubmit={handleSubmit} className="strategy-form-container center-flex">
        {
          !isEdit &&
          <div className="d-flex">
            <Field name="identity" label={t('mainten_plan.form.select_plan')} component={ReduxSelectField} isSearchable={true} parentClassName="select-plan-box"
              handleOnChange={(original) => dispatch(showPlanOnMap(original.id))}
              options={planSecList.map(a =>
              ({
                id: a.id,
                label: 'ID: ' + a.identity + ' - ' + a.road_category + a.roadNumber + ' - ' + a.part + ' - (' + a.start_distance + 'm - ' + a.end_distance + 'm) - ' + a.rmms_linetype_code,
                value: a.identity
              })
              )} validate={[required]} />
          </div>
        }

        {
          !isEdit &&
          <div className="d-flex">
            <Field name="status" label={t('mainten_plan.form.status')} component={ReduxSelectField} options={statusList} disabled={true} readOnly={true} />
            <Field name="roadNumber" label={t('mainten_plan.form.rd_num')} type="number" component={ReduxLabelInput} placeholder="Ex. 3000" disabled={true} readOnly={true} />
          </div>
        }

        {!isEdit && <div className="d-flex">
          <Field name="start_distance" label={t('mainten_plan.form.start_dis')} component={ReduxLabelInput} placeholder="Ex. S26D1" disabled={true} readOnly={true} />
          <Field name="end_distance" label={t('mainten_plan.form.end_dis')} type="number" component={ReduxLabelInput} placeholder="Ex. 123" disabled={true} readOnly={true} />
        </div>}

        {!isEdit && <div className="d-flex">
          <Field name="unit_cost" label={t('mainten_plan.form.unit_cost')} type="number" component={ReduxLabelInput} placeholder="Ex. 1000" disabled={true} readOnly={true} />
          <Field name="marking_factor" label={t('mainten_plan.form.av_factor')} component={ReduxLabelInput} placeholder="Ex. 1005" disabled={true} readOnly={true} />
        </div>}

        {false && <div className="d-flex">
          <Field name="intersection" label={t('mainten_plan.form.intersec')} component={ReduxLabelInput} placeholder="Ex. T-kryss" />
          <Field name="status" label={t('mainten_plan.form.status')} component={ReduxSelectField} options={statusList} />
        </div>}

        {isEdit && <div className="d-flex">
          <Field name="thicknessList" label={t('mainten_plan.form.change_thickness')} component={ReduxSelectField} options={thicknessList.map(s => ({ label: `${s} mm`, value: s }))} />
          {/* HINT: Not in use for now */}
          {false && <Field name="description" label={t('mainten_plan.form.desc')} component={renderTextArea} placeholder="Ex. Description..." />}
          <div className="blank-div" />
        </div>}

        {isEdit && <div className="d-flex">
          <Field name="description" label={t('mainten_plan.form.desc')} component={renderTextArea} placeholder="Ex. Description..." disabled={true} readOnly={true} />
          <Field name="status" label={t('mainten_plan.form.status')} component={ReduxSelectField} options={statusList} />
        </div>}

        {isEdit && <div className="d-flex">
          <Field name="is_selected" label={t('mainten_plan.form.is_selected')} component={ReduxCheckBox} />
          <Field name="deadline_date" type="date" label={t('form_input.deadline_date')} component={ReduxLabelInput} />
        </div>}

        {
          false ?
            <div className="d-flex">
              {/* TODO: Not in use AON */}
              {false && <Field name="direction" label={t('mainten_plan.form.dir')} component={ReduxSelectField} options={dirList} />}
              {false && <Field name="description" label={t('mainten_plan.form.desc')} component={renderTextArea} placeholder="Ex. Description..." />}
              <Field name="status" label={t('mainten_plan.form.status')} component={ReduxSelectField} options={statusList} />
              <Field name="is_selected" label={t('mainten_plan.form.is_selected')} component={ReduxCheckBox} />
            </div> :
            <>
              {false && <div className="d-flex">
                <Field name="line_type" label={t('mainten_plan.form.line_type')} component={ReduxLabelInput} placeholder="Ex. M" />
                <Field name="new_pavement" label={t('mainten_plan.form.new_pavement')} component={ReduxSelectField} options={yesNoOpt} />
              </div>}

              {/* TODO: Not in use AON */}
              {/* <div className="d-flex">
                <Field name="direction" label={t('mainten_plan.form.dir')} component={ReduxSelectField} options={dirList} />
              </div> */}
            </>
        }
        <WaywizeButton type="submit" title={(isLoading || submitting) ? `${t('button.loading')}...` : (!!isEdit ? t('button.update') : t('button.save'))} className="strategy-form-submit-btn" disabled={!isValid} />
      </form>
    </div>
  )
}

export default PlanSectionForm