import React from 'react'
import Edit from './Edit'
import Trash from './Trash'
import Download from './Download'
import Select from './Select'
import User from './User'
import Stack from './Stack'
import Plus from './Plush'
import Minus from './Minus'
import Network from './Network'
import Cross from './Cross'
import DoubleArrow from './DoubleArrow'
import StrategyHead from './StrategyHead'
import Magnifier from './Magnifier'
import Report from './Report'
import WorksPlan from './WorksPlan'
import Caret from './Caret'
import File from './File'
import Eye from './Eye'
import LeftArrow from './LeftArrow'
import RightArrow from './RightArrow'
import Calendar from './Calendar'
import RoundSelect from './RoundSelect'
import QuestionIcon from './QuestionIcon'

const Icon = ({ name, ...rest }) => {
    switch (name) {
        case 'download':
            return <Download {...rest} />
        case 'edit':
            return <Edit {...rest} />
        case 'trash':
            return <Trash {...rest} />
        case 'select':
            return <Select {...rest} />
        case 'user':
            return <User {...rest} />
        case 'stack':
            return <Stack {...rest} />
        case 'plus':
            return <Plus {...rest} />
        case 'minus':
            return <Minus {...rest} />
        case 'network':
            return <Network {...rest} />
        case 'cross':
            return <Cross {...rest} />
        case 'double-arrow':
            return <DoubleArrow {...rest} />
        case 'strategy-head':
            return <StrategyHead {...rest} />
        case 'magnifier':
            return <Magnifier {...rest} />
        case 'report':
            return <Report {...rest} />
        case 'worksplan':
            return <WorksPlan {...rest} />
        case 'caret':
            return <Caret {...rest} />
        case 'file':
            return <File {...rest} />
        case 'eye':
            return <Eye {...rest} />
        case 'left-arrow':
            return <LeftArrow {...rest} />
        case 'right-arrow':
            return <RightArrow {...rest} />
        case 'calendar':
            return <Calendar {...rest} />
        case 'round-select':
            return <RoundSelect {...rest} />
        case 'question-icon':
            return <QuestionIcon {...rest} />
        default:
            return null
    }
}

export default Icon
