import i18next from "i18next"
import { toast } from "react-toastify"
import { handleRequest } from "../constant/helpers"

export const SET_PLAYER_INFO = "SET_PLAYER_INFO"
export const SET_PLAYER_IS_PROCESS = "SET_PLAYER_IS_PROCESS"

export const setPlayerInfo = playerInfo => ({
    type: SET_PLAYER_INFO,
    payload: { playerInfo }
})

const setPlayerProcessing = isProcessing => ({
    type: SET_PLAYER_IS_PROCESS,
    payload: { isProcessing }
})


/**
 * Fetch Road images
 * @param {*} params
 * @returns 
 */
export const fetchRoadImages = (params = {}) => async (dispatch) => {
    if (!!params.roadname && !!params.start_distance) {
        dispatch(setPlayerProcessing(true))
        try {
            const resp = await handleRequest("post", false, {
                query: `query GetRoadImage($getRoadImageData2: roadImageParameter) {
                    getRoadImage(data: $getRoadImageData2) {
                        imageDate
                        imageType
                        imageData
                    }
                  }`,
                variables: {
                    getRoadImageData2: {
                        roadname: params.roadname,
                        start_distance: Number(params.start_distance)
                    }
                }
            })

            dispatch(setPlayerProcessing(false))
            if (!!resp && !!resp.getRoadImage && !!resp.getRoadImage.length) {
                dispatch(setPlayerInfo({ ...params, images: resp.getRoadImage }))
            } else {
                dispatch(setPlayerInfo(params))
            }
        } catch (err) {
            console.log('fetchRoadImages Error:', err);
            dispatch(setPlayerProcessing(false))
        }
    } else if (!params.roadname) {
        toast.error(i18next.t('message.error.rdname_param_missing'))
    } else if ((typeof params.start_distance === undefined) || (typeof params.start_distance === 'undefined')) {
        toast.error(i18next.t('message.error.start_param_missing'))
    }
}