import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import global from './global'
import roadReducer from './roads'
import strategies from './strategies'
import plans from './plans'
import navbar from './navbar'
import imagePlayer from './imagePlayer'
import searchRdAttr from './searchRdAttr'
import measurement from './measurement'
import unitCost from './unitCost'
import settings from './settings'
import users from './users'

const rootReducer = combineReducers({
    global,
    roadReducer,
    strategies,
    plans,
    navbar,
    imagePlayer,
    searchRdAttr,
    measurement,
    unitCost,
    settings,
    users,
    form: formReducer
})

export default rootReducer