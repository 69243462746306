import React from 'react'
import './index.scss'

const ToggleSwitch = ({
    name = '',
    value,
    checked = false,
    className = '',
    handleOnChange
}) => (
    <div className={`waywize-toggle-switch-container ${className}`}>
        <label className="toggle" name={name}>
            <input type="checkbox" value={value} checked={checked} onChange={handleOnChange} />
            <span className="slider" />
            <span className="labels" data-on="ON" data-off="OFF" />
        </label>
    </div>
)

export default ToggleSwitch