import { SET_MEASURMENT_RD_ARR } from "../actions/measurement"

const initialState = {
    roadArr: []
}

const searchRdAttr = (state = initialState, action) => {
    switch (action.type) {
        case SET_MEASURMENT_RD_ARR:
            return Object.assign({}, state, action.payload)
        default:
            return state
    }
}

export default searchRdAttr