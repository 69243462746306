import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLimitSettings, setSettingPage } from '../../actions/settings'
import CommonTable from '../../components/common/CommonTable'
import CustomPopUp from '../../components/common/CustomPopUp'
import EditLimit from './EditLimit'
import "./index.scss"

const columns = [
    { Header: "settings.parameter", accessor: (row) => row.name || row.variable_id, Filter: false },
    { Header: "settings.colour", accessor: "marking_color", Filter: false },
    { Header: "settings.lmt_input", accessor: "value", Filter: false }
]

const Settings = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { limitSettings, isProcess } = useSelector(state => state.settings)

    useEffect(() => {
        dispatch(fetchLimitSettings())
        // eslint-disable-next-line
    }, [])

    const [show, setShow] = useState(false)
    const [limitInfo, setLimitInfo] = useState({})

    const editUCFn = (info) => {
        setLimitInfo(info)
        setShow(true)
    }

    const handleOnHide = () => {
        setShow(false)
        setLimitInfo({})
    }

    return (
        <CustomPopUp className="limit-popup-container" title={t('settings.limits')} handleClose={() => dispatch(setSettingPage(false))}>
            {
                !!show && <CustomPopUp title={t('settings.edit_limit')} handleClose={() => handleOnHide()}>
                    <EditLimit isProcessing={isProcess} info={limitInfo} onSuccess={() => setShow(false)} />
                </CustomPopUp>
            }
            <CommonTable
                className="plan-sec-table"
                columns={columns}
                data={limitSettings}
                actionBtn={true}
                editFn={(info) => editUCFn(info.original || {})}
            />
            {(!limitSettings || (limitSettings.length < 1)) && <div className='center-flex no-records-txt'>{t(isProcess ? 'settings.fetching' : 'settings.no_records')}</div>}
        </CustomPopUp>
    )
}

export default Settings