import { handleRequest } from "../constant/helpers"
import msalObj from '../services/MsalService'

export const SET_GLOBAL_IS_PROCESS = 'SET_GLOBAL_IS_PROCESS'
export const SET_IS_LICENSE = 'SET_IS_LICENSE'
export const SET_MAP_LAT_LONG = 'SET_MAP_LAT_LONG'
export const SET_AUTO_OUT = 'SET_AUTO_OUT'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_MAP_TYPE = 'SET_MAP_TYPE'
export const SET_STATUS_ARR = 'SET_STATUS_ARR'
export const SET_ROLES_ARR = 'SET_ROLES_ARR'
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM'

export const setGlobalIsProcess = isProcess => ({
  type: SET_GLOBAL_IS_PROCESS,
  payload: { isProcess }
})

export const setIsLicense = isLicense => ({
  type: SET_IS_LICENSE,
  payload: { isLicense }
})

export const setMapLatLong = (mapLatLong, mapZoom = 7) => ({
  type: SET_MAP_LAT_LONG,
  payload: { mapLatLong, mapZoom }
})

export const setMapZoom = (mapZoom = 7) => ({
  type: SET_MAP_ZOOM,
  payload: { mapZoom }
})

export const setAutoOut = autoOut => ({
  type: SET_AUTO_OUT,
  payload: { autoOut }
})

export const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  payload: { userInfo }
})

export const setLanguage = lang => {
  localStorage.setItem("rmms-lang", lang)
  return ({
    type: SET_LANGUAGE,
    payload: { lang }
  })
}

export const setMapType = mapType => ({
  type: SET_MAP_TYPE,
  payload: { mapType }
})

export const setRolesArr = roleList => ({
  type: SET_ROLES_ARR,
  payload: { roleList }
})

export const setStatusArr = statusList => ({
  type: SET_STATUS_ARR,
  payload: { statusList }
})


/**
 * Fetch User Info
 * @returns 
 */
export const fetchUserInfo = () => async (dispatch) => {
  dispatch(setGlobalIsProcess(true));
  try {
    const data = await handleRequest("post", false, {
      query: `
      query Status {
        getUser {
          id
          user_email
          job_title
          role_id
        }
      }`
    })

    if (!!data && !!data.getUser) {
      const user = msalObj.getActiveAccount()
      const userName = !!user && !!user.name ? user.name : false
      dispatch(setUserInfo({ ...data.getUser, name: userName }))
      return data
    }
    dispatch(setGlobalIsProcess(false));
    return []
  } catch (e) {
    dispatch(setGlobalIsProcess(false));
    console.log('fetchUserInfo Error: ', e);
    return e
  }
}


/**
 * Fetch all Status
 * and Roles
 * @returns 
 */
export const fetchAllStatusAndRoles = () => async (dispatch) => {
  dispatch(setGlobalIsProcess(true));
  try {
    const data = await handleRequest("post", false, {
      query: `
      query Status {
        getOperations {
          status {
            name
            id
          }
          roles {
            id
            user_type
          }
        }
      }`
    })

    if (!!data && !!data.getOperations && !!data.getOperations.status) {
      dispatch(setStatusArr(data.getOperations.status))
    }

    if (!!data && !!data.getOperations && !!data.getOperations.roles) {
      dispatch(setRolesArr(data.getOperations.roles))
    }
    dispatch(setGlobalIsProcess(false))
    return data
  } catch (e) {
    dispatch(setGlobalIsProcess(false));
    console.log('fetchAllStatusAndRoles Error: ', e);
    return e
  }
}