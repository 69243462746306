import React, { useEffect, useRef } from 'react'
import './index.scss'

const ConfirmAlertPopup = ({
    title = 'Are you sure?',
    message = 'This item will be permanently deleted from the data',
    deleteBtnTxt = 'Yes, Delete',
    onConfirm, onClose
}) => {
    const wrapperRef = useRef()

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null) &&
            !wrapperRef.current.contains(event.target)) {
            onClose()
        }
    }

    const handleKeyDown = e => {
        if (((e.keyCode === 27) ||
            (e.key === 'Escape') ||
            (e.key === 'Esc'))) {
            onClose()
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="confirm-alert-popup-container center-flex">
            <div className="confirm-alert-popup-message center-flex f-column" ref={wrapperRef}>
                <img src={require("./delete.svg").default} className="fuelsave-logo" alt="RMMS" />
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="around-flex w-100 mt-10">
                    <button onClick={onClose} className='confirm-cancel'>Cancel</button>
                    <button onClick={() => {
                        onConfirm()
                        onClose()
                    }} className='confirm-agree'>{deleteBtnTxt}</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmAlertPopup