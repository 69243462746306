import { connect, useDispatch } from "react-redux"
import { reduxForm } from "redux-form"
import { editStrategy } from "../../../../actions/strategies"
import StrategyForm from "../StrategyForm"

const EditStrategy = ({ submitting, handleSubmit, valid, onSuccess }) => {
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    const {
      id, name, budget, distanceCombinedShort, distanceSkipShort,
      lengthMultiplier, lightingMultiplier, aadtMultiplier,
      speedLimitMultiplier, maintainanceClassMultiplier, tentMultiplier
    } = data
    dispatch(editStrategy({
      id,
      name,
      budget: Number(budget),
      ...(!!distanceCombinedShort && { "distanceCombinedShort": Number(distanceCombinedShort) }),
      ...(!!distanceSkipShort && { "distanceSkipShort": Number(distanceSkipShort) }),
      ...({ lengthMultiplier: !!lengthMultiplier ? Number(lengthMultiplier) : 0 }),
      ...({ lightingMultiplier: !!lightingMultiplier ? Number(lightingMultiplier) : 0 }),
      // ...(!!colourMultiplier && { "colourMultiplier": parseInt(colourMultiplier) }),
      ...({ aadtMultiplier: !!aadtMultiplier ? Number(aadtMultiplier) : 0 }),
      ...({ speedLimitMultiplier: !!speedLimitMultiplier ? Number(speedLimitMultiplier) : 0 }),
      ...({ maintainanceClassMultiplier: !!maintainanceClassMultiplier ? Number(maintainanceClassMultiplier) : 0 }),
      // ...(!!paymentMultiplier && { "paymentMultiplier": parseInt(paymentMultiplier) }),
      ...({ tentMultiplier: !!tentMultiplier ? Number(tentMultiplier) : 0 })
    }, onSuccess))
  };

  return (
    <StrategyForm
      submitting={submitting}
      valid={valid}
      isEdit={true}
      handleSubmit={handleSubmit(onSubmit)} />
  )
}

export default connect((state, ownProps) => {
  return {
    initialValues: {
      ...ownProps.info
    }
  }
}, null)(reduxForm({
  form: 'edit-strategy-form',
  enableReinitialize: true
})(EditStrategy))