import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { toast } from "react-toastify";
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import CommonTable from "../../../components/common/CommonTable";
import CustomPopUp from "../../../components/common/CustomPopUp";
import SpinLoader from "../../../components/common/SpinLoader";
import WaywizeButton from "../../../components/common/WaywizeButton";
import Icon from '../../../components/common/Icons';
import { colorCodes } from "../../../constant/colors";
import { deleteMaintenPlan, fetchAllPlans, fetchPlanSections, moveToRamApp, showPlanOnMap } from "../../../actions/plans";
import EditPlanSection from "./EditPlanSection";
import AddPlanSection from "./AddPlanSection";
import { changeMapOverlay } from "../../../actions/roads";
import { maintenPlanColorSet } from "../../../constant";
import DoughnutChart from "../../../components/common/DoughnutChart";
import ResizableWindow from "../../../components/common/ResizableWindow";
import "./index.scss";
import ConfirmAlertPopup from "../../../components/common/ConfirmAlertPopup";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import { fetchAllUCThickness } from "../../../actions/unitCost";
import EditMultiPlanSection from "./EditMultiPlanSection";
import { fetchRoadImages } from "../../../actions/imagePlayer";

const MultiSelect = (column) => {
  const { t } = useTranslation();
  return (
    <div className={`waywize-select-input-container`}>
      <label className="waywize-multi-select-input-label">{t(column.column.Header)}</label>
      <Select
        isMulti
        options={[
          { label: 'ML' },
          { label: 'IT' },
          { label: 'K' },
          { label: 'KF1' },
          { label: 'KF2' }
        ]}
        getOptionValue={option => option.label}
        onChange={value => {
          const valArr = value.map(v => v.label) || [];
          column.setFilter('rmms_linetype_code', valArr || undefined); // Set undefined to remove the filter entirely
        }}
        classNamePrefix="custom-prefix-select"
      />
    </div>
  );
};

const planSecColumns = (statusList) => [
  { Header: "table.identity", accessor: "identity", placeholder: 'table.search', isSortable: true },
  { Header: "table.rd_cat", accessor: "road_category", placeholder: 'table.enter_cat', isSortable: true },
  { Header: "table.rd_num", accessor: "roadNumber", placeholder: 'table.enter_rd_nm', isSortable: true },
  { Header: "table.part", accessor: "part", placeholder: 'table.part', isSortable: true },
  { Header: "table.start_dis", accessor: "start_distance", placeholder: 'table.search', isSortable: true },
  { Header: "table.end_dis", accessor: "end_distance", placeholder: 'table.search', isSortable: true },
  { Header: "table.selected", accessor: "is_selected", placeholder: 'table.search', filter: 'bool', type: 'bool', isSortable: true },
  { Header: "table.line_type", accessor: "rmms_linetype_code", placeholder: 'table.search', filter: 'multiSelect', Filter: MultiSelect, isSortable: true },
  { Header: "table.av_factor", accessor: (row) => (row.marking_factor).toFixed(2), placeholder: 'table.search', isSortable: true },
  { Header: "table.length", accessor: (row) => row.end_distance - row.start_distance, placeholder: 'table.search', isSortable: true },
  { Header: "table.des", accessor: "description", className: "no-wrap", placeholder: 'table.search', isSortable: true },
  {
    Header: "table.deadline_date", accessor: (row) => !!row.deadline_date ? new Date(row.deadline_date) : null, filter: 'date', type: 'date', className: "no-wrap", placeholder: 'table.search', isSortable: true,
    sortType: (a, b) => {
      var param1 = new Date(a.original.deadline_date).getTime();
      var param2 = new Date(b.original.deadline_date).getTime();
      if (param1 < param2)
        return 1;
      else if (param1 > param2)
        return -1;
      else
        return 0;
    }
  },
  { Header: "table.unit_cost", accessor: "unit_cost", placeholder: 'table.search', isSortable: true },
  { Header: "table.new_pavement", accessor: "newPayment", filter: 'bool', type: 'bool', placeholder: 'table.search', isSortable: true },
  { Header: "table.dir", accessor: "direction", placeholder: 'table.search', isSortable: true },
  {
    Header: "table.status", accessor: (row) => {
      const rec = statusList.find(s => s.id === row.status);
      return !!rec && !!rec.name ? rec.name : '-';
    }, placeholder: 'table.search', isSortable: true
  }
];

const MaintenancePlan = ({ isActive = false }) => {
  const [show, setShow] = useState(false);
  const [showGood, setShowGood] = useState(false);
  const [formType, setFormType] = useState(false);
  const [secInfo, setSecInfo] = useState({});
  const [chartData, setChartData] = useState('');
  const [barChartData, setBarChartData] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [multiIDs, setMultiIDs] = useState([]);
  const [multiRecs, setMultiRecs] = useState([]);
  const [resetMulti, doResetMulti] = useState(0);

  // Getting all the state values
  const { plans, planSections, isProcessing, ruleId } = useSelector(state => state.plans);
  const { statusList } = useSelector(state => state.global);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchAllPlans());
    dispatch(fetchAllUCThickness());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!isActive) {
      dispatch(fetchAllPlans());
    }

    // eslint-disable-next-line
  }, [isActive]);

  const memoizeSelectedPlans = records => !!records && !!records.length ? records.filter(p => !!p && p.is_selected) : [];
  const selectedPlans = useMemo(() => memoizeSelectedPlans(planSections), [planSections]);

  useEffect(() => {
    const labels = [];
    const color = [];
    const sum = [];
    const costSum = [];
    let totalCost = 0;

    // console.log(planSections, 'planSectionss');
    const allPlans = [...new Set(planSections.map(item => !!item && item.status))];


    const selectedPlans = planSections.filter(s => !!s && s.is_selected);
    totalCost = selectedPlans.reduce(function (sum, current) {
      return sum + parseInt(current.unit_cost);
    }, 0);
    allPlans.map(item => {
      if (Number.isInteger(item) === true) {
        console.log(item, "item");
        const rec = statusList.find(s => !!s && !!s.id && (s.id === item));
        labels.push(!!rec && !!rec.name ? rec.name : '-');
        color.push(maintenPlanColorSet[item].value);
        sum.push(((selectedPlans.filter(a => !!a && (a.status === item)).length / selectedPlans.length) * 100));
        costSum.push((selectedPlans.filter(a => !!a && (a.status === item))).reduce(function (sum, current) {
          return sum + parseInt(current.unit_cost);
        }, 0));
        return item;
      }
    });

    const data = {
      labels,
      datasets: [{
        data: sum,
        backgroundColor: color,
        borderColor: color
      }]
    };

    const barData = {
      labels: ['Total', ...labels],
      datasets: [{
        data: [totalCost, ...costSum],
        backgroundColor: [colorCodes.BLUE_1, ...color],
        borderColor: [colorCodes.BLUE_1, ...color]
      }]
    };
    setBarChartData(barData);
    setChartData(data);
    // eslint-disable-next-line
  }, [planSections]);

  const editSecPlan = (info) => {
    setSecInfo(info);
    setFormType('edit');
    setShow(true);
  };

  const handleOnHide = () => {
    setShow(false);
    setShowGood(false);
    setFormType(false);
  };

  const onSuccess = () => {
    setShow(false);
    setShowGood(false);
    dispatch(fetchAllPlans());
  };


  Chart.overrides.doughnut.plugins.tooltip = {
    callbacks: {
      label: (tooltipItem) => tooltipItem.label + ' : ' + Number(tooltipItem.formattedValue).toFixed(2) + '%',
      title: () => false
    }
  };

  const columns = [
    { Header: "table.name", accessor: "name", Filter: false },
    { Header: "table.maintenance_plan_year", accessor: "maintenanceplan_year", Filter: false },
    { Header: "table.all_unit_cost", accessor: "all_unit_cost", Filter: false },
    { Header: "table.backlog_cost", accessor: "backlog_cost", Filter: false },
    { Header: "table.budget", accessor: "buget_amount", Filter: false },
    {
      Header: "table.move_to_ramapp", accessor: (row) => {
        return <ToggleSwitch name="move_to_ramapp"
          value={row.move_to_ramapp}
          checked={row.move_to_ramapp === "true"} handleOnChange={e =>
            confirmAlert({
              customUI: values =>
                <ConfirmAlertPopup
                  {...values}
                  deleteBtnTxt="Yes, Move"
                  message="This item will be moved to RamApp."
                  onConfirm={() => dispatch(moveToRamApp((row.id).toString(), (row.move_to_ramapp === "false" ? true : false)))} />
            })} />;
      }, Filter: false
    },
    { Header: "table.modified_at", accessor: (row) => parseInt(row.modified), Filter: false, type: 'date', className: "no-wrap" }
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: false,
      title: {
        display: true,
        position: 'bottom',
        text: 'Unit Cost (NOK)',
      },
    },
  };

  const onCheckboxChange = (e, row) => {
    let newIDsList = multiIDs;
    if (e.target.checked) {
      newIDsList = [...new Set([...multiIDs, row.id])];
    } else {
      newIDsList = newIDsList.filter(id => parseInt(id) !== parseInt(row.id));
    }
    setMultiIDs(newIDsList);
    const multiRec = newIDsList.map(id => selectedPlans.find(p => parseInt(p.id) === parseInt(id)));
    setMultiRecs(multiRec);
  };

  const handleProceedBtn = () => {
    setFormType('multi');
  };

  const handleMultiOnSuccess = () => {
    setMultiIDs([]);
    setMultiRecs([]);
    setShow(false);
    setFormType(false);
    doResetMulti(prev => prev + 1);
  };

  return (
    <div className="maintenance-container">
      {!!isProcessing && <SpinLoader />}
      {
        (!!formType && (formType === 'multi')) && <CustomPopUp title={t('mainten_plan.multiple_update')} handleClose={() => handleOnHide()} className="multi-edit-popup">
          <EditMultiPlanSection isProcessing={isProcessing} multiRecs={multiRecs} onSuccess={() => handleMultiOnSuccess()} ruleId={ruleId} statusList={statusList.map(a => ({ label: a.name, value: a.id }))} />
        </CustomPopUp>
      }
      {
        (!!show || !!showGood) && <CustomPopUp title={`${formType === 'edit' ? t('mainten_plan.edit_m_plan') : (!!showGood ? t('mainten_plan.add_good') : t('mainten_plan.add_backlog'))}`} handleClose={() => handleOnHide()}>
          {
            formType === 'edit' ?
              <EditPlanSection isProcessing={isProcessing} info={secInfo} onSuccess={() => setShow(false)} ruleId={ruleId} statusList={statusList.map(a => ({ label: a.name, value: a.id }))} /> :
              <AddPlanSection showGood={showGood} isProcessing={isProcessing} onSuccess={onSuccess} ruleId={ruleId} statusList={statusList.map(a => ({ label: a.name, value: a.id }))} planSecList={planSections.filter(p => {
                return !!showGood ? (!p.is_selected && (Number(p.bad_condition) === 0)) : (!p.is_selected && (Number(p.bad_condition) !== 0));
              })} />
          }
        </CustomPopUp>
      }
      {
        !!plans.length ? <CommonTable columns={columns}
          className="m-plan-table"
          data={plans}
          actionBtn={true}
          deleteMsg="This plan will be permanently deleted from the data"
          selectFn={r => {
            if (!!r && !!r.original && !!r.original.id) {
              dispatch(fetchPlanSections(r.original.id, r.original.rmms_maintenanceplan_rule_id));
              dispatch(changeMapOverlay('hovedPlan')); // HINT: This is making issue
            }
          }}
          deleteFn={r => {
            if (!!r && !!r.original && !!r.original.rmms_maintenanceplan_rule_id) {
              console.log("r.original.rmms_maintenanceplan_rule_id, ruleId ", r.original.rmms_maintenanceplan_rule_id, ruleId);
              if ((Number(r.original.rmms_maintenanceplan_rule_id) === 280) || (Number(r.original.rmms_maintenanceplan_rule_id) === 281)) {
                toast.error('You can not delete this plan');
              } else {
                dispatch(deleteMaintenPlan(r.original.rmms_maintenanceplan_rule_id, ruleId));
              }
            }
          }}
          selectedRows={[]} /> :
          <h3 className="center-flex h3-fetching-text">{!!isProcessing ? `${t('mainten_plan.fetching')}...` : t('mainten_plan.no_records')}</h3>
      }

      {
        !!ruleId && !!planSections.length && <ResizableWindow className={`card-shadow-elem plan-sec-table-content`} isOpen={isOpen}>
          <div className={`header-action-tool center-flex ${isOpen ? '' : 'info-hide'}`} onClick={() => setIsOpen(!isOpen)}>
            <Icon name="double-arrow" className="toggle-btn" />
          </div>
          {chartData ?
            <div className="chart-content-holder">
              <div className="chart-content-header-title">{"Status (In Percent)"}</div>
              <DoughnutChart data={chartData} />
              <div className="bar-chart-div">
                <Bar options={options} data={barChartData} />
              </div>
            </div> :
            ""}
          <CommonTable
            className="plan-sec-table"
            columns={planSecColumns(statusList)}
            anotherBtn={<>
              <WaywizeButton onClick={() => setShow(true)} title={t('button.add_backlog')} className="ml-5 mr-5">
                <div className="center-flex">
                  <Icon className="mt-1" height={25} width={25} viewBox="0 0 25 25" name="plus" fill={colorCodes.BLUE_3} />
                  <div>{t('button.add_backlog')}</div>
                </div>
              </WaywizeButton>
              <WaywizeButton onClick={() => setShowGood(true)} title={t('button.add_good')} className="ml-5 mr-5">
                <div className="center-flex">
                  <Icon className="mt-1" height={25} width={25} viewBox="0 0 25 25" name="plus" fill={colorCodes.BLUE_3} />
                  <div>{t('button.add_good')}</div>
                </div>
              </WaywizeButton>
            </>}
            editFn={(info) => editSecPlan(info.original || {})}
            onCheckboxChange={(e, info) => onCheckboxChange(e, info.original || {})}
            actionBtn={true}
            data={selectedPlans}
            downloadBtn={true}
            showActiveRow={true}
            showMultiCheckbox={true}
            multiIDs={multiIDs}
            resetMulti={resetMulti}
            proceedBtnDisable={!multiIDs.length}
            handleMultiCancel={() => setMultiIDs([])}
            handleProceedBtn={() => handleProceedBtn()}
            trTitle={t("table.click_to_view_plan_map")}
            onRowClick={(row) => {
              dispatch(changeMapOverlay('hovedPlan'));
              // HINT: For now, we don't have roadname that is required for the fetch images
              dispatch(fetchRoadImages(row.original));
              dispatch(showPlanOnMap(row.original.id));
            }}
            selectedRows={[]} />
        </ResizableWindow>
      }
    </div>
  );
};

export default MaintenancePlan;