import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { changeMapOverlay } from '../../../actions/roads'
import Icon from '../../../components/common/Icons'
import WaywizeButton from '../../../components/common/WaywizeButton'
// import WaywizeCheckbox from '../../../components/common/WaywizeCheckbox'
import { maintenPlanColorSet } from '../../../constant'
import { colorCodes } from '../../../constant/colors'
import {
    colorSetFor10698, colorSetFor2021, colorSetFor4623, colorSetFor5555,
    colorSetForMobilEye, colorSetForRLD, colorSetForVisual
} from '../../../constant/helpers'
import "./index.scss"

const {
    DARK_GREEN_1, LIGHT_GREEN_10, RED_3, YELLOW_2, LIGHT_RED_1, LIGHT_RED_2, GREY_6,
    ORANGE_1, ORANGE_3, PURPLE_1, PURPLE_2
} = colorCodes

const WaywizeCheckbox = ({
    label = '',
    children = false
  }) => (
    <div className='mb-12'>
        {!!children ? children : label}
    </div>
  )

const ColorTile = ({ colorCode, title }) => (
    <div className='color-tile'>
        <div className='color-tile-block' style={{ backgroundColor: colorCode }} />
        <div className='color-tile-title'>{title}</div>
    </div>
)

const CheckHead = ({ title = '' }) => (
    <WaywizeCheckbox name='rd_ntwrk_lane' disabled={true} checked={true}>
        <div className='d-flex'>
            <img src={require('./images/road-network.svg').default} alt={title} />
            <div className='ml-10'>{title}</div>
        </div>
    </WaywizeCheckbox>
)

const NetworkWidget = () => {
    const wrapperRef = useRef()
    const [networkOpt, setNetworkOpt] = useState(false)
    const { map_overlay } = useSelector(state => state.roadReducer)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (!!wrapperRef.current) &&
            !wrapperRef.current.contains(event.target)) {
            setNetworkOpt(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line
    }, [])

    const arrFor10698 = ["TBK1", "TBK2", "TBK3", "TBK4"]
    const arrFor2021 = [40, 50, 60, 70, 80, 90, 100, 110, 120]
    const arrFor4623 = [1000, 2000, 4000, 8000, 10000, 15000, 20000, 30000, 40000]
    const RLDSet = ['0-130', '130-150', '150-200', '200-']
    const visualSet = [0, 1, 2, 3, 4]
    const set5555 = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    const mobilEyeSet = [
        { title: '0 - 0.7', value: 0.5 },
        { title: '0.7 - 0.8', value: 0.71 },
        { title: '0.8 - 0.9', value: 0.81 },
        { title: '0.90 - ', value: 0.91 }
    ]
    const strategySet = [
        { title: 'Good', value: DARK_GREEN_1 },
        { title: 'Selected', value: RED_3 },
        { title: 'Not Selected', value: YELLOW_2 }
    ]
    // if (!map_overlay || (map_overlay === '11801') || (map_overlay === '5178')) {
    if (!map_overlay) {
        return false
    }
    return (
        <>
            <WaywizeButton className='rest-map-btn center-flex' title={t("button.reset_map")} onClick={() => dispatch(changeMapOverlay(false))} />
            <div className='map-network-widget-container' ref={wrapperRef}>
                {
                    !!networkOpt &&
                    <div className='map-network-opt-list'>
                        <div className='map-network-list-cross' onClick={() => setNetworkOpt(false)}><Icon name="cross" /></div>

                        {
                            !!map_overlay && (map_overlay === 'roadSearch') &&
                            <WaywizeCheckbox name='rd_ntwrk_mtr' disabled={true} checked={true}>
                                <div className='d-flex'>
                                    <img src={require('./images/road-network-mtr.svg').default} alt="Road Network" />
                                    <div className='ml-10'>Road Network</div>
                                </div>
                            </WaywizeCheckbox>
                        }

                        {
                            !!map_overlay && (map_overlay === 'RL(d)') &&
                            <div>
                                <CheckHead title='RL(d)' />
                                {!!RLDSet && !!RLDSet.length && <div className='child-opt-holder'>
                                    {
                                        RLDSet.map((val, i) =>
                                            <ColorTile colorCode={colorSetForRLD(val)} title={val} key={i} />)
                                    }
                                </div>}
                            </div>
                        }

                        {
                            !!map_overlay && (
                                (map_overlay === '5555') ||
                                (map_overlay === '5264') ||
                                (map_overlay === '9537')) &&
                            <div>
                                <CheckHead title={`${(map_overlay === '5264') ? 'Vegbredde, totalt / road width' : ((map_overlay === '5555') ? 'Dekkebredde / pavement width' : 'Pavement/Width')}`} />
                                {!!set5555 && !!set5555.length && <div className='child-opt-holder'>
                                    {
                                        set5555.map((val, i) =>
                                            <ColorTile colorCode={colorSetFor5555(val)} title={val} key={i} />)
                                    }
                                </div>}
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '1114') &&
                            <div>
                                <CheckHead title='Crossroads' />
                                <div className='child-opt-holder'>
                                    <ColorTile colorCode={LIGHT_RED_1} title="Intersection" />
                                </div>
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '10945') &&
                            <div>
                                <CheckHead title='Transport' />
                                <div className='child-opt-holder'>
                                    <ColorTile colorCode={LIGHT_RED_2} title="Transport category" />
                                </div>
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '86') &&
                            <div>
                                <CheckHead title='Street Lights' />
                                <div className='child-opt-holder'>
                                    <ColorTile colorCode={LIGHT_GREEN_10} title="Street Lights" />
                                </div>
                            </div>
                        }

                        {
                            !!map_overlay && ((map_overlay === '9500') || (map_overlay === '9501')) &&
                            <div>
                                <CheckHead title='Milled Marking' />
                                <div className='child-opt-holder'>
                                    <ColorTile colorCode={ORANGE_1} title="Kantoppmerking" />
                                    <ColorTile colorCode={ORANGE_3} title="Midtoppmerking" />
                                </div>
                            </div>
                        }
                        {/* HINT: Map Layers not found */}
                        {
                            !!map_overlay && (map_overlay === '9502') &&
                            <div>
                                <CheckHead title='Sinus' />
                                <div className='child-opt-holder'>
                                    <ColorTile colorCode={PURPLE_1} title="Kantoppmerking" />
                                    <ColorTile colorCode={PURPLE_2} title="Midtoppmerking" />
                                </div>
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '1') &&
                            <div>
                                <CheckHead title='New Pavement' />
                                <div className='child-opt-holder'>
                                    <ColorTile colorCode={GREY_6} title="New Pavement" />
                                </div>
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '1081') &&
                            <div>
                                <CheckHead title='Tunnel' />
                                <div className='child-opt-holder'>
                                    <ColorTile colorCode={GREY_6} title="Tunnel" />
                                </div>
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '1216') &&
                            <div>
                                <CheckHead title='Pavement Type' />
                                <div className='child-opt-holder'>
                                    <ColorTile colorCode={GREY_6} title="Pavement Type" />
                                </div>
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '4623') &&
                            <div>
                                <CheckHead title='Traffic intensity' />
                                {!!arrFor4623 && !!arrFor4623.length && <div className='child-opt-holder'>
                                    {
                                        arrFor4623.map((val, i) =>
                                            <ColorTile colorCode={colorSetFor4623(val)} title={val} key={i} />)
                                    }
                                </div>}
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === 'Visual') &&
                            <div>
                                <CheckHead title='Visual' />
                                {!!visualSet && !!visualSet.length && <div className='child-opt-holder'>
                                    {
                                        visualSet.map((val, i) =>
                                            <ColorTile colorCode={colorSetForVisual(val)} title={val} key={i} />)
                                    }
                                </div>}
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === 'MobilEye') &&
                            <div>
                                <CheckHead title='MobilEye' />
                                {!!mobilEyeSet && !!mobilEyeSet.length && <div className='child-opt-holder'>
                                    {
                                        mobilEyeSet.map(({ title, value }, i) =>
                                            <ColorTile colorCode={colorSetForMobilEye(value)} title={title} key={i} />)
                                    }
                                </div>}
                            </div>
                        }
                        {
                            !!map_overlay && (map_overlay === 'strategy') &&
                            <div>
                                <CheckHead title='Strategy' />
                                {!!strategySet && !!strategySet.length && <div className='child-opt-holder'>
                                    {
                                        strategySet.map(({ title, value }, i) =>
                                            <ColorTile colorCode={value} title={title} key={i} />)
                                    }
                                </div>}
                            </div>
                        }
                        {
                            !!map_overlay && (map_overlay === 'hovedPlan') &&
                            <div>
                                <CheckHead title='Maintenance Plan' />
                                {!!maintenPlanColorSet && !!maintenPlanColorSet.length && <div className='child-opt-holder'>
                                    {
                                        maintenPlanColorSet.map(({ title, value }, i) =>
                                            <ColorTile colorCode={value} title={title} key={i} />)
                                    }
                                </div>}
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '2021') &&
                            <div>
                                <CheckHead title='Speed Limit' />
                                {!!arrFor2021 && !!arrFor2021.length && <div className='child-opt-holder'>
                                    {
                                        arrFor2021.map((val, i) =>
                                            <ColorTile colorCode={colorSetFor2021(val)} title={val} key={i} />)
                                    }
                                </div>}
                            </div>
                        }

                        {
                            !!map_overlay && (map_overlay === '10698') &&
                            <div>
                                <CheckHead title='Maintenance Class' />
                                {!!arrFor10698 && !!arrFor10698.length && <div className='child-opt-holder'>
                                    {
                                        arrFor10698.map((val, i) =>
                                            <ColorTile colorCode={colorSetFor10698(val)} title={val} key={i} />)
                                    }
                                </div>}
                            </div>
                        }
                    </div>
                }
                <WaywizeButton className='network-widget-btn center-flex' title="Network" onClick={() => setNetworkOpt(true)}>
                    <Icon name="network" />
                    <div className='network-btn-title'>
                        {t("button.legend")}
                    </div>
                </WaywizeButton>
            </div>
        </>
    )
}

export default NetworkWidget