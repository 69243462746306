import common from '../assets/scss/common.module.scss';
import { colorCodes } from './colors';
export const DATE_FORMAT = 'YYYY-DD-MM';
export const DATE_FORMAT_1 = 'DD-MM-YYYY';
export const NORWAY_CORDI = [10.45285588, 59.54413676];
export const GEO_SERVER_URL = 'gwc/service/tms/1.0.0/rmms_dev';

export const menuList = [
    { id: 'search_road', title: 'navbar.menu.search_road' },
    { id: 'search_road_attribute', title: 'navbar.menu.search_rd_attr' },
    { id: 'measurment', title: 'navbar.menu.measurment' },
    { id: 'strategy', title: 'navbar.menu.strategy' },
    { id: 'maintenance_plan', title: 'navbar.menu.maintain_plan' },
    // { id: 'backlog', title: 'navbar.menu.backlog' }, // HINT: Not in use as of now 7784
];

export const lineTypeList = [
    { label: 'Left Edge Line', value: 'left_edge_line_n' },
    { label: 'Right Edge Line', value: 'right_edge_line_n' },
    { label: 'Center Line', value: 'center_line' },
    { label: 'Right Middle Line', value: 'right_middle_line' },
    { label: 'Lane Line 2', value: 'lane_line_2' },
    { label: 'Left Middle Line', value: 'left_middle_line' },
    { label: 'Right Edge Line', value: 'right_edge_line' },
    { label: 'Lane Line 1', value: 'lane_line_1' },
    { label: 'Lane Line 3', value: 'lane_line_3' }
];

export const MOVEABLE_TOP_POS = 118;
export const ZOOM_LEVEL = common.zoomLevel;

export const maintenPlanColorSet = [
    { title: 'Planned', value: colorCodes.RED_3 },
    { title: 'Ordered', value: colorCodes.LIGHT_GREEN_5 },
    { title: 'Flushed', value: colorCodes.LIGHT_GREEN_6 },
    { title: 'Pre-Marked', value: colorCodes.LIGHT_BLUE_2 },
    { title: 'Marked', value: colorCodes.LIGHT_BLUE_3 },
    { title: 'Q/A', value: colorCodes.YELLOW_2 },
    { title: 'Invoiced', value: colorCodes.LIGHT_GREEN_4 },
    { title: 'Accepted', value: colorCodes.DARK_GREEN_1 }
];

export const jobTitels = [
    'jobtitle.developer',
    'jobtitle.pr_manager',
    'jobtitle.tester',
    'jobtitle.contractor',
];



// HINT: Temporary added for demo
export const REACT_APP_API_URL = "https://rmmsapidev.azurewebsites.net/graphql"
export const REACT_APP_GEOSERVER_URL = "http://10.8.138.7:8000/geoserver"
export const REACT_APP_B2C_AUTHORITY_DOMAIN = "rmmsdev.b2clogin.com"
export const REACT_APP_B2C_TENANT_ID = "rmmsdev.onmicrosoft.com"
export const REACT_APP_B2C_APP_ID = "3d6d93ff-dc58-401c-8ad8-7747ba61e015"
export const REACT_APP_B2C_API_SCOPE = "https://rmmsdev.onmicrosoft.com/api/demo.read"
export const REACT_APP_MAPTILER_KEY = "yAc0z88j4aRTqueGjZxB"
export const REACT_APP_UI_URL = "https://rmmsdev.ramboll.com/"
// export const REACT_APP_UI_URL = "http://localhost:3006/"