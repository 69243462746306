import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, fetchAllUsers } from '../../actions/users';
import CommonTable from '../../components/common/CommonTable'
import CustomPopUp from '../../components/common/CustomPopUp';
import SpinLoader from '../../components/common/SpinLoader';
import WaywizeButton from '../../components/common/WaywizeButton';
import { capitalizeWords } from '../../constant/helpers';
import AddUser from './AddUser';
import EditUser from './EditUser';
import './index.scss'

export function Users({
  inPopUp = false
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [formType, setFormType] = useState(false)
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    dispatch(fetchAllUsers())

    // eslint-disable-next-line
  }, [])

  const { isProcessing, users } = useSelector(state => state.users)
  const { roleList } = useSelector(state => state.global)

  const columns = [
    { Header: "table.email", accessor: "user_email", Filter: false },
    {
      Header: "table.role", accessor: (row) => {
        let roleVal = 'N/A'
        if (!!roleList && !!roleList.length) {
          const rec = roleList.find(r => parseInt(r.id) === parseInt(row.role_id))
          if (!!rec && !!rec.user_type) {
            roleVal = capitalizeWords((rec.user_type).replace("_", " "))
          }
        }
        return roleVal
      }, Filter: false
    },
    { Header: "table.job_title", accessor: "job_title", Filter: false }
  ]

  const editUCFn = (info) => {
    setUserInfo(info)
    setFormType('edit')
    setShow(true)
  }

  const handleOnHide = () => {
    setShow(false)
    setUserInfo({})
    setFormType(false)
  }

  return (
    <div className={`users-container ${!inPopUp ? 'route-ui' : ''}`}>
      <div className="users-cont-heading between-flex">
        {(!!isProcessing && !show) && <SpinLoader />}
        {!inPopUp && <div className='users-headin-title'>{t('users.users')}</div>}
        <div className='user-headin-revision'>
          <WaywizeButton title={t('button.add_new')} onClick={() => {
            setShow(true)
            setFormType(false)
          }} />
        </div>
      </div>
      {
        !!show && <CustomPopUp title={t((formType === 'edit') ? 'users.edit_user' : 'users.add_user')} handleClose={() => handleOnHide()}>
          {
            (!!formType && (formType === 'edit')) ?
              <EditUser isProcessing={isProcessing} info={userInfo} onSuccess={() => setShow(false)} /> :
              <AddUser isProcessing={isProcessing} onSuccess={() => setShow(false)} />
          }
        </CustomPopUp>
      }
      <div className='users-context'>
        <Row className='center-flex'>
          <Col xs={10}>
            <div className='users-context-table'>
              {
                !!users.length ?
                  <CommonTable columns={columns} data={users}
                    actionBtn={true}
                    editFn={(info) => editUCFn(info.original || {})}
                    deleteMsg={t("message.user_delete_confirm")}
                    deleteFn={r => !!r && !!r.original && !!r.original.id && dispatch(deleteUser(r.original.id))}
                  /> :
                  <h3 className="center-flex h3-fetching-text">{!!isProcessing ? `${t('users.fetching')}...` : t('users.no_records')}</h3>
              }
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
