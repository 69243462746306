import "./index.scss"

const WaywizeButton = ({
    title = 'Button', className = '', type = 'button',
    onClick, disabled = false, children
}) => (
    <button type={type}
        disabled={disabled}
        title={title || ''}
        className={`waywize-button ${className}`}
        onClick={(e) => {
            !!onClick && onClick(e)
        }}>{!!children ? children : title}</button>
)

export default WaywizeButton