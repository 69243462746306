import React from 'react'
import './index.scss'

const AuthPopup = ({ isLicense = null }) => (
    <div className="auth-popup-container center-flex">
        <div className="auth-popup-message center-flex f-column">
            <img src={require("../../assets/images/logoSpin.png").default} className="fuelsave-logo" alt="FuelSave" />
            <div className='auth-message-holder'>
                {
                    (isLicense === false) ? 'License key not found, Please contact Administrator.' :
                        <>Your session has been logged out. Please refresh the window or <div className="reload-act-btn" onClick={() => window.location.reload()}>Click Here</div></>
                }
            </div>
        </div>
    </div>
)

export default AuthPopup