import React from 'react'
import { colorCodes } from '../../../constant/colors'

const User = ({
    fill = colorCodes.GREY_2,
    height = '40.108',
    width = 40,
    style = {},
    viewBox = '0 0 40 40.108',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g transform="translate(-0.129)"><g transform="translate(25.83 38.679)">
            <path fill="#f9ede0" d="M299.207,451.92c-.209.071-.419.139-.631.2C298.788,452.058,299,451.99,299.207,451.92Z" transform="translate(-298.576 -451.536)" />
            <path fill="#f9ede0" d="M310.8,447.472c-.225.09-.451.178-.68.26C310.35,447.65,310.576,447.562,310.8,447.472Z" transform="translate(-309.122 -447.472)" /></g>
            <circle fill="#e0e2ec" cx="20" cy="20" r="20" transform="translate(0.129)" />
            <g transform="translate(11.402 38.139)" fill="#f9ede0">
                <path d="M287.355,455.584q-.307.085-.619.161Q287.048,455.669,287.355,455.584Z" transform="translate(-273.331 -454.343)" />
                <path d="M207.474,462.67c-.245-.017-.489-.036-.73-.062C206.986,462.634,207.23,462.653,207.474,462.67Z" transform="translate(-200.253 -460.76)" />
                <path d="M170.715,455.737q-.311-.076-.619-.161Q170.4,455.662,170.715,455.737Z" transform="translate(-166.773 -454.335)" />
                <path d="M195.023,461.013c-.219-.028-.438-.058-.655-.093C194.584,460.955,194.8,460.984,195.023,461.013Z" transform="translate(-188.947 -459.218)" />
                <path d="M220.379,463.671c-.369,0-.735-.012-1.1-.031C219.644,463.659,220.01,463.671,220.379,463.671Z" transform="translate(-211.706 -461.702)" />
                <path d="M182.787,458.7c-.21-.039-.42-.08-.627-.126C182.367,458.622,182.577,458.663,182.787,458.7Z" transform="translate(-177.794 -457.076)" />
                <path d="M132.844,441.765c-.312-.131-.62-.268-.924-.413Q132.376,441.57,132.844,441.765Z" transform="translate(-131.897 -441.341)" />
                <path d="M146.68,447.732c-.229-.082-.455-.17-.68-.26C146.225,447.562,146.452,447.65,146.68,447.732Z" transform="translate(-144.76 -446.932)" />
                <path d="M233.1,463.64c-.364.019-.73.031-1.1.031C232.369,463.671,232.735,463.659,233.1,463.64Z" transform="translate(-223.326 -461.702)" />
                <path d="M249.538,462.608c-.242.026-.486.045-.73.062C249.052,462.653,249.3,462.634,249.538,462.608Z" transform="translate(-238.682 -460.76)" />
                <path d="M275.219,458.576c-.208.046-.417.087-.627.126C274.8,458.663,275.011,458.622,275.219,458.576Z" transform="translate(-262.237 -457.076)" /><path d="M262.719,460.912c-.217.035-.436.065-.655.093C262.283,460.977,262.5,460.948,262.719,460.912Z" transform="translate(-250.792 -459.21)" /><path d="M322.312,441.368q-.453.217-.92.411C321.7,441.649,322.01,441.513,322.312,441.368Z" transform="translate(-304.991 -441.356)" /><path d="M158.759,452.122c-.212-.064-.422-.132-.631-.2C158.337,451.99,158.546,452.058,158.759,452.122Z" transform="translate(-155.84 -450.995)" /><path d="M318.293,446.128c-.1.041-.2.077-.293.115C318.1,446.2,318.2,446.169,318.293,446.128Z" transform="translate(-301.893 -445.704)" /><path d="M332.06,441.224l-.028.012Z" transform="translate(-314.712 -441.224)" />
                <path d="M177.679,457.546q-.213-.047-.423-.1Q177.467,457.5,177.679,457.546Z" transform="translate(-173.314 -456.046)" /><path d="M189.836,460.108c-.143-.023-.286-.05-.428-.076C189.551,460.058,189.693,460.085,189.836,460.108Z" transform="translate(-184.416 -458.406)" /><path d="M215.545,463.355c-.118-.006-.236-.019-.353-.027C215.31,463.336,215.427,463.349,215.545,463.355Z" transform="translate(-207.971 -461.417)" /><path d="M202.359,462.053c-.139-.015-.277-.035-.415-.053C202.082,462.018,202.22,462.037,202.359,462.053Z" transform="translate(-195.868 -460.204)" /><path d="M131.68,441.242l-.024-.01Z" transform="translate(-131.656 -441.231)" /><path d="M245.073,463.328c-.118.008-.235.021-.353.027C244.838,463.349,244.955,463.336,245.073,463.328Z" transform="translate(-234.947 -461.417)" /><path d="M294.3,454.256c-.134.04-.269.077-.4.115C294.031,454.333,294.166,454.3,294.3,454.256Z" transform="translate(-279.872 -453.13)" /><path d="M306.247,450.48c-.122.044-.245.083-.367.125C306,450.563,306.125,450.524,306.247,450.48Z" transform="translate(-290.82 -449.68)" /><path d="M142.909,446.251c-.1-.039-.2-.075-.293-.116C142.713,446.176,142.812,446.212,142.909,446.251Z" transform="translate(-141.669 -445.711)" /><path d="M154.247,450.6c-.122-.042-.246-.081-.367-.125C154,450.524,154.124,450.563,154.247,450.6Z" transform="translate(-151.959 -449.68)" />
                <path d="M257.671,462c-.138.018-.276.038-.415.053C257.395,462.037,257.533,462.018,257.671,462Z" transform="translate(-246.399 -460.204)" />
                <path d="M282.271,457.44q-.211.051-.423.1Q282.061,457.492,282.271,457.44Z" transform="translate(-268.866 -456.038)" /><path d="M270.06,460.032c-.142.026-.284.053-.428.076C269.776,460.085,269.918,460.058,270.06,460.032Z" transform="translate(-257.706 -458.406)" /><path d="M165.829,454.371c-.135-.037-.27-.075-.4-.115C165.558,454.3,165.693,454.333,165.829,454.371Z" transform="translate(-162.505 -453.13)" /></g>
            <path fill={fill} d="M73.377,87.209a8.3,8.3,0,1,0-9.18,0h0A15.923,15.923,0,0,0,53.344,98.622h0c.219.263.445.521.677.773.056.062.115.12.172.18.178.189.359.376.544.559.077.075.155.148.233.222.171.163.345.324.522.481.088.077.177.154.266.23q.26.223.528.437c.094.075.189.149.284.223q.272.21.551.408c.1.068.191.138.288.205.2.136.4.266.6.394.091.057.179.117.27.174.232.143.469.28.707.414.063.035.124.073.188.108q.463.253.94.483l.024.01q.455.218.924.413c.1.041.2.077.293.115.225.09.451.178.68.26.122.044.245.083.367.124.209.071.419.139.631.2.134.04.269.077.4.115q.307.085.619.161.211.051.423.1c.208.046.417.087.627.126.142.026.284.053.428.076.217.035.436.065.655.093.138.018.276.038.415.053.242.026.486.045.73.062.118.008.235.021.353.027.364.021.73.033,1.1.033s.735-.012,1.1-.031c.118-.006.236-.019.353-.027.245-.017.489-.036.73-.062.139-.015.277-.035.415-.053.219-.028.438-.058.655-.093.143-.024.286-.05.428-.076.21-.039.42-.08.627-.126q.213-.047.423-.1.311-.076.619-.161c.135-.037.27-.075.4-.115.212-.064.422-.132.631-.2.122-.041.246-.081.367-.124.229-.082.455-.17.68-.26.1-.039.2-.075.293-.115.31-.13.618-.266.92-.411l.028-.012q.477-.229.94-.483c.064-.035.125-.073.188-.108.239-.134.475-.271.707-.414.091-.056.18-.115.27-.174.2-.129.4-.259.6-.394.1-.067.192-.136.288-.205q.279-.2.551-.408c.1-.073.189-.148.284-.223q.268-.214.528-.437c.089-.076.178-.153.266-.23.177-.157.351-.318.522-.481.077-.074.156-.147.233-.222q.278-.274.544-.559c.057-.061.115-.12.172-.18.232-.252.458-.51.677-.773h0A15.921,15.921,0,0,0,73.377,87.209Z" transform="translate(-48.712 -65.776)" />
        </g></svg>
)

export default User