import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"
import { fetchAllStatusAndRoles, fetchUserInfo } from "../../../actions/global"
import { setPlayerInfo } from "../../../actions/imagePlayer"
import { fetchLimitSettings } from "../../../actions/settings"
import AuthPopup from "../../../views/AuthPopup"
import { Home } from "../../../views/home"
import { NavBar } from "../../../views/navbar"
import { UnitCosts } from "../../../views/unitCosts"
import { Users } from "../../../views/Users"
import ImagePlayer from "../ImagePlayer"
import SpinLoader from "../SpinLoader"

const LoggedInLayout = () => {
    const dispatch = useDispatch()
    const { autoOut, isLicense } = useSelector(state => state.global)
    const { playerInfo, isProcessing } = useSelector(state => state.imagePlayer)

    useEffect(() => {
        dispatch(fetchUserInfo())
        dispatch(fetchAllStatusAndRoles())
        dispatch(fetchLimitSettings())
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {autoOut && <AuthPopup isLicense={isLicense} />}
            <NavBar />
            {(!playerInfo && !!isProcessing) && <SpinLoader />}
            {!!playerInfo && <ImagePlayer playerInfo={playerInfo} handleClose={() => dispatch(setPlayerInfo(false))} />}
            <Routes>
                <Route path="" element={<Home />} />
                <Route path="unit-cost" element={<UnitCosts />} />
                <Route path="users" element={<Users />} />
            </Routes>
        </>
    )
}

export default LoggedInLayout