import {
    CHANGE_DESCRIPTION, CHANGE_END_DISTANCE, CHANGE_MAP_OVERLAY,
    CHANGE_MEASUREMENT_DATE, CHANGE_ROAD_NAME, CHANGE_ROAD_NAME_SECTION,
    CHANGE_ROAD_SECTION, CHANGE_START_DISTANCE, CHANGE_VALUE, SEARCH_RAOD_ATTR_LIST,
    CHANGE_VALUE_CONDITION, CHANGE_VARIABLE, SEARCH_RAOD_ATTR_PROCESSING, CHANGE_LINE_TYPE
} from "../actions/roads"

const initialState = {
    road_name: "",
    road_section: "",
    road_name_section: "",
    description: "",
    start_distance: "",
    end_distance: "",
    value: null,
    valueCondition: "",
    variable: "RL(d)",
    lineType: "",
    measurement_date: "",
    map_overlay: "",
    searchRdAttrList: [],
    searchRdProcessing: false
}

const roadReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_ROAD_NAME:
        case CHANGE_ROAD_SECTION:
        case CHANGE_ROAD_NAME_SECTION:
        case CHANGE_DESCRIPTION:
        case CHANGE_START_DISTANCE:
        case CHANGE_END_DISTANCE:
        case CHANGE_VALUE:
        case CHANGE_VALUE_CONDITION:
        case CHANGE_VARIABLE:
        case CHANGE_LINE_TYPE:
        case CHANGE_MEASUREMENT_DATE:
        case CHANGE_MAP_OVERLAY:
        case SEARCH_RAOD_ATTR_LIST:
        case SEARCH_RAOD_ATTR_PROCESSING:
            return Object.assign({}, state, action.payload)
        default:
            return state
    }
}

export default roadReducer