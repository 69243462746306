import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'
import MoveAbleWindow from "../../../components/common/MoveAbleWindow"
import RoadForm from "../RoadForm"
import RoadSearchAttrForm from "../RoadSearchAttrForm"
import MeasurementForm from "../MeasurementForm"
import Strategies from "../Strategies"
import MaintenancePlan from "../MaintenancePlan"
import "./index.scss"

const MoveableForm = ({ component, className, icon, minimize, title, selectorId = false }) => (
  <MoveAbleWindow className={className} toggleIcon={icon} minimize={minimize} title={title} selectorId={selectorId}>
    {component}
  </MoveAbleWindow>
)

const MapInput = () => {
  const { menuState, clickedNavs } = useSelector(state => state.navbar)
  const { t } = useTranslation()
  const activeMenu = menuState.find(a => a.status === true) || {}

  return <div className="map-input-divs">
    {/* {
      menuState.map((mn, i) => <Fragment key={i}>
        {(mn.name === 'search_road') &&
          <MoveableForm component={<RoadForm />} minimize={mn.status} icon='magnifier' title={t("navbar.menu.search_road")} selectorId='search_road' />}

        {(mn.name === 'search_road_attribute') &&
          <MoveableForm component={<RoadSearchAttrForm />} minimize={mn.status} icon='report' title={t("navbar.menu.search_rd_attr")} selectorId='search_road_attribute' />}

        {(mn.name === 'measurment') &&
          <MoveableForm component={<MeasurementForm />} minimize={mn.status} icon='worksplan' title={t("navbar.menu.measurment")} selectorId='measurment' />}

        {(mn.name === 'strategy') &&
          <MoveableForm component={<Strategies />} icon='strategy-head' minimize={mn.status} title={t("navbar.menu.strategy")} selectorId='strategy' className='strategy-moveable-comp' />}

        {(mn.name === 'maintenance_plan') &&
          <MoveableForm component={<div className="center-flex">Coming Soon - WIP</div>} minimize={mn.status} icon="file" title={t("navbar.menu.maintain_plan")} selectorId='maintenance_plan' />}

        {(mn.name === 'backlog') &&
          <MoveableForm component={<div className="center-flex">Coming Soon - WIP</div>} minimize={mn.status} icon="file" title={t("navbar.menu.backlog")} selectorId='backlog' />}
      </Fragment>)
    } */}
    {clickedNavs.includes('search_road') &&
      <MoveableForm component={<RoadForm />} minimize={activeMenu.name === 'search_road'} icon='magnifier' title={t("navbar.menu.search_road")} selectorId='search_road' />}

    {clickedNavs.includes('search_road_attribute') &&
      <MoveableForm component={<RoadSearchAttrForm />} minimize={activeMenu.name === 'search_road_attribute'} icon='report' title={t("navbar.menu.search_rd_attr")} selectorId='search_road_attribute' />}

    {clickedNavs.includes('measurment') &&
      <MoveableForm component={<MeasurementForm />} minimize={activeMenu.name === 'measurment'} icon='worksplan' title={t("navbar.menu.measurment")} selectorId='measurment' />}

    {clickedNavs.includes('strategy') &&
      <MoveableForm component={<Strategies />} icon='strategy-head' minimize={activeMenu.name === 'strategy'} title={t("navbar.menu.strategy")} selectorId='strategy' className='strategy-moveable-comp' />}

    {clickedNavs.includes('maintenance_plan') &&
      <MoveableForm component={<MaintenancePlan isActive={activeMenu.name === 'maintenance_plan'} />} minimize={activeMenu.name === 'maintenance_plan'} icon="file" title={t("navbar.menu.maintain_plan")} selectorId='maintenance_plan' />}

    {clickedNavs.includes('backlog') &&
      <MoveableForm component={<div className="center-flex">{t("backlog.comming_soon")} - WIP</div>} minimize={activeMenu.name === 'backlog'} icon="file" title={t("navbar.menu.backlog")} selectorId='backlog' />}
  </div>

  // return (
  //   <MoveAbleWindow>
  //     <Tabs transition={false} className="tab-body" activeKey={key} onSelect={(k) => setKey(k)}>
  //       {false && <Tab eventKey="wip" title="WIP" className="pt-3">
  //         <Grid item xs={12}>
  //           <div>
  //             <input
  //               type="checkbox"
  //               checked={showLayer1}
  //               onChange={(event) => {
  //                 setShowLayer1(event.target.checked);
  //               }}
  //             />{" "}
  //             RoadAttribute 105
  //           </div>

  //           {showLayer1 ? (
  //             <div>
  //               <input
  //                 type="checkbox"
  //                 checked={overValueCondition}
  //                 onChange={(event) => {
  //                   setOverValueCondition(event.target.checked);
  //                 }}
  //               />{" "}
  //               Value Over 80?
  //             </div>
  //           ) : ("")}
  //           <div>
  //             <input
  //               type="checkbox"
  //               //checked={redColor}
  //               //onChange={(event) => {setRedColor(event.target.checked);stylerfunc(event.target.checked)}}
  //               checked={showLayer2}
  //               onChange={(event) => {
  //                 setShowLayer2(event.target.checked);
  //               }}
  //             />{" "}
  //             RoadAttribute 540
  //           </div>
  //           {showLayer2 ? (
  //             <div>
  //               <input
  //                 type="checkbox"
  //                 checked={overValueCondition}
  //                 onChange={(event) => {
  //                   setOverValueCondition(event.target.checked);
  //                 }}
  //               />
  //               Value Over 10000?
  //             </div>
  //           ) : ("")}
  //           <div>
  //             <input
  //               type="checkbox"
  //               checked={showLayer3}
  //               onChange={(event) => setShowLayer3(event.target.checked)}
  //             />
  //             RoadAttribute 583
  //           </div>
  //           {showLayer3 ? (
  //             <div>
  //               <input
  //                 type="checkbox"
  //                 checked={overValueCondition}
  //                 onChange={(event) => {
  //                   setOverValueCondition(event.target.checked);
  //                 }}
  //               />
  //               Value Over 7.5?
  //             </div>
  //           ) : ("")}
  //         </Grid>
  //       </Tab>}
  //     </Tabs>
  //   </MoveAbleWindow>
  // )
}

export default MapInput