import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/common/Icons';
import WaywizeButton from '../../../components/common/WaywizeButton';
import { setMapType, setMapZoom } from '../../../actions/global';
import RMMSContext from '../../../context/RMMSContext';
import "./index.scss";

export const mapList = [
    { id: 'default', name: 'map_list.default', img: require('./images/layer1.png').default },
    { id: 'satellite', name: 'map_list.satellite', img: require('./images/layer2.png').default, apiUrl: 'https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg' },
    { id: 'voyager', name: 'map_list.light', img: require('./images/layer3.png').default, apiUrl: 'https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png' },
    { id: 'dark', name: 'map_list.dark', img: require('./images/layer4.png').default, apiUrl: 'https://api.maptiler.com/maps/darkmatter/{z}/{x}/{y}.png' }
];
const MapWidget = () => {
    const wrapperRef = useRef();
    const dispatch = useDispatch();
    const { mapType } = useSelector(state => state.global);
    const [layerOpt, setLayerOpt] = useState(false);
    const { map } = useContext(RMMSContext);
    const { t } = useTranslation();

    const handleClickOutside = event => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null) &&
            !wrapperRef.current.contains(event.target)) {
            setLayerOpt(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);

    const selectMode = id => {
        setLayerOpt(false);
        dispatch(setMapType(id));
    };

    const zoomOutIn = (zoomOut = true) => {
        const view = map.getView();
        const zoom = view.getZoom();
        dispatch(setMapZoom(zoomOut ? (zoom - 1) : zoom + 1));
    };

    return (<>
        <div className='map-widget-container' ref={wrapperRef}>
            <WaywizeButton className='layer-btn center-flex' title={t('button.list')} onClick={() => setLayerOpt(!layerOpt)}>
                <Icon name="stack" />
            </WaywizeButton>
            {
                !!layerOpt && <>
                    <div className='map-layer-list-notch' />
                    <div className='map-layer-list'>
                        {mapList.map((item, i) => (
                            <div className={`map-layer-list-item ${mapType === item.id ? 'active-mode' : ''}`} key={i} onClick={() => selectMode(item.id)}>
                                <img src={item.img} alt={t(item.name)} />
                                <div className='map-layer-list-item-name'>{t(item.name)}</div>
                            </div>
                        ))}
                    </div>
                </>
            }
            <div className='zoom-in-out-btn'>
                <WaywizeButton className='map-zoom-in-btn center-flex' title={t('button.zoom_in')} onClick={() => zoomOutIn(false)}>
                    <Icon name="plus" />
                </WaywizeButton>
                <WaywizeButton className='map-zoom-out-btn center-flex' title={t('button.zoom_out')} onClick={() => zoomOutIn()}>
                    <Icon name="minus" />
                </WaywizeButton>
            </div>
        </div>
    </>
    );
};

export default MapWidget;