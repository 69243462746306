import { SET_PLAYER_INFO, SET_PLAYER_IS_PROCESS } from "../actions/imagePlayer"

const initialState = {
    playerInfo: false,
    isProcessing: false
}

const imagePlayer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLAYER_INFO:
        case SET_PLAYER_IS_PROCESS:
            return Object.assign({}, state, action.payload)
        default:
            return state
    }
}

export default imagePlayer