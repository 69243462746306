import axios from "axios";
import { BrowserAuthError } from '@azure/msal-browser';
import { colourExport } from "../common/Features/Style";
import B2CConfig from "../services/B2CConfig";
import msalObj from '../services/MsalService';
import { colorCodes } from "./colors";
import { toast } from "react-toastify";
import { setAutoOut, setIsLicense, setUserInfo } from "../actions/global";
import store from "../common/Store";
import { REACT_APP_API_URL } from ".";

const {
    PINK_1, PINK_2, PINK_3, PINK_4,
    RED_3, ORANGE_1, ORANGE_2, ORANGE_3, LIGHT_RED_1, LIGHT_RED_2,
    PURPLE_1, PURPLE_2, PURPLE_3, PURPLE_4, PURPLE_5, PURPLE_6, PURPLE_7, PURPLE_8, PURPLE_9, PURPLE_10,
    LIGHT_GREEN_3, LIGHT_GREEN_4, LIGHT_GREEN_5, LIGHT_GREEN_6, LIGHT_GREEN_7, LIGHT_GREEN_8, LIGHT_GREEN_9,
    LIGHT_GREEN_10, DARK_GREEN_1, YELLOW_2, YELLOW_3, YELLOW_4, GREY_6,
    LIGHT_BLUE_2, LIGHT_BLUE_3, LIGHT_BLUE_4, LIGHT_BLUE_5, LIGHT_BLUE_6, LIGHT_BLUE_7, LIGHT_BLUE_8,
    BLUE_7, BLUE_8, GREEN_8, BLACK_3, BLACK_4, GREEN_1, GREEN_3, GREEN_5, GREEN_9, GREEN_10, GREEN_11, GREEN_12
} = colorCodes;

export const getApiToken = async () => {
    const tokenRequest = {
        scopes: B2CConfig.apiScopes,
        forceRefresh: false
    };
    try {
        return await msalObj.acquireTokenSilent(tokenRequest);
    } catch (err) {
        try {
            return await msalObj.acquireTokenPopup(tokenRequest);
        } catch (err) {
            if (
                err instanceof BrowserAuthError &&
                err.errorCode === 'user_cancelled'
            ) {
                // Do nothing
            } else {
                console.error(err);
            }
            return null;
        }
    }
};


/**
 * Handle request
 * @param {*} method 
 * @param {*} serverUrl 
 * @param {*} data 
 * @param {*} contentType 
 * @param {*} acceptType 
 * @returns 
 */
export const handleRequest = async (method, serverUrl = false, data, contentType = false, acceptType = false) => {
    const tokenObj = await getApiToken();
    return new Promise((resolve, reject) => {
        axios({
            method,
            url: !!serverUrl ? serverUrl : REACT_APP_API_URL,
            headers: {
                ...(acceptType && { 'Accept': acceptType }),
                ...(contentType && { 'Content-Type': contentType }),
                ...(tokenObj !== null && { Authorization: 'Bearer ' + tokenObj.accessToken })
            },
            ...(!!data && { data })
        })
            .then(result => {
                if (!!result && !!result.data && !!result.data.errors) {
                    const { errors } = result.data;
                    errors.map(e => {
                        if (!!e.message && (e.message === "Unauthorized user")) {
                            toast.error("You're not allowed to perform this action");
                        }
                        return e;
                    });
                }
                store.dispatch(setAutoOut(false));
                store.dispatch(setIsLicense(true));
                return resolve(!!result && !!result.data && !!result.data.data ? result.data.data : result);
            })
            .catch(e => {
                if (!!e && !!e.response && !!e.response.status && e.response.status === 401) {
                    store.dispatch(setAutoOut(true));
                    store.dispatch(setUserInfo({}));
                }

                if (!!e && !!e.response && !!e.response.status && (e.response.status === 500) && !!e.response.data) {
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(e.response.data, 'text/html');
                    if (!!htmlDoc) {
                        const preTag = htmlDoc.getElementsByTagName('pre');
                        if (!!preTag && !!preTag[0] && !!preTag[0].innerText && ((preTag[0].innerText) === 'License key not found')) {
                            store.dispatch(setIsLicense(false));
                            store.dispatch(setAutoOut(true));
                            store.dispatch(setUserInfo({}));
                        }
                    }
                }
                return reject(e);
            });
    });
};


/**
 * Sleep function
 * for some delay
 * @param {*} ms 
 * @returns 
 */
export const sleep = (ms = 3000) => new Promise(resolve => setTimeout(() => resolve(true), ms));

export const colorSetFor2021 = value => {
    if (value >= 40 && value < 50) return GREEN_9;
    else if (value >= 50 && value < 60) return GREEN_10;
    else if (value >= 60 && value < 70) return GREEN_11;
    else if (value >= 70 && value < 80) return GREEN_1;
    else if (value >= 80 && value < 90) return GREEN_3;
    else if (value >= 90 && value < 100) return GREEN_5;
    else if (value >= 100 && value < 110) return GREEN_12;
    else if (value >= 100 && value < 120) return LIGHT_GREEN_3;
    else if (value >= 120) return LIGHT_GREEN_3;
};

export const colorSetFor10698 = value => {
    if (value === "TBK1") return PINK_1;
    else if (value === "TBK2") return PINK_2;
    else if (value === "TBK3") return PINK_3;
    else if (value === "TBK4") return PINK_4;
};

export const colorSetForRLD = value => {
    if (value === '0-130') return RED_3;
    else if (value === '130-150') return ORANGE_2;
    else if (value === '150-200') return LIGHT_GREEN_4;
    else if (value === '200-') return DARK_GREEN_1;
};

export const colorSetForVisual = value => {
    if (value === 0) return RED_3;
    else if (value === 1) return ORANGE_2;
    else if (value === 2) return YELLOW_2;
    else if (value === 3) return LIGHT_GREEN_4;
    else if (value === 4) return DARK_GREEN_1;
};

export const colorSetForMobilEye = value => {
    if (value >= 0 && value < 0.7) return RED_3;
    //else if (value >= 0.60 && value < 0.6) return ORANGE_2
    else if (value >= 0.7 && value < 0.8) return YELLOW_2;
    else if (value >= 0.80 && value < 0.90) return LIGHT_GREEN_4;
    else if (value >= 0.90) return DARK_GREEN_1;
};

export const colorSetFor5555 = value => {
    if (value === 6) return BLACK_3;
    else if (value === 7) return BLACK_4;
    else if (value === 8) return BLUE_7;
    else if (value === 9) return BLUE_8;
    else if (value === 10) return LIGHT_BLUE_4;
    else if (value === 11) return LIGHT_BLUE_5;
    else if (value === 12) return GREEN_8;
    else if (value === 13) return LIGHT_BLUE_6;
    else if (value === 14) return LIGHT_BLUE_7;
    else if (value === 15) return LIGHT_BLUE_8;
    else if (value === 16) return LIGHT_GREEN_7;
    else if (value === 17) return LIGHT_GREEN_8;
    else if (value === 18) return LIGHT_GREEN_9;
    else if (value === 19) return YELLOW_3;
    else if (value === 20) return YELLOW_4;
};

export const colorSetFor4623 = value => {
    if (value >= 0 && value < 2000) return PURPLE_1;
    else if (value >= 2000 && value < 4000) return PURPLE_2;
    else if (value >= 4000 && value < 6000) return PURPLE_3;
    else if (value >= 6000 && value < 8000) return PURPLE_4;
    else if (value >= 8000 && value < 10000) return PURPLE_5;
    else if (value >= 10000 && value < 15000) return PURPLE_6;
    else if (value >= 15000 && value < 20000) return PURPLE_7;
    else if (value >= 20000 && value < 30000) return PURPLE_8;
    else if (value >= 30000 && value < 40000) return PURPLE_9;
    else if (value >= 40000) return PURPLE_10;
};

export const mapAttributeChange = ((feature, searchType) => {
    //so we need to check the value first
    //RLD limit
    if (searchType === "RL(d)") {
        //color schmea 0-130 -->Red
        return colourExport(colorSetForRLD(feature.properties_.variable_class_name));
    }
    else if (searchType === "Visual") {
        //color schmea 0-130 -->Red
        try {
            return colourExport(colorSetForVisual(parseInt(feature.properties_.value)));
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === 'MobilEye') {
        try {
            const value = parseInt(feature.properties_.value) / 100;
            return colourExport(colorSetForMobilEye(value));
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === 'roadSearch') {
        try {
            //simpily returing the roadSearch as no value in it
            return colourExport(DARK_GREEN_1);
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '86') {
        try {
            const value = feature.properties_.value;
            if (value) return colourExport(LIGHT_GREEN_10);
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '11801') {
        try {
            const value = feature.properties_.value;
            if (value) return colourExport(GREY_6); //red
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '_5178') {
        try {
            const value = feature.properties_.value;
            if (value) return colourExport(LIGHT_GREEN_10); //red
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '1216') {
        try {
            const value = feature.properties_.value;
            if (value) return colourExport(GREY_6); //red
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '5555') {
        try {
            const value = parseInt(feature.properties_.value);
            return colourExport(colorSetFor5555(value));
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '2021') {
        try {
            return colourExport(colorSetFor2021(parseInt(feature.properties_.value)));
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '10945') {
        try {
            const value = feature.properties_.value;
            if (value) return colourExport(LIGHT_RED_2);
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '10698') {
        try {
            return colourExport(colorSetFor10698(feature.properties_.value));
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '4623') {
        try {
            return colourExport(colorSetFor4623(parseInt(feature.properties_.value) / 100));
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '1081') {
        try {
            const value = feature.properties_.value;
            if (value) return colourExport(GREY_6); //red
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '9537') {
        try {
            return colourExport(colorSetFor5555(parseInt(feature.properties_.value)));
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '5264') {
        try {
            return colourExport(colorSetFor5555(parseInt(feature.properties_.value)));
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '1114') {
        try {
            const value = feature.properties_.value;
            if (value) return colourExport(LIGHT_RED_1);
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '9500' || searchType === '9501') {
        try {
            const value = feature.properties_.value;
            if (value === "Forsterket kantoppmerking") return colourExport(ORANGE_1);
            else if (value === "Forsterket midtoppmerking") return colourExport(ORANGE_3);
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    // HINT: Map Layers not found
    else if (searchType === '9502') {
        try {
            const value = feature.properties_.value;
            console.log("WIP - vlaue for 9502 ", value);
            if (value === "Forsterket kantoppmerking") return colourExport(PURPLE_1);
            else if (value === "Forsterket midtoppmerking") return colourExport(PURPLE_2);
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
    else if (searchType === '1') {
        try {
            const value = feature.properties_.value;
            console.log("WIP - vlaue for 1 ", value);
            if (value === "Forsterket kantoppmerking") return colourExport(PURPLE_1);
            else if (value === "Forsterket midtoppmerking") return colourExport(PURPLE_2);
        }
        catch (e) {
            console.log(`Some error: ${e}`);
        }
    }
});

/*const checkFeature = ((feature, featureName, filterValue, style) => {
    if (featureName === 'startDis') {
        if (feature.properties_.start_distance > filterValue) return style;
    }
    else if (featureName === 'endDis') {
        if (feature.properties_.end_distance < filterValue) return style;
    }
    else if (featureName === 'roadName') {
        if ((feature.properties_.roadname).includes(filterValue)) return style;
    }
});


const checkInputTagValue = (featureName, inputTagName) => {
    if (featureName === 'startDis' || 'endDis' || 'roadName') {
        if (inputTagName !== '') return true;
    }
    else {
        return false;
    }
};

/*export const mapAttributeFilter = ((feature, searchType, roadName, startDis, endDis) => {
    let styleReturn = mapAttributeChange(feature, searchType);
    if (roadName || startDis || endDis || mDist) {
        if (roadName !== "") {
            styleReturn = checkFeature(feature, 'roadName', roadName, styleReturn);
        }
        if (startDis !== "") {
            if (roadName !== "") {
                console.log("right value");
                if ((feature.properties_.roadname).includes(roadName) && feature.properties_.start_distance > startDis) {
                    return styleReturn;
                }
            }
            else { styleReturn = checkFeature(feature, 'startDis', startDis, styleReturn); };
        }
        if (endDis !== "") {
            if (roadName !== "" && startDis === "") {
                if ((feature.properties_.roadname).includes(roadName) && feature.properties_.end_distance < endDis) {
                    return styleReturn;
                }
            }
            if (startDis !== "") {
                if ((feature.properties_.roadname).includes(roadName) && feature.properties_.start_distance > startDis && feature.properties_.end_distance < endDis) {
                    return styleReturn;
                }
            }
            styleReturn = checkFeature(feature, 'endDis', endDis, styleReturn);
        }
        //console.log("should not come here");
        //return styleReturn;
    }
    return styleReturn;
});*/


const mapAttributeChange_Strategy = ((feature, data) => {
    const goodConditionList = data.goodCondition;
    const selectedList = data.selected;
    const notSelectedBadConditionList = data.notSelected;

    const listFilterFunction = ((list = []) => {
        return list.some(item =>
            item.roadname === feature.properties_["roadname"] &&
            item.rmms_linetype_id === feature.properties_["rmms_linetype_id"] &&
            feature.properties_["start_distance"] >= item.start_distance &&
            feature.properties_["end_distance"] <= item.end_distance);
    });


    const goodCheck = listFilterFunction(goodConditionList);
    const selectedCheck = listFilterFunction(selectedList);
    const notSelectedCheck = listFilterFunction(notSelectedBadConditionList);

    if (goodCheck) return colourExport(DARK_GREEN_1);
    else if (selectedCheck) return colourExport(RED_3);
    else if (notSelectedCheck) return colourExport(YELLOW_2);

});

// TODO: Change this to non static version
// 0--> Planned
// 1--> Ordered
// 2--> Flushed 
// 3--> Pre-Marked 
// 4--> Marked 
// 5--> Q/A 
// 6--> Invoiced 
// 7--> Accepted 



const mapAttributeChange_hovedPlan = ((feature, data) => {
    if (data !== undefined && feature !== null) {
        let plannedSegments = false;
        let orderedSegments = false;
        let flushedSegments = false;
        let preMarkedSegments = false;
        let marked = false;
        let qASegments = false;
        let invoicedSegments = false;
        let acceptedSegments = false;

        for (var i = 0; i < data.length; i++) {
            const item = data[i]
            if (feature.properties_["roadname"] === item.roadname &&
                item.rmms_linetype_code === feature.properties_["rmms_line_code"] &&
                feature.properties_["start_distance"] >= item.start_distance &&
                feature.properties_["end_distance"] <= item.end_distance &&
                feature.properties_["direction"] === item.direction) {

                plannedSegments = item.status === 0;
                orderedSegments = item.status === 1;
                flushedSegments = item.status === 2;
                preMarkedSegments = item.status === 3;
                marked = item.status === 4;
                qASegments = item.status === 5;
                invoicedSegments = item.status === 6;
                acceptedSegments = item.status === 7;
                if (!!plannedSegments || !!orderedSegments || !!flushedSegments || !!preMarkedSegments ||
                    !!marked || !!qASegments || !!invoicedSegments || !!acceptedSegments) {
                    break
                }
            }
        }

        // const listFilterFunction = ((list = [], statusId) => {
        //     if (list !== null) {
        //         return list.some(item =>
        //             // feature.properties_["roadname"].includes(item.road_category) &&
        //             // feature.properties_["roadname"].includes(item.roadNumber) &&
        //             // feature.properties_["roadname"].includes(item.part) &&
        //             feature.properties_["roadname"] === item.roadname &&
        //             item.rmms_linetype_code === feature.properties_["rmms_line_code"] &&
        //             feature.properties_["start_distance"] >= item.start_distance &&
        //             feature.properties_["end_distance"] <= item.end_distance &&
        //             feature.properties_["direction"] === item.direction &&
        //             item.status === statusId);
        //     }
        // });

        // const plannedSegments = listFilterFunction(data, 0);
        // const orderedSegments = listFilterFunction(data, 1);
        // const flushedSegments = listFilterFunction(data, 2);
        // const preMarkedSegments = listFilterFunction(data, 3);
        // const marked = listFilterFunction(data, 4);
        // const qASegments = listFilterFunction(data, 5);
        // const invoicedSegments = listFilterFunction(data, 6);
        // const acceptedSegments = listFilterFunction(data, 7);

        if (plannedSegments) return colourExport(RED_3);
        else if (orderedSegments) return colourExport(LIGHT_GREEN_5);
        else if (flushedSegments) return colourExport(LIGHT_GREEN_6);
        else if (preMarkedSegments) return colourExport(LIGHT_BLUE_2);
        else if (marked) return colourExport(LIGHT_BLUE_3);
        else if (qASegments) return colourExport(YELLOW_2);
        else if (invoicedSegments) return colourExport(LIGHT_GREEN_4);
        else if (acceptedSegments) return colourExport(DARK_GREEN_1);
    }
});



let filterObject = {
    roadname: {
        propName: "roadname",
        compare: "include",
        value: ""
    },
    start_distance: {
        propName: "startDis",
        compare: "greater",
        value: ""
    },
    end_distance: {
        propName: "endDis",
        compare: "lesser",
        value: ""
    },
};

//Making a for loop of conditions
const conditionFunction = ((filterList, feature) => {
    let isTrue = true;
    for (let index = 0; index < filterList.length; index++) {
        const item = filterList[index];
        if (item.compare === "include") {
            isTrue = isTrue && (feature.properties_[item.propName]).includes(item.value);
        }
        else if (item.compare === "greater") {
            isTrue = isTrue && feature.properties_[item.propName] > item.value;
        }
        else if (item.compare === "lesser") {
            isTrue = isTrue && feature.properties_[item.propName] < item.value;
        }
    };
    return isTrue;
});


export const mapAttributeFilter = ((feature, searchType, formParameterObject, strategyObjectList = [], hovedPlanSection = []) => {
    let styleReturn;

    if (searchType === 'strategy') {
        styleReturn = mapAttributeChange_Strategy(feature, strategyObjectList);
    }
    else if (searchType === 'hovedPlan') {
        styleReturn = mapAttributeChange_hovedPlan(feature, hovedPlanSection);
    }
    else {
        styleReturn = mapAttributeChange(feature, searchType);
    }
    const { roadname, start_distance, end_distance } = formParameterObject;
    if (roadname || start_distance || end_distance) {
        let newObj = {};
        newObj = Object.keys(formParameterObject)
            .filter((key) => formParameterObject[key] !== "" && formParameterObject[key] !== undefined && (typeof (formParameterObject[key]) === 'number' ? (isNaN(formParameterObject[key]) !== true) : true))
            .map(mappedValue =>
            ({
                ...newObj, ...{
                    propName: mappedValue,
                    compare: filterObject[mappedValue]["compare"],
                    value: formParameterObject[mappedValue]
                }
            }));
        const isTrue = conditionFunction(newObj, feature);
        if (isTrue) return styleReturn;
    }

    else {
        return styleReturn;
    }

});

/*export const mapAttributeFilter = ((feature, searchType, roadName, startDis, endDis, mDist) => {
    let returnFeature = mapAttributeChange(feature, searchType);
    if (roadName !== "" || startDis !== "" || endDis !== "" || mDist !== "") {
        console.log("not here");
        if (roadName !== "") {
            returnFeature = checkFeature(feature, 'roadName', roadName);
        }
        if (startDis !== "") {
            returnFeature = checkFeature(returnFeature, 'startDis', startDis);
        }
        if (endDis !== "") {
            returnFeature = checkFeature(feature, 'endDis', endDis);
        }
    }
    return returnFeature;
});*/


/**
 * Parse Line Type
 * @param {*} lineType 
 * @returns 
 */
export const parseLineType = lineType => {
    switch (lineType) {
        case 'left_edge_line_n':
        case 'left_edge_line':
            return 'Left Edge Line';
        case 'right_edge_line_n':
            return 'Right Edge Line';
        case 'center_line':
            return 'Center Line';
        case 'right_middle_line':
            return 'Right Middle Line';
        case 'lane_line_2':
            return 'Lane Line 2';
        case 'left_middle_line':
            return 'Left Middle Line';
        case 'right_edge_line':
            return 'Right Edge Line';
        case 'lane_line_1':
            return 'Lane Line 1';
        case 'lane_line_3':
            return 'Lane Line 3';
        default:
            return lineType;
    }
};


/**
 * Return only numeric value
 * @param {*} val 
 * @returns 
 */
export const onlyNumber = val => parseInt(val.replace(/\D/g, ''));


/**
 * Capitalize Words
 * @param {*} val 
 * @returns 
 */
export const capitalizeWords = val =>
    val.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });