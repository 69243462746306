import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Caret = ({
    fill = colorCodes.BLUE_4,
    height = '12',
    width = '22',
    style = {},
    viewBox = '0 0 22 12',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <path d="M.293,11.707l10,10a1,1,0,0,0,1.414-1.414L2.414,11l9.293-9.293A1,1,0,1,0,10.293.293l-10,10a1,1,0,0,0,0,1.414Z" transform="translate(22) rotate(90)" fill={fill} />
    </svg>
)

export default Caret