import { toast } from "react-toastify"
import { handleRequest } from "../constant/helpers"

export const UC_IS_PROCESSING = "UC_IS_PROCESSING"
export const SET_ALL_UC = "SET_ALL_UC"
export const SET_ALL_UC_THICKNESS = "SET_ALL_UC_THICKNESS"
export const EDIT_UC_SUCCESS = "EDIT_UC_SUCCESS"

export const setUcIsProcessing = isProcessing => ({
    type: UC_IS_PROCESSING,
    payload: { isProcessing }
})

export const setAllUc = unitCosts => ({
    type: SET_ALL_UC,
    payload: {
        isProcessing: false,
        unitCosts
    }
})

export const setAllUcThickness = thicknessList => ({
    type: SET_ALL_UC_THICKNESS,
    payload: {
        isProcessing: false,
        thicknessList
    }
})

const editUCSuccess = (unitCost) => ({
    type: EDIT_UC_SUCCESS,
    unitCost
})

/**
 * Fetch All Variables
 * for Search Road Attr
 */
export const fetchAllUC = () => async (dispatch) => {
    dispatch(setUcIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `
            query {
                getUnitCostList {
                    id
                    address_system_id
                    year
                    marking_material
                    marking_profile
                    marking_width
                    marking_thickness
                    unit_cost
                    marking_color
                    code
                    description
                  }
            }`
        })

        if (!!resp && !!resp.getUnitCostList) {
            dispatch(setAllUc(resp.getUnitCostList))
        } else {
            dispatch(setAllUc([]))
        }
        return resp
    } catch (e) {
        dispatch(setUcIsProcessing(false))
        console.log('fetchAllUC Error: ', e)
        return e
    }
}

/**
 * Fetch All Thickness
 * and store in global state
 */
 export const fetchAllUCThickness = () => async (dispatch) => {
    dispatch(setUcIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `
            query {
                getUnitCostList {
                    id
                    marking_thickness
                  }
            }`
        })

        if (!!resp && !!resp.getUnitCostList && !!resp.getUnitCostList.length) {
            const uniqueThickness = [...new Set((resp.getUnitCostList).map(item => parseInt(item.marking_thickness)))]
            dispatch(setAllUcThickness(uniqueThickness))
        } else {
            dispatch(setAllUcThickness([]))
        }
        return resp
    } catch (e) {
        dispatch(setUcIsProcessing(false))
        console.log('fetchAllUC Error: ', e)
        return e
    }
}


/**
 * Edit Unit Cost
 * @param {*} data 
 * @param {*} successCallback 
 * @returns 
 */
export const editUnitCost = (data, successCallback) => async (dispatch) => {
    dispatch(setUcIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `
        query EditUnitCostByID($data: unitCostInput) {
            editUnitCostByID(data: $data) {
                id
                address_system_id
                year
                marking_material
                marking_profile
                marking_width
                marking_thickness
                unit_cost
                marking_color
                code
                description
            }
        }`,
            variables: { data }
        })

        dispatch(setUcIsProcessing(false))

        if (!!resp && !!resp.editUnitCostByID && !!resp.editUnitCostByID[0]) {
            successCallback()
            dispatch(editUCSuccess(resp.editUnitCostByID[0]))
            toast.success('Updated Successfully')
        }
    } catch (err) {
        console.log('editUnitCost Error:', err);
        dispatch(setUcIsProcessing(false))
    }
}
