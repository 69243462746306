import React from 'react'
import { colorCodes } from '../../../constant/colors'

const QuestionIcon = ({
    fill = colorCodes.BLUE_3,
    height = 24,
    width = 24,
    style = {},
    viewBox = '0 0 24 24',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)} fill={fill}>
        <path d="M12 24C5.4 24 0 18.6 0 12S5.4 0 12 0s12 5.4 12 12-5.4 12-12 12zm0-22C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z" />
        <path d="M12 16c-.6 0-1-.4-1-1v-.7c0-1.5.8-2.8 2-3.5.8-.4 1.2-1.3 1-2.1-.1-.8-.8-1.5-1.6-1.6-.6-.1-1.2 0-1.7.4-.4.3-.7.9-.7 1.5s-.4 1-1 1-1-.4-1-1c0-1.2.5-2.3 1.4-3.1.9-.8 2.1-1.1 3.3-.9 1.6.3 2.9 1.6 3.2 3.2.3 1.7-.5 3.4-2 4.2-.6.3-.9 1-.9 1.8v.8c0 .6-.4 1-1 1z" />
        <circle cx="12" cy="18" r="1" />
        <path d="M12 19.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm0-2c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5z" />
    </svg>
)

export default QuestionIcon