import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUC } from '../../actions/unitCost';
import CommonTable from '../../components/common/CommonTable'
import CustomPopUp from '../../components/common/CustomPopUp';
import EditUC from './EditUC';
import './index.scss'

const sprayDummyData = [
  { line_width: 0.10, thickness: 1.5, colour: "Yellow", cost: 10.0 },
  { line_width: 0.15, thickness: 1.5, colour: "Yellow", cost: 10.0 },
  { line_width: 0.10, thickness: 1.0, colour: "Yellow", cost: 10.0 },
  { line_width: 0.15, thickness: 1.0, colour: "White", cost: 10.0 },
  { line_width: 0.10, thickness: 1.5, colour: "White", cost: 10.0 },
  { line_width: 0.15, thickness: 1.5, colour: "White", cost: 10.0 },
  { line_width: 0.20, thickness: 1.0, colour: "White", cost: 10.0 },
  { line_width: 0.20, thickness: 1.5, colour: "White", cost: 10.0 },
  { line_width: 0.30, thickness: 1.0, colour: "White", cost: 10.0 },
  { line_width: 0.30, thickness: 1.5, colour: "White", cost: 10.0 }
]

const thermoDummyData = [
  { profile_type: 'Plan', width: 0.10, thickness: 2.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Plan', width: 0.15, thickness: 2.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Plan', width: 0.30, thickness: 2.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Plan', width: 0.10, thickness: 3.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Plan', width: 0.15, thickness: 3.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Plan', width: 0.30, thickness: 3.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Longflex', width: 0.10, thickness: 3.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Longflex', width: 0.15, thickness: 3.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Longflex', width: 0.30, thickness: 3.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Kamflex', width: 0.15, thickness: 3.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Kamflex', width: 0.30, thickness: 3.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Dråpeflex', width: 0.15, thickness: 4.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Dråpeflex', width: 0.30, thickness: 4.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Dråpeflex', width: 0.30, thickness: 4.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Dråpekombi', width: 0.15, thickness: 5.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Dråpekombi', width: 0.30, thickness: 5.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Dråpekombi', width: 0.30, thickness: 5.0, colour: "Yellow", cost: 10.0 },
  { profile_type: 'Plan', width: 0.10, thickness: 2.0, colour: "White", cost: 10.0 },
  { profile_type: 'Plan', width: 0.15, thickness: 2.0, colour: "White", cost: 10.0 },
  { profile_type: 'Plan', width: 0.20, thickness: 2.0, colour: "White", cost: 10.0 },
  { profile_type: 'Plan', width: 0.30, thickness: 2.0, colour: "White", cost: 10.0 },
  { profile_type: 'Plan', width: 0.10, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Plan', width: 0.15, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Plan', width: 0.20, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Plan', width: 0.30, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Longflex', width: 0.10, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Longflex', width: 0.15, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Longflex', width: 0.20, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Longflex', width: 0.30, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Kamflex', width: 0.15, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Kamflex', width: 0.30, thickness: 3.0, colour: "White", cost: 10.0 },
  { profile_type: 'Dråpeflex', width: 0.10, thickness: 4.0, colour: "White", cost: 10.0 },
  { profile_type: 'Dråpeflex', width: 0.15, thickness: 4.0, colour: "White", cost: 10.0 },
  { profile_type: 'Dråpeflex', width: 0.20, thickness: 4.0, colour: "White", cost: 10.0 },
  { profile_type: 'Dråpeflex', width: 0.30, thickness: 4.0, colour: "White", cost: 10.0 },
  { profile_type: 'Dråpekombi', width: 0.10, thickness: 5.0, colour: "White", cost: 10.0 },
  { profile_type: 'Dråpekombi', width: 0.15, thickness: 5.0, colour: "White", cost: 10.0 },
  { profile_type: 'Dråpekombi', width: 0.20, thickness: 5.0, colour: "White", cost: 10.0 },
  { profile_type: 'Dråpekombi', width: 0.30, thickness: 5.0, colour: "White", cost: 10.0 },
]

const flushingDummyData = [
  { type: 'Flushing Edge Line', cost: 5000 },
  { type: 'Flushing Center Line', cost: 5000 }
]

const sprayColumns = [
  { Header: "table.line_width", accessor: "line_width", Filter: false },
  { Header: "table.thickness", accessor: "thickness", Filter: false },
  { Header: "table.colour", accessor: "colour", Filter: false },
  { Header: "table.price_meter", accessor: "cost", Filter: false }
]

const thermoColumns = [
  { Header: "table.profile", accessor: "profile_type", Filter: false },
  { Header: "table.line_width", accessor: "width", Filter: false },
  { Header: "table.thickness", accessor: "thickness", Filter: false },
  { Header: "table.colour", accessor: "colour", Filter: false },
  { Header: "table.price_meter", accessor: "cost", Filter: false }
]

const flushingColumns = [
  { Header: "Type", accessor: "type", Filter: false },
  { Header: "Unit cost [NOK/km]", accessor: "cost", Filter: false }
]

const columns = [
  { Header: "table.profile", accessor: "marking_profile", Filter: false },
  { Header: "table.line_width", accessor: "marking_width", Filter: false },
  { Header: "table.thickness", accessor: "marking_thickness", Filter: false },
  { Header: "table.colour", accessor: "marking_color", Filter: false },
  { Header: "table.price_meter", accessor: "unit_cost", Filter: false }
]

export function UnitCosts({
  inPopUp = false
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [ucInfo, setUCInfo] = useState({})

  useEffect(() => {
    dispatch(fetchAllUC())

    // eslint-disable-next-line
  }, [])

  const { unitCosts, isProcessing } = useSelector(state => state.unitCost)
  const { userInfo } = useSelector(state => state.global)

  const editUCFn = (info) => {
    setUCInfo(info)
    setShow(true)
  }

  const handleOnHide = () => {
    setShow(false)
    setUCInfo({})
  }

  return (
    <div className={`unit-costs-container ${!inPopUp ? 'route-ui' : ''}`}>
      <div className="unit-costs-cont-heading between-flex">
        {!inPopUp && <div className='uc-headin-title'>{t('unit_cost.unit_costs')}</div>}
        <div className='uc-headin-revision'>{t('unit_cost.last_rev_made')}</div>
      </div>

      {/* HINT: Not in use AON */}
      {false && <div className='uc-context'>
        <Row className='center-flex'>
          <Col xs={10}>
            <div className='uc-context-table'>
              <div className='uc-table-title'>{t('unit_cost.sprayplastic')}</div>
              <CommonTable columns={sprayColumns} data={sprayDummyData} />
            </div>
          </Col>
        </Row>

        <Row className='center-flex'>
          <Col xs={10}>
            <div className='uc-context-table'>
              <div className='uc-table-title'>{t('unit_cost.extruder')} ({t('unit_cost.thermoplastic')})</div>
              <CommonTable columns={thermoColumns} data={thermoDummyData} />
            </div>
          </Col>
        </Row>
      </div>}

      {
        !!show && <CustomPopUp title={t('unit_cost.edit_uc')} handleClose={() => handleOnHide()}>
          <EditUC isProcessing={isProcessing} info={ucInfo} onSuccess={() => setShow(false)} />
        </CustomPopUp>
      }

      <div className='uc-context'>
        <Row className='center-flex'>
          <Col xs={10}>
            <div className='uc-context-table'>
              {
                !!unitCosts.length ?
                  <CommonTable columns={columns} data={unitCosts}
                    actionBtn={true}
                    editFn={(info) => editUCFn(info.original || {})} /> :
                  <h3 className="center-flex h3-fetching-text">{!!isProcessing ? `${t('unit_cost.fetching')}...` : t('unit_cost.no_records')}</h3>
              }
            </div>
          </Col>
        </Row>

        {
          !!userInfo && !!userInfo.role_id && userInfo.role_id !== 3 &&
          <Row className='center-flex'>
            <Col xs={10}>
              <div className='uc-context-table'>
                <div className='uc-table-title'>{t('unit_cost.flushing')}</div>
                <CommonTable columns={flushingColumns} data={flushingDummyData} />
              </div>
            </Col>
          </Row>
        }
      </div>
    </div>
  )
}
