import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { ProfileSection } from './ProfileSection';
import { onClickNavMenu } from '../../actions/navbar';
import { menuList } from '../../constant';
import './index.scss'

export function NavBar() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { active, clickedNavs } = useSelector(state => state.navbar)
    const [isActive, setIsActive] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {
        window.addEventListener('resize', handleResize, false)
        return () => {
            window.removeEventListener('resize', handleResize, false)
        }
        // eslint-disable-next-line
    }, [])

    // On window resize close mobile menu
    const handleResize = () => {
        if (isActive) {
            setIsActive(false)
        }
    }

    const onClickNav = val => {
        navigate('/rmms/')
        dispatch(onClickNavMenu(val, clickedNavs))
    }

    const mobileClick = val => {
        setIsActive(false)
        onClickNav(val)
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" className="navbar-header">
                <Toolbar variant="dense" className="nav-box-container">
                    <div className={`menu-list-holder ${isActive ? 'is-active' : ''}`}>
                        <button onClick={() => setIsActive(false)} className="nav-bar-menu-cls-btn">Close &times;</button>
                        {menuList.map((m, i) => <div className={`navbar-mobile-menu-item ${(m.id === active) ? 'active-op' : ''}`} onClick={() => mobileClick(m.id)} key={i}>{t(m.title)}</div>)}
                        <Link className="navbar-mobile-menu-item" onClick={() => setIsActive(false)} to="/rmms/">Home</Link>
                        <Link className="navbar-mobile-menu-item" onClick={() => setIsActive(false)} to="/rmms/unit-cost">Unit Cost</Link>
                    </div>
                    <IconButton className='navbar-menu-toggle' onClick={() => setIsActive(true)} edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" component="div" className='between-flex w-100'>
                        <div className="d-flex logo-section ai-center">
                            <div className="logo-title">RMMS <span className="nav-mode-value">BETA</span></div>
                            <img src={require('../../assets/images/sm-logo.png').default} alt="RMMS" />
                            <div className="d-flex ml-20" style={{ height: 80 }}>
                            <img src={require('../../assets/images/brand-logo-white-small.svg').default} alt="Statens vegvesen" />
                        </div>
                        </div>

                        <div className="d-flex nav-menu-list">
                            {menuList.map((m, i) => <div className={`nav-menu-list-item ${(m.id === active) ? 'active-op' : ''}`} onClick={() => onClickNav(m.id)} key={i}>{t(m.title)}</div>)}
                        </div>

                        <div className="d-flex logo-section">
                            <ProfileSection />
                        </div>
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
