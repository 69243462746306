import color from '../assets/scss/common.module.scss'

export const colorCodes = {
  BLACK_1: color.black1,
  BLACK_2: color.black2,
  BLACK_3: color.black3,
  BLACK_4: color.black4,
  RICH_BLACK: color.richBlack,
  TEAL: color.teal,
  WHITE_1: color.white1,
  WHITE_2: color.white2,
  BLUE_1: color.blue1,
  BLUE_2: color.blue2,
  BLUE_3: color.blue3,
  BLUE_4: color.blue4,
  BLUE_5: color.blue5,
  BLUE_6: color.blue6,
  BLUE_7: color.blue7,
  BLUE_8: color.blue8,
  LIGHT_BLUE_1: color.lightBlue1,
  LIGHT_BLUE_2: color.lightBlue2,
  LIGHT_BLUE_3: color.lightBlue3,
  LIGHT_BLUE_4: color.lightBlue4,
  LIGHT_BLUE_5: color.lightBlue5,
  LIGHT_BLUE_6: color.lightBlue6,
  LIGHT_BLUE_7: color.lightBlue7,
  LIGHT_BLUE_8: color.lightBlue8,
  GREEN_1: color.green1,
  GREEN_2: color.green2,
  GREEN_3: color.green3,
  GREEN_4: color.green4,
  GREEN_5: color.green5,
  GREEN_6: color.green6,
  GREEN_7: color.green7,
  GREEN_8: color.green8,
  GREEN_9: color.green9,
  GREEN_10: color.green10,
  GREEN_11: color.green11,
  GREEN_12: color.green12,
  LIGHT_GREEN: color.lightGreen,
  LIGHT_GREEN_2: color.lightGreen2,
  LIGHT_GREEN_3: color.lightGreen3,
  LIGHT_GREEN_4: color.lightGreen4,
  LIGHT_GREEN_5: color.lightGreen5,
  LIGHT_GREEN_6: color.lightGreen6,
  LIGHT_GREEN_7: color.lightGreen7,
  LIGHT_GREEN_8: color.lightGreen8,
  LIGHT_GREEN_9: color.lightGreen9,
  LIGHT_GREEN_10: color.lightGreen10,
  DARK_GREEN_1: color.darkGreen1,
  LIGHT_CYAN: color.lightCyan,
  RED_1: color.red1,
  RED_2: color.red2,
  RED_3: color.red3,
  LIGHT_RED_1: color.lightRed1,
  LIGHT_RED_2: color.lightRed2,
  PINK_1: color.pink1,
  PINK_2: color.pink2,
  PINK_3: color.pink3,
  PINK_4: color.pink4,
  GREY_1: color.grey1,
  GREY_2: color.grey2,
  GREY_3: color.grey3,
  GREY_4: color.grey4,
  GREY_5: color.grey5,
  GREY_6: color.grey6,
  YELLOW_1: color.yellow1,
  YELLOW_2: color.yellow2,
  YELLOW_3: color.yellow3,
  YELLOW_4: color.yellow4,
  PURPLE_1: color.purple1,
  PURPLE_2: color.purple2,
  PURPLE_3: color.purple3,
  PURPLE_4: color.purple4,
  PURPLE_5: color.purple5,
  PURPLE_6: color.purple6,
  PURPLE_7: color.purple7,
  PURPLE_8: color.purple8,
  PURPLE_9: color.purple9,
  PURPLE_10: color.purple10,
  AQUA: color.aqua,
  WATER_SPOUT: color.waterSpout,
  ORANGE_1: color.orange1,
  ORANGE_2: color.orange2,
  ORANGE_3: color.orange3,
  SHADOW_1: color.shadow1
}
