import React from 'react'
import { colorCodes } from '../../../constant/colors'

const File = ({
    fill = colorCodes.BLUE_4,
    height = '23.562',
    width = '18.5',
    style = {},
    viewBox = '0 0 18.5 23.562',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g transform="translate(0)">
            <path d="M19.123.265a.785.785,0,0,1,.785.785V23.041a.785.785,0,0,1-.785.785H1.844a.785.785,0,0,1-.785-.785V6.548a.785.785,0,0,1,.23-.555l5.5-5.5a.785.785,0,0,1,.555-.23Zm-.785,1.571H8.125V6.546a.785.785,0,0,1-.782.789H2.629V22.256H18.337ZM6.555,2.948,3.738,5.764H6.555Z" transform="translate(-1.058 -0.265)" fill={fill} />
            <path d="M10.111,291.709a.649.649,0,0,1,.642.66.659.659,0,0,1-.706.6H3.307a.632.632,0,1,1,0-1.26h6.8Z" transform="translate(3.146 -282.945)" fill={fill} />
            <path d="M10.062,293.295a.632.632,0,1,1,0,1.26H3.32a.632.632,0,1,1,0-1.26Z" transform="translate(3.133 -279.757)" fill={fill} />
            <path d="M10.062,294.883a.632.632,0,1,1,0,1.26H3.32a.632.632,0,1,1,0-1.26Z" transform="translate(3.133 -276.564)" fill={fill} />
        </g>
    </svg>
)

export default File