import { useState } from "react"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import { editSecPlan } from "../../../../actions/plans"
import LabelSelectBox from "../../../../components/common/LabelSelectBox"
import LabelInput from "../../../../components/common/LabelInput"
import WaywizeButton from "../../../../components/common/WaywizeButton"

const EditMultiPlanSection = ({ isProcessing = false, multiRecs, onSuccess, ruleId, statusList }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [statusVal, setStatusVal] = useState(statusList[0].value || '')
  const [deadLine, setDeadLine] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!!multiRecs && !!multiRecs.length) {
      try {
        await Promise.all(
          multiRecs.map(async (rec) => {
            const { is_selected, identity, id } = rec
            await dispatch(editSecPlan({
              ...(!!deadLine && { deadline_date: new Date(deadLine).toISOString() }),
              ...(!!statusVal && { status: parseInt(statusVal) }),
              is_selected,
              maintenanceplanRuleId: ruleId,
              sectionNumber: identity
            }, false, id, false))
          })
        )
        onSuccess()
        toast.success('Updated Successfully')
      } catch (e) {
        toast.error('Something went wrong, Please try again.')
        console.log("Errors", e)
      }
    }
  }

  return (
    <div className="d-flex ai-center">
      <form onSubmit={onSubmit} className="strategy-form-container center-flex">
        <div className="d-flex">
          <LabelSelectBox name="status" label={t('mainten_plan.form.status')} options={statusList} value={statusVal} handleOnChange={e => setStatusVal((e.value).toString())} />
          <LabelInput name="deadline_date" type="date" label={t('form_input.deadline_date')} value={deadLine} onChange={e => setDeadLine(e.target.value)} />
        </div>
        <WaywizeButton type="submit" title={t(!!isProcessing ? 'button.loading' : 'button.update')} className="strategy-form-submit-btn" disabled={!multiRecs.length} />
      </form>
    </div>
  )
}

export default EditMultiPlanSection