import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { useTranslation } from 'react-i18next'
import LabelInput from "../../../../components/common/LabelInput";
import SpinLoader from "../../../../components/common/SpinLoader";
import WaywizeButton from "../../../../components/common/WaywizeButton";
import "./index.scss";

const required = value => value ? undefined : 'message.required';

const renderField = ({
  input, label, type,
  handleOnFocus, handleOnBlur,
  min, max, placeholder, helpText = false,
  meta: { touched, visited, active, error }
}) => (
  <LabelInput label={label} type={type}
    errClass={((!active && (visited || touched)) && error) ? 'error_border' : ''}
    errMsg={((!active && (visited || touched)) && error) ? error : ''}
    placeholder={placeholder} min={min} max={max}
    active={active}
    helpText={helpText}
    onChange={param => {
      input.onChange(param.target.value);
    }}
    onFocus={e => {
      input.onFocus(e);
      !!handleOnFocus && handleOnFocus(e);
    }}
    onBlur={e => {
      input.onChange((e.target.value).trim());

      // HINT: capitalizeOnBlur function taking some time in calculation that's why we added setTimeOut
      input.onBlur(e);
      !!handleOnBlur && handleOnBlur(e);
    }}

    {...input} />
);

const StrategyForm = ({ submitting, handleSubmit, valid, isLoading = false, isEdit = false }) => {
  const isValid = (submitting || valid);
  const { isProcessing } = useSelector(state => state.strategies);
  const { t } = useTranslation()

  return (
    <div className="d-flex ai-center">
      {!!isProcessing && <SpinLoader />}
      <form onSubmit={handleSubmit} className="strategy-form-container center-flex">
        <div className="d-flex">
          <Field name="name" component={renderField} label={t('strategy.form.name')} placeholder={t('strategy.form.name_ex')}
            validate={[required]}
          />
          <Field name="budget" label={`${t('strategy.form.budget')} (NOK)`} type="number" component={renderField} placeholder="Ex. 3000" />
        </div>

        <div className="d-flex">
          <Field name="distanceCombinedShort" label={`${t('strategy.form.combined_dis')} (m)`} type="number" component={renderField} placeholder="Ex. 0 (Optional)" helpText="help_txt.combine_dist" />
          <Field name="distanceSkipShort" label={`${t('strategy.form.skip_dis')} (m)`} type="number" component={renderField} placeholder="Ex. 0 (Optional)" helpText="help_txt.skip_dist" />
        </div>

        <div className="d-flex">
          <Field name="lengthMultiplier" label={`${t('strategy.form.sec_length')} (Points)`} min={0} max={100} type="number" component={renderField} placeholder="Ex. 1-100 (Optional)" />
          <Field name="lightingMultiplier" label={`${t('strategy.form.street_lighting')} (Points)`} min={0} max={100} type="number" component={renderField} placeholder="Ex. 1-100 (Optional)" />
        </div>

        <div className="d-flex">
          <Field name="tentMultiplier" label={`${t('strategy.form.ten-t-ntwrk')} (Points)`} min={0} max={100} type="number" component={renderField} placeholder="Ex. 1-100 (Optional)" />
          <Field name="aadtMultiplier" label={`${t('strategy.form.aadt')} (Points)`} min={0} max={100} type="number" component={renderField} placeholder="Ex. 1-100 (Optional)" />
        </div>

        <div className="d-flex">
          <Field name="speedLimitMultiplier" label={`${t('strategy.form.spd_lmt')} (Points)`} min={0} max={100} type="number" component={renderField} placeholder="Ex. 1-100 (Optional)" />
          <Field name="maintainanceClassMultiplier" label={`${t('strategy.form.maintain_cls')} (Points)`} min={0} max={100} type="number" component={renderField} placeholder="Ex. 1-100 (Optional)" />
        </div>

        {/* <div className="d-flex">
          <Field name="paymentMultiplier" label={t('strategy.form.new_pavement')} min={1} max={100} type="number" component={renderField} placeholder="Ex. 1-100 (Optional)" />
          <Field name="colourMultiplier" label={t('strategy.form.mark_colour')} min={1} max={100} type="number" component={renderField} placeholder="Ex. 1-100 (Optional)" />
        </div> */}
        <WaywizeButton type="submit" title={(isLoading || submitting) ? `${t('button.loading')}...` : (!!isEdit ? t('button.update') : t('button.save'))} className="strategy-form-submit-btn" disabled={!isValid} />
      </form>
    </div>
  )
}

export default StrategyForm