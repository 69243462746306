import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRoadImages } from '../../../actions/imagePlayer'
import CustomPopUp from "../CustomPopUp"
import Icon from "../Icons"
import SpinLoader from '../SpinLoader'
import "./index.scss"

const SKIP_DISTANCE = 100
const ImagePlayer = ({ playerInfo = {}, handleClose }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isProcessing } = useSelector(state => state.imagePlayer)

    return (
        <CustomPopUp className={`image-player-container ${!!playerInfo.images && !!playerInfo.images.length ? 'double-img' : ''}`} title={t('image_player.rd_imgs')} handleClose={handleClose}>
            {!!isProcessing && <SpinLoader />}
            <div className="image-player-section">
                <div className="image-holder-sec">
                    {
                        !!playerInfo.images && !!playerInfo.images.length ?
                            (playerInfo.images).map((img, i) =>
                                <div key={i} className="center-flex">
                                    <img src={img.imageData} alt={img.imageType} />
                                    <div className="info-section-heading">{img.imageType}</div>
                                    <div className="info-section-heading">{t('image_player.last_update')}: {img.imageDate}</div>
                                </div>) :
                            <img src={require('./dummy.png').default} alt="Map" />
                    }
                </div>
                <div className="image-action-tool center-flex">
                    <div className="btn-sec-1 center-flex">
                        <div>-{SKIP_DISTANCE}m</div>
                        <button className='center-flex' onClick={() => dispatch(fetchRoadImages({ roadname: playerInfo.roadname, start_distance: Number(playerInfo.start_distance - 100) }))}><Icon name="left-arrow" /></button>
                    </div>
                    <div className="btn-sec-2 center-flex">
                        <button className='center-flex' onClick={() => dispatch(fetchRoadImages({ roadname: playerInfo.roadname, start_distance: Number(playerInfo.start_distance + 100) }))}><Icon name="right-arrow" /></button>
                        <div>+{SKIP_DISTANCE}m</div>
                    </div>
                </div>
            </div>
            <div className="info-section">
                <div className="info-section-heading">{t('image_player.sec_details')}</div>
                <div>
                    {/* <p>{playerInfo.description}</p> */}
                    <p>{playerInfo.start_distance}</p>
                    {/* <p>{playerInfo.end_distance}</p> */}
                    <p>{playerInfo.roadname}</p>
                    {/* <p>{playerInfo.value}</p> */}
                </div>
            </div>

        </CustomPopUp>
    )
}

export default ImagePlayer