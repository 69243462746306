import { connect, useDispatch } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import PlanSectionForm from "../PlanSectionForm";
import { editSecPlan } from "../../../../actions/plans";

const FORM_NAME = 'add-plan-sec-form'

const AddPlanSection = ({
  submitting, handleSubmit, valid, ruleId,
  onSuccess, planSecList = [], statusList
}) => {
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const { identity, id } = data
    dispatch(editSecPlan({
      is_selected: true,
      maintenanceplanRuleId: ruleId,
      sectionNumber: identity
    }, onSuccess, id))
  }

  return (
    <PlanSectionForm
      submitting={submitting}
      valid={valid}
      statusList={statusList}
      planSecList={planSecList}
      handleSubmit={handleSubmit(onSubmit)} />
  )
}

const selector = formValueSelector(FORM_NAME)

export default connect((state, ownProps) => {
  const planInfo = selector(state, 'identity')
  const info = (ownProps.planSecList).find(a => a.identity === planInfo)
  return {
    initialValues: {
      ...info
    }
  }
}, null)(reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(AddPlanSection))