import { toast } from "react-toastify"
import { handleRequest } from "../constant/helpers"

export const SET_STRATEGY_PROCESSING = 'SET_STRATEGY_PROCESSING'
export const SET_STRATEGY_ID = 'SET_STRATEGY_ID'
export const SET_ALL_STRATEGIES = 'SET_ALL_STRATEGIES'
export const STRATEGY_DELETED = 'STRATEGY_DELETED'
export const STORE_MEASUREMENT_LIST_OBJECT = 'STORE_MEASUREMENT_LIST_OBJECT'

export const setStrategyIsProcessing = isProcessing => ({
  type: SET_STRATEGY_PROCESSING,
  payload: { isProcessing }
})

export const setStrategyId = strategyId => ({
  type: SET_STRATEGY_ID,
  payload: { strategyId }
})

const setAllStrategies = strategies => ({
  type: SET_ALL_STRATEGIES,
  payload: {
    strategies,
    isProcessing: false
  }
});

export const storeMeasurementList = strategyObjectList => ({
  type: STORE_MEASUREMENT_LIST_OBJECT,
  payload: {
    strategyObjectList
  }
});

const strategyDeleted = strategyId => ({
  type: STRATEGY_DELETED,
  strategyId
})


/**
 * Fetch All Strategy
 * and presumptions
 */
export const fetchAllStrategies = () => async (dispatch) => {
  dispatch(setStrategyIsProcessing(true));
  try {
    const data = await handleRequest("post", false, {
      query: `
      query Query {
        getCalculatedList {
          id
          name
          budget
          distanceCombinedShort
          distanceSkipShort
          lengthMultiplier
          lightingMultiplier
          colourMultiplier
          aadtMultiplier
          speedLimitMultiplier
          maintainanceClassMultiplier
          tentMultiplier
          paymentMultiplier
        }
      }`
    });

    if (!!data && !!data.getCalculatedList && !!data.getCalculatedList.length) {
      const resp = data.getCalculatedList
      dispatch(setAllStrategies(resp))
      return resp
    }
    dispatch(setStrategyIsProcessing(false));
    return [];
  } catch (e) {
    dispatch(setStrategyIsProcessing(false));
    console.log('fetchAllStrategies Error: ', e);
    return e;
  }
}


/**
 * Edit Strategy
 * @param {*} data 
 * @param {*} successCallback 
 * @returns 
 */
export const editStrategy = (data, successCallback) => async (dispatch) => {
  dispatch(setStrategyIsProcessing(true))
  try {
    const resp = await handleRequest("post", false, {
      query: `
      mutation EditCalculationItem($data: editParameter!) {
        editCalculationItem(data: $data) {
          totalCost
          selected {
            roadname
            road_class
            start_distance
            end_distance
            measurement_date
            rmms_linetype_id
            bad_condition
            length
            priorityCalculation
          }
          notSelected {
            roadname
            road_class
            start_distance
            end_distance
            measurement_date
            rmms_linetype_id
            bad_condition
            length
            priorityCalculation
          }
          goodCondition {
            roadname
            road_class
            start_distance
            end_distance
            measurement_date
            rmms_linetype_id
            bad_condition
            length
            priorityCalculation
          }
        }
      }`,
      variables: { data }
    })

    dispatch(setStrategyIsProcessing(false))

    if (!!resp && !!resp.editCalculationItem) {
      if (resp.editCalculationItem === 'ID not found') {
        toast.error(resp.editCalculationItem)
      } else {
        dispatch(fetchAllStrategies())
        successCallback()
        toast.success('Updated Successfully')
      }
    }
  } catch (err) {
    console.log('editStrategy Error:', err);
    dispatch(setStrategyIsProcessing(false))
  }
}


/**
 * Delete Strategy
 * @param {*} id 
 * @returns 
 */
export const deleteStrategy = id => async (dispatch) => {
  dispatch(setStrategyIsProcessing(true))
  try {
    const resp = await handleRequest("post", false, {
      query: `
      mutation Mutation($deleteCalculationItemId: ID!) {
        deleteCalculationItem(id: $deleteCalculationItemId)
      }`,
      variables: {
        deleteCalculationItemId: id
      }
    })

    dispatch(setStrategyIsProcessing(false))

    if (!!resp && !!resp.deleteCalculationItem) {
      if (resp.deleteCalculationItem === 'ID not found') {
        toast.error(resp.deleteCalculationItem)
      } else {
        dispatch(strategyDeleted(id))
        toast.error('Deleted Successfully')
      }
    }
  } catch (err) {
    console.log('deleteStrategy Error:', err);
    dispatch(setStrategyIsProcessing(false))
  }
}
