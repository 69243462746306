import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'
import LabelInput from "../../../components/common/LabelInput";
import SpinLoader from "../../../components/common/SpinLoader";
import WaywizeButton from "../../../components/common/WaywizeButton";
import { handleRequest, onlyNumber } from "../../../constant/helpers";
import LabelSelectBox from "../../../components/common/LabelSelectBox";
import CommonTable from "../../../components/common/CommonTable";
import { fetchRoadImages } from "../../../actions/imagePlayer";
import {
  changeMapOverlay,
  changeRoadDescription, changeRoadEndDistance, changeRoadName,
  changeRoadStartDistance, changeValue, changeValueCondition, fetchAllRoadAttrSearch
} from "../../../actions/roads";
import Icon from "../../../components/common/Icons";
import { setRdAttrArr, showRoadAttrOnMap } from "../../../actions/searchRdAttr";
import "./index.scss";
import ResizableWindow from "../../../components/common/ResizableWindow";

// TODO: Not in use for now
// const descriptionList = [
//   { value: "Speedlimit", name: "Speed Limit" },
//   { value: "VehicleCount", name: "Vehicle Count" },
//   { value: "RoadWidth", name: "Road Width" },
// ];

const columns = [
  { Header: "table.rd_cat", accessor: "road_class", Filter: false },
  { Header: "table.rd_num", accessor: "road", Filter: false },
  {
    Header: "table.sec", accessor: (row) => {
      return ((row.roadname).replace(row.road, "")).replace(row.road_class, "");
    }, Filter: false
  },
  { Header: "table.des", accessor: "description", Filter: false },
  { Header: "table.start_dis", accessor: "start_distance", Filter: false, isSortable: true },
  { Header: "table.end_dis", accessor: "end_distance", Filter: false, isSortable: true },
  { Header: "table.rd_name", accessor: "roadname", Filter: false, isSortable: false },
  { Header: "table.value", accessor: "value", Filter: false }
];

const RoadSearchAttrForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true)

  let dispatch = useDispatch()
  const { t } = useTranslation()

  // Getting all the state values
  const roadName = useSelector(state => state.roadReducer.road_name);
  const startDis = useSelector(state => state.roadReducer.start_distance);
  const endDis = useSelector(state => state.roadReducer.end_distance);
  const { value, valueCondition, description, searchRdAttrList } = useSelector(state => state.roadReducer);
  const { roadArr } = useSelector(state => state.searchRdAttr)
  const { playerInfo, isProcessing } = useSelector(state => state.imagePlayer)

  useEffect(() => {
    dispatch(fetchAllRoadAttrSearch());

    // eslint-disable-next-line
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(changeMapOverlay(description));
    try {
      const resp = await handleRequest("post", false, {
        query: `
        query roadAttributeSearchAdvanced($roadAttributeSearchParameterAdvanced: roadAttributeSearchParameterAdvanced) {
          roadAttributeSearchAdvanced(roadAttributeSearchParameterAdvanced: $roadAttributeSearchParameterAdvanced) {
            id
            roadname
            start_distance
            end_distance
            description
            value
            road
            section
            road_class
            }
          }`,
        variables: {
          roadAttributeSearchParameterAdvanced: {
            id: (description).toString(),
            ...(!!roadName && { "roadname": roadName }),
            ...(!!startDis && { "start_distance": startDis }),
            ...(!!endDis && { "end_distance": endDis }),
            ...(!!value && { value }),
            ...(!!valueCondition && { "valueCondition": valueCondition })
          }
        }
      });

      setIsLoading(false);
      if (!!resp && !!resp.roadAttributeSearchAdvanced) {
        dispatch(setRdAttrArr(resp.roadAttributeSearchAdvanced))
      } else {
        dispatch(setRdAttrArr([]))
      }
    } catch (err) {
      console.log('Road Submit Search Error:', err);
      setIsLoading(false);
    }
  };

  const isValid = (!!description);

  return (
    <div className="d-flex ai-center">
      <form onSubmit={handleSubmit} className="form-card-container center-flex">
        {(!!isLoading || (!playerInfo && !!isProcessing)) && <SpinLoader />}
        <LabelSelectBox name="description" label={t('form_input.select_desc')} options={searchRdAttrList.map(a => ({ label: a.description, value: a.id }))}
          value={description} handleOnChange={e => dispatch(changeRoadDescription((e.value).toString()))} />
        <LabelInput label={t('form_input.rd_name')} value={roadName} onChange={e => dispatch(changeRoadName(e.target.value))} placeholder={t('form_input.rd_name_ex')} />
        <LabelInput label={t('form_input.start_dis')} value={startDis} onChange={e => dispatch(changeRoadStartDistance(onlyNumber(e.target.value)))} placeholder="Ex. 0 (Optional)" />
        <LabelInput label={t('form_input.end_dis')} value={endDis} onChange={e => dispatch(changeRoadEndDistance(onlyNumber(e.target.value)))} placeholder="Ex. 200 (Optional)" />

        <LabelSelectBox name="variable" label={t('form_input.value_cond')} options={[
          { value: "GREATER", label: t('form_input.greater') },
          { value: "LESSER", label: t('form_input.lesser') }
        ]}
          value={valueCondition} handleOnChange={e => dispatch(changeValueCondition(e.value))} />
        <LabelInput label={t('form_input.value')} value={value} onChange={e => dispatch(changeValue(Math.round(onlyNumber(e.target.value))))} placeholder="Ex. 10 (Optional)" />
        <WaywizeButton type="submit" className="mt-17" title={isLoading ? `${t("button.loading")}...` : t("button.search")} disabled={!isValid} />
      </form>
      {
        !!roadArr.length && <ResizableWindow className='card-shadow-elem road-search-attr-table-content' isOpen={isOpen} left={480}>
          <div className={`header-action-tool center-flex ${isOpen ? '' : 'info-hide'}`} onClick={() => setIsOpen(!isOpen)}>
            <Icon name="double-arrow" className="toggle-btn" />
          </div>
          <CommonTable
            className="rd-search-tbl"
            columns={columns}
            trTitle="Click to view images"
            showActiveRow={true}
            data={roadArr} downloadBtn={true}
            onRowClick={(row) => {
              dispatch(fetchRoadImages(row.original))
              dispatch(showRoadAttrOnMap(description, row.original.roadname, row.original.start_distance, row.original.end_distance))
            }}
            selectedRows={[]} />
        </ResizableWindow>
      }
    </div>
  );
};

export default RoadSearchAttrForm;