import { toast } from "react-toastify"
import { handleRequest } from "../constant/helpers"

export const USER_IS_PROCESSING = "USER_IS_PROCESSING"
export const SET_ALL_USERS = "SET_ALL_USERS"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const USER_DELETED_SUCCESS = "USER_DELETED_SUCCESS"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"

const setUserIsProcessing = isProcessing => ({
    type: USER_IS_PROCESSING,
    payload: { isProcessing }
})

const setAllUsers = users => ({
    type: SET_ALL_USERS,
    payload: {
        isProcessing: false,
        users
    }
})

const addUserSuccess = user => ({
    type: ADD_USER_SUCCESS,
    payload: { user }
})

const userDeletedSuccess = id => ({
    type: USER_DELETED_SUCCESS,
    id
})


const editUserSuccess = (id, user) => ({
    type: EDIT_USER_SUCCESS,
    id, user
})

/**
 * Fetch All users
 */
export const fetchAllUsers = () => async (dispatch) => {
    dispatch(setUserIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `query GetAllUser {
                getAllUser {
                  id
                  user_email
                  role_id
                  job_title
                }
              }`
        })
        if (!!resp && !!resp.getAllUser && !!resp.getAllUser.length) {
            dispatch(setAllUsers(resp.getAllUser))
        } else {
            dispatch(setAllUsers([]))
        }
        return resp
    } catch (e) {
        dispatch(setUserIsProcessing(false))
        console.log('fetchAllUsers Error: ', e)
        return e
    }
}


/**
 * Add User
 * @param {*} info 
 * @param {*} successCallback 
 * @returns 
 */
export const addUser = (info, successCallback, id) => async (dispatch) => {
    dispatch(setUserIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `mutation Mutation($data: addUserInput!) {
                addUser(data: $data) {
                  job_title
                  role_id
                  user_email
                  id
                }
              }`,
            variables: {
                data: {
                    ...info,
                    role_id: parseInt(info.role_id)
                }
            }
        })

        dispatch(setUserIsProcessing(false))

        if (!!resp && !!resp.addUser) {
            successCallback()
            dispatch(addUserSuccess(resp.addUser))
            toast.success('User Added Successfully')
        } else if (!!resp && !!resp.data && !!resp.data.errors && !!resp.data.errors.length && !!resp.data.errors[0].message) {
            toast.error(resp.data.errors[0].message)
        }
    } catch (err) {
        console.log('addUser Error:', err);
        dispatch(setUserIsProcessing(false))
    }
}



/**
 * Edit User
 * @param {*} data 
 * @param {*} successCallback 
 * @returns 
 */
export const editUser = (data, successCallback) => async (dispatch) => {
    dispatch(setUserIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `mutation Mutation($data: editUserInput!) {
                editUser(data: $data) {
                  id
                  user_email
                  role_id
                  job_title
                }
              }`,
            variables: {
                data: {
                    ...data,
                    role_id: parseInt(data.role_id)
                }
            }
        })

        dispatch(setUserIsProcessing(false))

        if (!!resp && !!resp.editUser) {
            successCallback()
            dispatch(editUserSuccess(data.id, resp.editUser))
            toast.success('Updated Successfully')
        }
    } catch (err) {
        console.log('editUser Error:', err);
        dispatch(setUserIsProcessing(false))
    }
}


/**
 * Delete User
 * @param {*} id 
 * @returns 
 */
export const deleteUser = id => async (dispatch) => {
    dispatch(setUserIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `mutation DeleteUser($deleteUserId: ID!) {
                deleteUser(id: $deleteUserId)
              }`,
            variables: {
                deleteUserId: (id).toString()
            }
        })

        dispatch(setUserIsProcessing(false))

        if (!!resp && !!resp.deleteUser && (resp.deleteUser === `ID ${id}, deleted`)) {
            dispatch(userDeletedSuccess(id))
            toast.success('Deleted Successfully')
        }
    } catch (err) {
        console.log('deleteUser Error:', err);
        dispatch(setUserIsProcessing(false))
    }
}