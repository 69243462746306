import { EDIT_UC_SUCCESS, SET_ALL_UC, SET_ALL_UC_THICKNESS, UC_IS_PROCESSING } from "../actions/unitCost"

const initialState = {
    unitCosts: [],
    thicknessList: [],
    isProcessing: false
}

const unitCosts = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_UC:
        case SET_ALL_UC_THICKNESS:
        case UC_IS_PROCESSING:
            return Object.assign({}, state, action.payload)
        case EDIT_UC_SUCCESS: {
            let allUnitCosts = (state.unitCosts).map(a => {
                if (a.id === action.unitCost.id) {
                    return { ...a, ...action.unitCost }
                } else {
                    return a
                }
            })
            return {
                ...state,
                isProcessing: false,
                unitCosts: allUnitCosts
            }
        }
        default:
            return state
    }
}

export default unitCosts