import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import "./index.scss"

const Welcome = ({ loginCallBack, isLoggedIn }) => {
  const navigate = useNavigate()

  console.log("Log!")
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/rmms")
    }
    // eslint-disable-next-line
  }, [isLoggedIn])

  const dateObj = new Date()
  return (
    <div id="app">
      <div className="d-flex f-column welcome-container">
        <div className="p-2">
          <img src={require("../../assets/images/logo-white-green.png").default} className="rmms-logo" alt="RMMS" />
          <img src={require('../../assets/images/brand-logo-white.svg').default} alt="Statens vegvesen" className="ml-10" />
        </div>
        <div className="p-2">
          <h1 className="welcome-h1">Welcome to <span>RMMS</span><span className="mode-value">BETA</span></h1>
          {false && <div className="welcome-msg">neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adpisci vetit.</div>}
        </div>
        <div className="p-2">
          {
            !!isLoggedIn ? <Link className="log-in-button" to="/rmms">Enter RMMS</Link>
              : <button className="log-in-button" onClick={loginCallBack}>Log In</button>
          }
        </div>
        <div className="bottom-part">
          <div>
            <span>
              Copyright © {dateObj.getFullYear()} · Waywize · All Rights Reserved
            </span>
          </div>
          <div className="mt-10">
            <a href="https://www.waywize.com/fuelsave-terms-and-conditions/" className="term-service" target="#">Terms of Service</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome