import { handleRequest } from "../constant/helpers";
import { getAllRoadAttrSearch } from "../utils/road";
import { setMapLatLong } from "./global";

export const CHANGE_ROAD_NAME = "CHANGE_ROAD_NAME";
export const CHANGE_ROAD_SECTION = "CHANGE_ROAD_SECTION";
export const CHANGE_ROAD_NAME_SECTION = "CHANGE_ROAD_NAME_SECTION";
export const CHANGE_DESCRIPTION = "CHANGE_DESCRIPTION";
export const CHANGE_START_DISTANCE = "CHANGE_START_DISTANCE";
export const CHANGE_END_DISTANCE = "CHANGE_END_DISTANCE";
export const CHANGE_VALUE = "CHANGE_VALUE";
export const CHANGE_VALUE_CONDITION = "CHANGE_VALUE_CONDITION";
export const CHANGE_VARIABLE = "CHANGE_VARIABLE";
export const CHANGE_LINE_TYPE = 'CHANGE_LINE_TYPE';
export const CHANGE_MEASUREMENT_DATE = "CHANGE_MEASUREMENT_DATE";
export const CHANGE_MAP_OVERLAY = "CHANGE_MAP_OVERLAY";
export const SEARCH_RAOD_ATTR_PROCESSING = "SEARCH_RAOD_ATTR_PROCESSING";
export const SEARCH_RAOD_ATTR_LIST = 'SEARCH_RAOD_ATTR_LIST';

export const changeRoadName = road_name => ({
    type: CHANGE_ROAD_NAME,
    payload: { road_name }
});

export const changeRoadSection = road_section => ({
    type: CHANGE_ROAD_SECTION,
    payload: { road_section }
});

export const changeRoadNameSection = road_name_section => ({
    type: CHANGE_ROAD_NAME_SECTION,
    payload: { road_name_section }
});

export const changeRoadDescription = description => ({
    type: CHANGE_DESCRIPTION,
    payload: { description }
});

export const changeRoadStartDistance = start_distance => ({
    type: CHANGE_START_DISTANCE,
    payload: { start_distance }
});

export const changeRoadEndDistance = end_distance => ({
    type: CHANGE_END_DISTANCE,
    payload: { end_distance }
});

export const changeValue = value => ({
    type: CHANGE_VALUE,
    payload: { value }
});

export const changeValueCondition = valueCondition => ({
    type: CHANGE_VALUE_CONDITION,
    payload: { valueCondition }
});

export const changeVariable = variable => ({
    type: CHANGE_VARIABLE,
    payload: { variable }
});

export const changeLineType = lineType => ({
    type: CHANGE_LINE_TYPE,
    payload: { lineType }
});

export const changeMeasurementDate = measurement_date => ({
    type: CHANGE_MEASUREMENT_DATE,
    payload: { measurement_date }
});

export const changeMapOverlay = map_overlay => ({
    type: CHANGE_MAP_OVERLAY,
    payload: { map_overlay }
});

const searchRoadAttrProcessing = searchRdProcessing => ({
    type: SEARCH_RAOD_ATTR_PROCESSING,
    payload: { searchRdProcessing }
});

const searchRoadAttrList = searchRdAttrList => ({
    type: SEARCH_RAOD_ATTR_LIST,
    payload: {
        searchRdAttrList,
        searchRdProcessing: false
    }
});


/**
 * Fetch All Variables
 * for Search Road Attr
 */
export const fetchAllRoadAttrSearch = () => async (dispatch) => {
    dispatch(searchRoadAttrProcessing(true));
    try {
        const resp = await getAllRoadAttrSearch();
        if (!!resp && !!resp.getRoadAttributelist && !!resp.getRoadAttributelist.length) {
            dispatch(searchRoadAttrList(resp.getRoadAttributelist));

            // Set first record as default
            if (resp.getRoadAttributelist[0]['id']) {
                dispatch(changeRoadDescription((resp.getRoadAttributelist[0]['id']).toString()));
            }
            return resp.getRoadAttributelist;
        } else {
            dispatch(searchRoadAttrList([]));
            return [];
        }
    } catch (e) {
        dispatch(searchRoadAttrProcessing(false));
        console.log('fetchAllRoadAttrSearch Error: ', e);
        return e;
    }
};


/**
 * Show Road On Map
 * @param {*} roadname 
 * @returns 
 */
 export const showRoadOnMap = roadname => async (dispatch) => {
    dispatch(searchRoadAttrProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `query getCoordinatesForRoadSearch($roadname: String!) {
                getCoordinatesForRoadSearch(roadname: $roadname) {
                  x_coordinate
                  y_coordinate
                }
              }`,
            variables: {
                roadname
            }
        })

        dispatch(searchRoadAttrProcessing(false))

        if (!!resp && !!resp.getCoordinatesForRoadSearch) {
            const { x_coordinate, y_coordinate } = resp.getCoordinatesForRoadSearch
            dispatch(setMapLatLong([x_coordinate, y_coordinate], 13))
        }
    } catch (err) {
        console.log('showRoadOnMap Error:', err);
        dispatch(searchRoadAttrProcessing(false))
    }
}