import { handleRequest } from "../constant/helpers"

/**
 * Get all road attr
 * for Search Road Attr
 */
export const getAllRoadAttrSearch = () => handleRequest('post', false, {
  query: `query {
    getRoadAttributelist {
      id
      description
    }
  }`
}, 'application/json')