import { connect, useDispatch } from "react-redux"
import { reduxForm } from "redux-form"
import { addUser } from "../../../actions/users"
import UserForm from "../UserForm"

const AddUser = ({ submitting, handleSubmit, valid, onSuccess }) => {
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    dispatch(addUser(data, onSuccess))
  }

  return (
    <UserForm
      submitting={submitting}
      valid={valid}
      handleSubmit={handleSubmit(onSubmit)} />
  )
}

export default connect(null, null)(reduxForm({
  form: 'add-user-form',
  enableReinitialize: true
})(AddUser))