import { ADD_USER_SUCCESS, EDIT_USER_SUCCESS, SET_ALL_USERS, USER_DELETED_SUCCESS, USER_IS_PROCESSING } from "../actions/users"

const initialState = {
    users: [],
    isProcessing: false
}

const users = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_USERS:
        case USER_IS_PROCESSING:
            return Object.assign({}, state, action.payload)
        case ADD_USER_SUCCESS: {
            return {
                ...state,
                isProcessing: false,
                users: [
                    ...state.users,
                    action.payload.user
                ]
            }
        }
        case USER_DELETED_SUCCESS: {
            let allUsers = (state.users).filter(a => a.id !== action.id)
            return {
                ...state,
                isProcessing: false,
                users: allUsers
            }
        }
        case EDIT_USER_SUCCESS: {
            let allUsers = (state.users).map(a => {
                if (parseInt(a.id) === parseInt(action.id)) {
                    return action.user
                } else {
                    return a
                }
            })

            return {
                ...state,
                isProcessing: false,
                users: allUsers
            }
        }
        default:
            return state
    }
}

export default users