import React, { useRef, useEffect, useContext } from "react";
import "./Map.css";
import RMMSContext from "../../context/RMMSContext";
import * as ol from "ol";


export const Map = ({ children, zoom, center }) => {
  const mapRef = useRef();
  const { map, setMap } = useContext(RMMSContext)
  // on component mount
  useEffect(() => {
    let options = {
      view: new ol.View({ zoom, center }),
      layers: [],
      controls: [],
      overlays: [],
    };

    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);
    return () => mapObject.setTarget(undefined);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!map) return;
    map.on('click', (event) => {
      //to reset the graph
      map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        // const polygon = feature.getGeometry()
        /*sensordataNew.listCorodinates.map((coordinate,idx)=>{
          if(polygon.intersectsCoordinate(transform(coordinate,'EPSG:3067','EPSG:3857'))){
              //Todo
            if(data!==null){
                // Todo
            }
          }
        })*/
      })
    })
  }, [map])

  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
    // eslint-disable-next-line
  }, [zoom]);

  // center change handler

  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
    // eslint-disable-next-line
  }, [JSON.stringify(center)]);



  return (
    <div ref={mapRef} className="ol-map">
      {children}
    </div>
  );
};