import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Stack = ({
    fill = colorCodes.BLUE_4,
    height = '22',
    width = '22.716',
    style = {},
    viewBox = '0 0 22.716 22',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g id="layers" transform="translate(1.358 1)">
            <path id="Path_79" data-name="Path 79" d="M13,3,3,8.5,13,14,23,8.5Z" transform="translate(-3 -3)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path id="Path_80" data-name="Path 80" d="M3,25.5,13,31l10-5.5" transform="translate(-3 -11.001)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path id="Path_81" data-name="Path 81" d="M3,18l10,5.5L23,18" transform="translate(-3 -8.334)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
    </svg>

)

export default Stack