import React, { useEffect, useState } from "react"
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table"
import BTable from "react-bootstrap/Table"
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import moment from "moment"
import { confirmAlert } from 'react-confirm-alert'
import TablePagination from "../TablePagination"
import WaywizeButton from "../WaywizeButton"
import Icon from '../Icons'
import SpinLoader from "../SpinLoader"
import ConfirmAlertPopup from "../ConfirmAlertPopup"
import { parseLineType } from "../../../constant/helpers"
import CommonInputFilter from "./CommonInputFilter"
import { DATE_FORMAT_1 } from "../../../constant"
import { colorCodes } from "../../../constant/colors"
import WaywizeCheckbox from "../WaywizeCheckbox"
import "./index.scss"

const CommonTable = ({
    customFnTxt = false, anotherBtn = false, trTitle = "", showActiveRow = false,
    className = '', fullDownload = true, deleteMsg = '', customFn = false,
    columns, data, onRowClick, selectedRows = [], downloadBtn = false,
    actionBtn = false, editFn = false, deleteFn = false, selectFn = false,
    onCheckboxChange = false, showMultiCheckbox = false, proceedBtnDisable = false,
    handleMultiCancel = false, handleProceedBtn = false, multiIDs = [],
    resetMulti = false
}) => {
    const [activeRow, setActiveRow] = useState(false)
    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
            multiSelect: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return (filterValue !== undefined) && !!filterValue.length
                        ? filterValue.includes(rowValue)
                        : true
                })
            },
            date: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? (String(moment(rowValue).format(DATE_FORMAT_1))).includes(filterValue)
                        : true
                })
            },
            bool: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return (rowValue !== undefined)
                        // eslint-disable-next-line
                        ? (((String(filterValue).toLowerCase() === 'yes') && (rowValue === true)) ||
                            // eslint-disable-next-line
                            ((String(filterValue).toLowerCase()) === 'no') && (rowValue === false))
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(() => ({ Filter: CommonInputFilter }), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns, data,
            defaultColumn,
            filterTypes
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    );

    const allRecords = useTable({ columns, data })
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        selectedRows.forEach(index => rows[index].toggleRowSelected(true));
    }, [selectedRows, rows]);

    const outputSheetData = () => {
        const headers = columns.map(c => t(c.Header))
        const sheetData = [headers]
        const recordArr = fullDownload ? allRecords.rows : page
        recordArr.map(row => {

            if (fullDownload) {
                prepareRow(row)
            }
            const cellInfo = []
            row.cells.map(cell => {
                cellInfo.push((cell.column.Header === "table.line_type") ? parseLineType(cell.value) : cell.value)
                return cell
            })
            sheetData.push(cellInfo)
            return row
        })
        return sheetData
    }

    const downloadFile = (format, blob) => {
        const csvBtn = document.createElement('a');
        csvBtn.target = '_blank';
        csvBtn.href = URL.createObjectURL(blob);
        csvBtn.download = `Search Road Attribute${format}`
        csvBtn.innerText = 'Download File';
        csvBtn.click()
    }

    const downloadCsv = () => {
        setIsLoading(true)
        const sheetData = outputSheetData()

        // Generate the csv string
        let csvString = "";
        sheetData.forEach((row) => {
            csvString += row.join(",") + "\n";
        })

        downloadFile('.csv', new Blob([csvString], { type: "application/vnd.ms-excel" }))
        setIsLoading(false)
    }

    const binaryToOctet = (s) => {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }

    const downloadXlsx = () => {
        setIsLoading(true)
        const sheetData = outputSheetData()

        const wb = XLSX.utils.book_new();

        wb.Props = {
            Title: "Search Road Attribute",
            CreatedDate: new Date()
        };

        wb.SheetNames.push("Results");
        const ws = XLSX.utils.aoa_to_sheet(sheetData);
        wb.Sheets["Results"] = ws;
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
        const blob = new Blob([binaryToOctet(wbout)], { type: "application/octet-stream" })
        downloadFile('.xlsx', blob)
        setIsLoading(false)
    }

    const handleValueType = cell => {
        switch (cell.column.type) {
            case 'date':
                return !!cell.value ? moment(cell.value).format(DATE_FORMAT_1) : 'N/A'
            case 'bool':
                return !!cell.value ? 'Yes' : 'No'
            case 'undefined':
            case undefined:
            default:
                return cell.render("Cell")
        }
    }

    const [updateMulti, setUpdateMulti] = useState(false)

    const handleCancelBtn = () => {
        !!handleMultiCancel && handleMultiCancel()
        setUpdateMulti(false)
    }

    useEffect(() => {
        if (!!resetMulti) {
            setUpdateMulti(false)
        }
    }, [resetMulti])

    return (
        <div className={`d-flex f-column ${className}`}>
            {!!isLoading && <SpinLoader />}
            {
                !!downloadBtn && <div className="download-btn-holder d-flex">
                    <WaywizeButton className="ml-5 mr-5" title={t('table.download_csv')} onClick={downloadCsv}>
                        <div className="center-flex">
                            <Icon name="download" />
                            <div className="ml-10">CSV</div>
                        </div>
                    </WaywizeButton>
                    <WaywizeButton className="ml-5 mr-5" title={t('table.download_xlsx')} onClick={downloadXlsx}>
                        <div className="center-flex">
                            <Icon name="download" />
                            <div className="ml-10">XLSX</div>
                        </div>
                    </WaywizeButton>
                    {!!anotherBtn && anotherBtn}
                </div>
            }
            {!!showMultiCheckbox && <div className="d-flex">
                {
                    !!updateMulti ?
                        <>
                            <WaywizeButton onClick={() => !!handleProceedBtn && handleProceedBtn()} title={t('button.proceed_multi')} className="ml-5 mr-5 update-multiple" disabled={!!proceedBtnDisable}>
                                <div className="center-flex">
                                    <div>{t('button.proceed_multi')}</div>
                                </div>
                            </WaywizeButton>
                            <WaywizeButton onClick={() => handleCancelBtn()} title={t('button.cancel')} className="ml-5 mr-5 update-multiple">
                                <div className="center-flex">
                                    <div>{t('button.cancel')}</div>
                                </div>
                            </WaywizeButton></> :
                        <WaywizeButton onClick={() => setUpdateMulti(!updateMulti)} title={t('button.edit_multi')} className="ml-5 mr-5 update-multiple">
                            <div className="center-flex">
                                <Icon name="edit" fill={colorCodes.BLUE_3} />
                                <div className="update-multiple-txt">{t('button.edit_multi')}</div>
                            </div>
                        </WaywizeButton>
                }
            </div>}
            <BTable striped bordered hover size="sm" {...getTableProps()}>
                <thead>
                    {// Loop over the header rows
                        headerGroups.map(headerGroup => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {!!updateMulti && <th>{t('table.select')}</th>}
                                {// Loop over the headers in each row
                                    headerGroup.headers.map(column => (
                                        // Apply the header cell props
                                        <th {...column.getHeaderProps(!!column.isSortable && column.getSortByToggleProps())}>
                                            <div className="center-flex">
                                                {// Render the header
                                                    !!column.Filter ? column.render("Filter") : t(column.render("Header"))}
                                                {!!column.isSortable && <img src={require('../../../assets/images/directional-arrows.png').default} alt="Sort" className="ml-10" />}
                                            </div>
                                        </th>
                                    ))}
                                {!!actionBtn && !updateMulti && <th>{t('table.actions')}</th>}
                            </tr>
                        ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                    {// Loop over the table rows
                        page.map((row, index) => {
                            // Prepare the row for display
                            prepareRow(row)

                            const rowClassName = row.isSelected ? "selected" : "";
                            return (
                                // Apply the row props
                                <tr {...row.getRowProps()} onClick={() => {
                                    setActiveRow(index)
                                    !!onRowClick && onRowClick(row)
                                }} className={`${rowClassName} ${(!!showActiveRow && (Number(activeRow) === Number(index))) ? 'active-rw' : ''}`} title={trTitle}>
                                    {!!updateMulti && <td>
                                        <div className="center-flex" onClick={e => { e.stopPropagation() }}>
                                            {!!onCheckboxChange && <WaywizeCheckbox checked={multiIDs.includes(row.original.id)} onChange={e => { onCheckboxChange(e, row) }}></WaywizeCheckbox>}
                                        </div>
                                    </td>}

                                    {// Loop over the rows cells
                                        row.cells.map(cell => {
                                            // Apply the cell props
                                            return (
                                                <td {...cell.getCellProps()} className={cell.column.className || ''}>
                                                    { // Render the cell contents
                                                        (cell.column.Header === "table.line_type") ?
                                                            parseLineType(cell.value) : handleValueType(cell)
                                                    }
                                                </td>
                                            )
                                        })}
                                    {!!actionBtn && !updateMulti && <td>
                                        <div className="center-flex">
                                            {!!customFn && <WaywizeButton title={t(customFnTxt || 'table.select')} className="action-btn-view" onClick={() => !!customFn && customFn(row)}><Icon name="round-select" /></WaywizeButton>}
                                            {!!selectFn && <WaywizeButton title={t('table.view')} className="action-btn-view" onClick={() => !!selectFn && selectFn(row)}><Icon name="eye" /></WaywizeButton>}
                                            {!!editFn && <WaywizeButton title={t('table.edit')} className="action-btn-edit" onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                !!editFn && editFn(row)
                                            }}><Icon name="edit" /></WaywizeButton>}
                                            {!!deleteFn && <WaywizeButton title={t('table.delete')} className="action-btn-delete" onClick={() => {
                                                !!deleteFn && confirmAlert({
                                                    customUI: values => <ConfirmAlertPopup {...values} onConfirm={() => deleteFn(row)} message={deleteMsg} />
                                                })
                                            }}><Icon name="trash" /></WaywizeButton>}
                                        </div>
                                    </td>}
                                </tr>
                            )
                        })}
                </tbody>
            </BTable>
            {
                pageOptions.length > 1 && <TablePagination pageCount={pageCount} pageOptions={pageOptions} pageIndex={pageIndex}
                    canNextPage={canNextPage} canPreviousPage={canPreviousPage} pageSize={pageSize} setPageSize={setPageSize}
                    gotoPage={gotoPage} previousPage={previousPage} nextPage={nextPage} recordsOnPage={page.length} />
            }
        </div>
    )
}

export default CommonTable