import LabelInput from "../../common/LabelInput"

const ReduxLabelInput = ({
    input, label, type,
    handleOnFocus, handleOnBlur,
    min, max, placeholder, disabled = false, readOnly = false,
    meta: { touched, visited, active, error }
}) => (
    <LabelInput label={label} type={type}
        errClass={((!active && (visited || touched)) && error) ? 'error_border' : ''}
        errMsg={((!active && (visited || touched)) && error) ? error : ''}
        placeholder={placeholder} min={min} max={max}
        active={active}
        onChange={param => {
            input.onChange(param.target.value);
        }}
        onFocus={e => {
            input.onFocus(e);
            !!handleOnFocus && handleOnFocus(e);
        }}
        onBlur={e => {
            input.onChange((e.target.value).trim());

            // HINT: capitalizeOnBlur function taking some time in calculation that's why we added setTimeOut
            input.onBlur(e);
            !!handleOnBlur && handleOnBlur(e);
        }}
        disabled={disabled}
        readOnly={readOnly}
        {...input} />
)

export default ReduxLabelInput