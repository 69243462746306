import { Modal } from "react-bootstrap"

const PopUp = ({ title = false, show, onHide, children }) => (
    <Modal
        show={show}
        size="lg"
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            {!!title && <Modal.Title id="contained-modal-title-vcenter">
                {title}
            </Modal.Title>}
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
    </Modal>
)

export default PopUp