import { handleRequest } from "../constant/helpers"
import { setMapLatLong } from "./global"

export const SET_ROAD_ATTR_ARR = "SET_ROAD_ATTR_ARR"

export const setRdAttrArr = roadArr => ({
    type: SET_ROAD_ATTR_ARR,
    payload: { roadArr }
})


/**
 * Show Road Attr On Map
 * @param {*} id 
 * @param {*} roadName 
 * @param {*} startDis 
 * @param {*} endDis 
 * @returns 
 */
 export const showRoadAttrOnMap = (id, roadName = false, startDis = false, endDis = false) => async (dispatch) => {
    try {
        const resp = await handleRequest("post", false, {
            query: `query Query($data: coordinateAttributeInput) {
                getCoordinatesForRoadAttribute(data: $data) {
                  x_coordinate
                  y_coordinate
                }
              }`,
            variables: {
                data: {
                    id: (id).toString(),
                    ...(!!roadName && { "roadname": roadName }),
                    ...(!!startDis && { "start_distance": startDis }),
                    "end_distance": endDis || 0,
                }
            }
        })

        if (!!resp && !!resp.getCoordinatesForRoadAttribute) {
            const { x_coordinate, y_coordinate } = resp.getCoordinatesForRoadAttribute
            dispatch(setMapLatLong([x_coordinate, y_coordinate], 15))
        }
    } catch (err) {
        console.log('showRoadAttrOnMap Error:', err);
    }
}