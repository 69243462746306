import { useTranslation } from "react-i18next"
import LabelInput from "../../LabelInput"
import "./index.scss"

const CommonInputFilter = ({ column }) => {
    const { t } = useTranslation()
    return (
        <LabelInput
            className="common-search-input-filter"
            label={t(column.render("Header"))}
            value={column.filterValue || ''}
            onClick={e => {
                e.stopPropagation()
            }}
            onChange={e => {
                column.setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={t(column.placeholder || '')}
        />
    )
}

export default CommonInputFilter