import WaywizeCheckbox from "../../common/WaywizeCheckbox"

const ReduxCheckBox = ({
    input, label,
    meta: { touched, visited, active, error }
}) => (
    <WaywizeCheckbox label={label}
        errClass={((!active && (visited || touched)) && error) ? 'error_border' : ''}
        errMsg={((!active && (visited || touched)) && error) ? error : ''}
        onChange={param => {
            input.onChange(param.target.value);
        }}
        checked={input.value}
        {...input} />
)

export default ReduxCheckBox