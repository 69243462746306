import React from 'react'
import "./index.scss"

const WaywizeCheckbox = ({
  name,
  label = '',
  checked,
  value,
  disabled = false,
  onChange,
  children = false
}) => (
  <div className='waywize-checkbox-input-container'>
    <label htmlFor={name} className='waywize-checkbox-input-label center-flex'>
      {!!children ? children : label}
      <input type="checkbox"
        name={name}
        id={name}
        disabled={disabled}
        onChange={e => !!onChange && onChange(e)}
        checked={checked}
        value={value}
        className='waywize-checkbox-input'
      />
      <span className="checkmark waywize-checkbox-checkmark"></span>
    </label>
  </div>
)

export default WaywizeCheckbox