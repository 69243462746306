import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Select = ({
    fill = colorCodes.BLUE_3,
    height = 24,
    width = 24,
    style = {},
    viewBox = '0 0 24 24',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} fill={fill} className={className} onClick={e => !!onClick && onClick(e)}>
        <path d="M6.49 20.13l1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13z"></path>
    </svg>
)

export default Select