import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { useTranslation } from 'react-i18next'
import SpinLoader from "../../../components/common/SpinLoader";
import WaywizeButton from "../../../components/common/WaywizeButton";
import ReduxLabelInput from "../../../components/redux-form/ReduxLabelInput";
import { required } from "../../../components/redux-form/validations";
import "./index.scss";

const UCForm = ({ submitting, handleSubmit, valid, isLoading = false, isEdit = false }) => {
  const isValid = (submitting || valid);
  const { isProcessing } = useSelector(state => state.unitCost);
  const { t } = useTranslation()

  return (
    <div className="d-flex ai-center">
      {!!isProcessing && <SpinLoader />}
      <form onSubmit={handleSubmit} className="uc-form-container center-flex">
        <div className="d-flex pt-20">
          <Field name="unit_cost" label={t('unit_cost.form.cost')} type="number" component={ReduxLabelInput} placeholder="Ex. 3000" validate={[required]} />
        </div>

        <WaywizeButton type="submit" title={(isLoading || submitting) ? `${t('button.loading')}...` : (!!isEdit ? t('button.update') : t('button.save'))} className="uc-form-submit-btn" disabled={!isValid} />
      </form>
    </div>
  )
}

export default UCForm