import { connect, useDispatch } from "react-redux"
import { formValueSelector } from "redux-form"
import { reduxForm } from "redux-form"
import { editSecPlan } from "../../../../actions/plans"
import PlanSectionForm from "../PlanSectionForm"

const FORM_NAME = 'edit-plan-sec-form'
const EditPlanSection = ({ submitting, handleSubmit, valid, onSuccess, ruleId, statusList, info }) => {
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    const { status, thickness, description, is_selected, identity, id, deadline_date } = data
    dispatch(editSecPlan({
      // intersection, direction, 
      description: {
        new_description: thickness || description,
        old_description: info.description
      },
      deadline_date: new Date(deadline_date).toISOString(),
      status: parseInt(status), is_selected,
      maintenanceplanRuleId: ruleId,
      sectionNumber: identity
    }, onSuccess, id))
  }

  return (
    <PlanSectionForm
      submitting={submitting}
      valid={valid}
      isEdit={true}
      statusList={statusList}
      handleSubmit={handleSubmit(onSubmit)} />
  )
}

const selector = formValueSelector(FORM_NAME)
export default connect((state, ownProps) => {
  const thickness = selector(state, 'thicknessList')
  if(typeof thickness !== 'undefined') {
    let descriptionVal = ownProps.info && ownProps.info.description ? (ownProps.info.description).split(",") : []
    if(!!descriptionVal && !!descriptionVal[1]) {
      descriptionVal[1] = ` ${thickness} mm`
    }
    ownProps.info.thickness = descriptionVal.toString()
  }

  return {
    initialValues: {
      ...ownProps.info
    }
  }
}, null)(reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(EditPlanSection))