import "./index.scss"

const LabelTextArea = ({
    id = '', label = 'Label', className = '', rows = 10,
    value, onClick, onChange, required = false, style = {},
    placeholder = '', readOnly = false, disabled = false
}) => (
    <label htmlFor={id} className={`waywize-label-textarea ${className}`}
        onClick={(e) => {
            !!onClick && onClick(e)
        }}>{label}
        <textarea id={id} value={value} rows={rows} style={style} readOnly={readOnly} disabled={disabled}
            onChange={onChange} required={required} placeholder={placeholder} /></label>
)

export default LabelTextArea