import { handleRequest, onlyNumber } from "../constant/helpers"

export const SET_SETTING_IS_PROCESS = 'SET_SETTING_IS_PROCESS'
export const SET_SETTING_PAGE = 'SET_SETTING_PAGE'
export const SET_LIMIT_SETTINGS = 'SET_LIMIT_SETTINGS'

export const setSettingIsProcess = isProcess => ({
  type: SET_SETTING_IS_PROCESS,
  payload: { isProcess }
})

export const setSettingPage = settingPage => ({
  type: SET_SETTING_PAGE,
  payload: { settingPage }
})

export const setLimitSettings = limitSettings => ({
  type: SET_LIMIT_SETTINGS,
  payload: { limitSettings }
})


/**
 * Fetch limit settings
 * @returns 
 */
export const fetchLimitSettings = () => async (dispatch) => {
  dispatch(setSettingIsProcess(true));
  try {
    const data = await handleRequest("post", false, {
      query: `
      query GetLimits {
        getLimits {
          variable_id
          value
          marking_color
        }
      }`
    })

    if (!!data && !!data.getLimits && !!data.getLimits.length) {
      dispatch(setLimitSettings(data.getLimits))
      dispatch(fetchVariableName(data.getLimits))
    }

    dispatch(setSettingIsProcess(false))
    return data
  } catch (e) {
    dispatch(setSettingIsProcess(false));
    console.log('fetchLimitSettings Error: ', e);
    return e
  }
}


/**
 * Fetch variable name
 * @returns 
 */
export const fetchVariableName = (limits) => async (dispatch) => {
  dispatch(setSettingIsProcess(true))
  try {
    const data = await handleRequest("post", false, {
      query: `
      query GetVariables {
        getVariables {
          description
          name
          id
        }
      }`
    })

    if (!!data && !!data.getVariables && !!data.getVariables.length) {
      const newLimits = limits.map(s => {
        const rec = (data.getVariables).find(r => parseInt(r.id) === parseInt(s.variable_id))
        s.name = !!rec && !!rec.name ? (rec.name).trim() : false
        return s
      })
      dispatch(setLimitSettings(newLimits))
    }

    dispatch(setSettingIsProcess(false))
    return data
  } catch (e) {
    dispatch(setSettingIsProcess(false));
    console.log('fetchVariableName Error: ', e);
    return e
  }
}


/**
 * Update Limits
 * @returns 
 */
export const updateLimits = ({ variable_id, marking_color, value }, onSuccess) => async (dispatch) => {
  dispatch(setSettingIsProcess(true))
  try {
    const data = await handleRequest("post", false, {
      query: `
      mutation SetLimits($data: limitsInput) {
        setLimits(data: $data) {
          variable_id
          value
          marking_color
        }
      }`,
      variables: {
        data: {
          variable_id, marking_color,
          value: onlyNumber(value)
        }
      }
    })

    if (!!data && !!data.setLimits && !!data.setLimits.length) {
      dispatch(fetchLimitSettings())
      onSuccess()
    }

    dispatch(setSettingIsProcess(false))
    return data
  } catch (e) {
    dispatch(setSettingIsProcess(false));
    console.log('updateLimits Error: ', e);
    return e
  }
}

