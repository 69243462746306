import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import Overlay from "ol/Overlay";
import RMMSContext from "../../context/RMMSContext";
import { parseLineType } from "../../constant/helpers";
import { fetchRoadImages } from "../../actions/imagePlayer";
import "./Overlay.scss";

const OverlayDiv = () => {
  const { map } = useContext(RMMSContext);
  const [localValue, setLocalValue] = useState(null);
  const [roadName, setRoadName] = useState(null);
  const [propertyName, setPropertyName] = useState(null);
  const [startDis, setStartDis] = useState(null);
  const [endDis, setEndDis] = useState(null);
  const [lineCode, setLineCode] = useState(null);
  const [identity, setIdentity] = useState(null)

  const { t } = useTranslation();
  const dispatch = useDispatch()

  useEffect(() => {
    if (!map) return;
    map.on("pointermove", (event) => {
      let clickedFeature2 = {};
      const clickedFeature = event.coordinate;
      map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        clickedFeature2 = event.coordinate;
        if (feature.properties_.value) {
          setLocalValue(feature.properties_.value);
        }
        if (feature.properties_.description) {
          setPropertyName(feature.properties_.value);
        }
        if (feature.properties_.roadname) {
          setRoadName(feature.properties_.roadname);
        }
        if (feature.properties_.variable) {
          setPropertyName(feature.properties_.variable);
        }
        if (feature.properties_.description) {
          setPropertyName(feature.properties_.description);
        }
        if (feature.properties_.start_distance) {
          setStartDis(feature.properties_.start_distance);
        }
        if (feature.properties_.end_distance) {
          setEndDis(feature.properties_.end_distance);
        }
        if (feature.properties_.rmms_line_code || feature.properties_.rmms_linetype_code) {
          setLineCode(feature.properties_.rmms_line_code || feature.properties_.rmms_linetype_code);
        }
        if (feature.properties_.section_number) {
          setIdentity(feature.properties_.section_number)
        }
      });
      if (clickedFeature2 !== clickedFeature) {
        setLocalValue(null);
        setRoadName(null);
      }
      //setClickCoordinate(event.coordinate)

      var popup = new Overlay({
        element: document.getElementById("overlapExample"),
        position: event.coordinate,
      });
      map.addOverlay(popup);
      /*map.on("moveend", () => {
      zoom=map.getView().getZoom();
    });
	
      map.on('zoomend', function () {
      zoom=map.getView().getZoom();
    });*/
    })

    map.on("click", (event) => {
      let featureList = []
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        featureList.push(feature.properties_)
      })

      if (!!featureList && !!featureList.length) {
        dispatch(fetchRoadImages(featureList[featureList.length - 1]))
      }
    })

    // eslint-disable-next-line
  }, [map])

  return (
    <>
      {(localValue || roadName) ? (
        <div className="overlay-cotainer" id="overlapExample">
          {!!roadName && <p>{t('map_overlay.rd_name')}: {roadName}</p>}
          {!!propertyName && <p>{t('map_overlay.property')}: {propertyName}</p>}
          {!!localValue && <p>{t('map_overlay.value')}: {localValue}</p>}
          {!!startDis && <p>{t('map_overlay.start')}: {startDis} (m)</p>}
          {!!endDis && <p>{t('map_overlay.end')}: {endDis} (m)</p>}
          {!!lineCode && <p>{t('map_overlay.line_code')}: {parseLineType(lineCode)}</p>}
          {!!identity && <p>{t('map_overlay.identity')}: {parseLineType(identity)}</p>}
        </div>
      ) : (
        <div id="overlapExample" />
      )}
    </>
  );
};

export default OverlayDiv;
