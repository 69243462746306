import React, { useEffect, useRef } from "react"
import "./index.scss"

const ResizableWindow = ({
    left = 780,
    className = '', isOpen = false, children
}) => {
    const wrapperRef = useRef()
    const rightLineRef = useRef()
    const bottomLineRef = useRef()

    useEffect(() => {
        if (!isOpen) {
            if (!!wrapperRef &&
                (wrapperRef.current !== null)) {
                const elem = wrapperRef.current
                if (!!elem && !!elem.style) {

                    // Set the element's new position
                    elem.style.top = "0px";
                    elem.style.left = `${left}px`;
                }
            }
        }

        // eslint-disable-next-line
    }, [isOpen])

    let newPosX = 0, newPosY = 0, startPosX = 0, startPosY = 0;
    let startX, startY, startWidth, startHeight;

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
        // eslint-disable-next-line
    }, [])

    const handleMouseDown = (e) => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null)
        ) {
            const elem = wrapperRef.current
            if (!!elem && !!elem.style) {
                elem.classList.remove("full-transition")
                elem.classList.add("some-transition")
            }

            if (
                (!!rightLineRef &&
                    (rightLineRef.current !== null) &&
                    (rightLineRef.current === e.target)) ||
                (!!bottomLineRef &&
                    (bottomLineRef.current !== null) &&
                    (bottomLineRef.current === e.target))
            ) {
                const elem = wrapperRef.current
                startWidth = parseInt(document.defaultView.getComputedStyle(elem).width, 10)
                startHeight = parseInt(document.defaultView.getComputedStyle(elem).height, 10)

                startX = e.clientX;
                startY = e.clientY;

                document.addEventListener('mousemove', resizeOnMouseMove)

            } else if ((!!wrapperRef &&
                (wrapperRef.current !== null) &&
                (wrapperRef.current === e.target)) ||
                (!!e.target && !!e.target.className &&
                    !!(e.target.className).includes &&
                    (e.target.className).includes('download-btn-holder'))
            ) {
                // Get the starting position of the cursor
                startPosX = e.clientX
                startPosY = e.clientY

                document.addEventListener('mousemove', mouseMove)
            }

            document.addEventListener('mouseup', function () {
                if (!!wrapperRef &&
                    (wrapperRef.current !== null)) {
                    const elem = wrapperRef.current
                    if (!!elem && !!elem.style) {
                        elem.classList.add("full-transition")
                        elem.classList.remove("some-transition")
                    }
                }
                document.removeEventListener('mousemove', resizeOnMouseMove)
                document.removeEventListener('mousemove', mouseMove)
            })
            return false
        }
    }

    const mouseMove = (e) => {
        // Calculate the new position
        newPosX = startPosX - e.clientX;
        newPosY = startPosY - e.clientY;

        // With each move we also want to update the start X and Y
        startPosX = e.clientX
        startPosY = e.clientY

        if (!!wrapperRef &&
            (wrapperRef.current !== null)) {
            const elem = wrapperRef.current
            if (!!elem && !!elem.style) {

                // Set the element's new position
                elem.style.top = (elem.offsetTop - newPosY) + "px";
                elem.style.left = (elem.offsetLeft - newPosX) + "px";
            }
        }
    }

    const resizeOnMouseMove = (e) => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null)) {
            const elem = wrapperRef.current
            if (!!elem && !!elem.style) {
                elem.style.width = (startWidth + e.clientX - startX) + 'px';
                elem.style.height = (startHeight + e.clientY - startY) + 'px';
            }
        }
    }

    const handleInnerScroll = () => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null)) {
            const elem = wrapperRef.current
            if (!!rightLineRef &&
                (rightLineRef.current !== null)) {
                const rightLineElem = rightLineRef.current
                rightLineElem.style.right = `-${elem.scrollLeft}px`
            }

            if (!!bottomLineRef &&
                (bottomLineRef.current !== null)) {
                const bottomLineElem = bottomLineRef.current
                bottomLineElem.style.bottom = `-${elem.scrollTop}px`
            }
        }
    }

    return (
        <div className={`resizable-container ${className} ${isOpen ? '' : 'info-hide'}`} ref={wrapperRef} onScroll={handleInnerScroll}>
            <div className="bottom-line-resizer" ref={bottomLineRef} />
            <div className="right-line-resizer" ref={rightLineRef} />
            {children}
        </div>
    )
}

export default ResizableWindow