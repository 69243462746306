import { SET_ACTIVE_NAV, SET_CLICKED_NAVS, SET_MENU_STATE } from "../actions/navbar"

const initialState = {
  active: false,
  clickedNavs: [],
  menuState: []
}

const navbar = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_NAV:
    case SET_CLICKED_NAVS:
    case SET_MENU_STATE:
      return Object.assign({}, state, action.payload);
    default:
      return state
  }
}

export default navbar