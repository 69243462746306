import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { setContext } from '@apollo/client/link/context'
import { createHttpLink, ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { BrowserAuthError } from '@azure/msal-browser';
import B2CConfig from './services/B2CConfig';
import msalObj from './services/MsalService';
import RMMSContext from "./context/RMMSContext";
import LoggedInLayout from "./components/common/LoggedInLayout";
import Welcome from "./views/welcome";
import SpinLoader from "./components/common/SpinLoader";
import PrivateRoute from "./components/PrivateRoute";
import 'bootstrap/dist/css/bootstrap.min.css'
import "./App.scss";
import { REACT_APP_API_URL } from "./constant";

function App() {

  const [map, setMap] = useState(null);
  const [apolloClient, setApolloClient] = useState(null);
  const { isProcess } = useSelector(state => state.global)

  useEffect(() => {
    const httpLink = createHttpLink({ uri: REACT_APP_API_URL });

    // When using apolloclient, add api access token to headers
    const authLink = setContext((_, { headers }) => ({
      headers: {
        // We can put other headers here
        ...headers
      }
    }))

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    })

    setApolloClient(client)
  }, [])

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const user = msalObj.getActiveAccount()
    if (!!user && !!user.idTokenClaims && !!user.idTokenClaims.exp) {
      if ((new Date()).getTime() > (new Date(user.idTokenClaims.exp * 1000)).getTime()) {
        setIsLoggedIn(false)
      } else {
        setIsLoggedIn(true)
      }
    }
  }, [])

  const login = async () => {
    try {
      const loginInfo = await msalObj.loginPopup({
        scopes: ['openid', ...B2CConfig.apiScopes],
      });
      msalObj.setActiveAccount(loginInfo.account);
      setIsLoggedIn(true);
    } catch (err) {
      if (
        err instanceof BrowserAuthError &&
        err.errorCode === 'user_cancelled'
      ) {
        // Do nothing
      } else {
        console.error(err);
      }
    }
  }

  return (
    <RMMSContext.Provider value={{ map, setMap }}>
      {!!isProcess && <SpinLoader />}
      {
        !!apolloClient ? <ApolloProvider client={apolloClient}>
          <Router>
            <Routes>
              <Route path={"/"} element={<Welcome loginCallBack={login} isLoggedIn={isLoggedIn} />} />
              <Route path={"/welcome"} element={<Welcome loginCallBack={login} isLoggedIn={isLoggedIn} />} />
              <Route path="/rmms/*" element={<PrivateRoute isLoggedIn={isLoggedIn}>
                <LoggedInLayout />
              </PrivateRoute>} />
            </Routes>
          </Router>
        </ApolloProvider> : <SpinLoader />
      }
    </RMMSContext.Provider>
  );
}

export default App;
