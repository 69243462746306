import { useState } from "react";
import { useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import { handleRequest } from "../../../../constant/helpers";
import StrategyForm from "../StrategyForm";
import { setStrategyIsProcessing, storeMeasurementList } from "../../../../actions/strategies";
import { changeMapOverlay } from "../../../../actions/roads";

const AddStrategy = ({ submitting, handleSubmit, valid, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const {
      name, budget, distanceCombinedShort, distanceSkipShort,
      lengthMultiplier, lightingMultiplier, aadtMultiplier,
      speedLimitMultiplier, maintainanceClassMultiplier, tentMultiplier
    } = data
    setIsLoading(true); // HINT: I'll remove this local function later
    dispatch(setStrategyIsProcessing(true))
    try {
      const data = await handleRequest("post", false, {
        query: `
        query Query($measurementCalculationParameter: measurementCalculationParameter) {
          calculateUnitCost(measurementCalculationParameter: $measurementCalculationParameter) {
            totalCost
            selected {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
            notSelected {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
            goodCondition {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
          }
        }`,
        variables: {
          measurementCalculationParameter: {
            name,
            budget: Number(budget),
            ...(!!distanceCombinedShort && { "distanceCombinedShort": Number(distanceCombinedShort) }),
            ...(!!distanceSkipShort && { "distanceSkipShort": Number(distanceSkipShort) }),
            ...({ lengthMultiplier: !!lengthMultiplier ? Number(lengthMultiplier) : 0 }),
            ...({ lightingMultiplier: !!lightingMultiplier ? Number(lightingMultiplier) : 0 }),
            // ...(!!colourMultiplier && { "colourMultiplier": parseInt(colourMultiplier) }),
            ...({ aadtMultiplier: !!aadtMultiplier ? Number(aadtMultiplier) : 0 }),
            ...({ speedLimitMultiplier: !!speedLimitMultiplier ? Number(speedLimitMultiplier) : 0 }),
            ...({ maintainanceClassMultiplier: !!maintainanceClassMultiplier ? Number(maintainanceClassMultiplier) : 0 }),
            // ...(!!paymentMultiplier && { "paymentMultiplier": parseInt(paymentMultiplier) }),
            ...({ tentMultiplier: !!tentMultiplier ? Number(tentMultiplier) : 0 }),
          }
        }
      });
      dispatch(storeMeasurementList(data.calculateUnitCost));
      dispatch(changeMapOverlay("strategy"));
      setIsLoading(false);
      dispatch(setStrategyIsProcessing(false))
      onSuccess();
    } catch (err) {
      console.log('Add Strategy Error:', err);
      setIsLoading(false);
      dispatch(setStrategyIsProcessing(false));
    }
  };

  return (
    <StrategyForm
      submitting={submitting}
      isLoading={isLoading}
      valid={valid}
      handleSubmit={handleSubmit(onSubmit)} />
  );
};

export default reduxForm({
  form: 'add-strategy-form',
  initialValues: {
    budget: 100,
    distanceCombinedShort: 1,
    distanceSkipShort: 1,
    lengthMultiplier: 0,
    lightingMultiplier: 0,
    colourMultiplier: 1,
    aadtMultiplier: 0,
    speedLimitMultiplier: 0,
    maintainanceClassMultiplier: 0,
    paymentMultiplier: 1,
    tentMultiplier: 0
  },
  enableReinitialize: true
})(AddStrategy);