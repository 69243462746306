import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Download = ({
    fill = colorCodes.BLUE_3,
    height = 20,
    width = 20,
    style = {},
    viewBox = '0 0 20 20',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g transform="translate(-3.5 -3.5)">
            <path d="M22.5,22.5v4a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2v-4" transform="translate(0 -6)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path d="M10.5,15l5,5,5-5" transform="translate(-2 -3.5)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path d="M18,16.5V4.5" transform="translate(-4.5 0)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
    </svg>
)

export default Download