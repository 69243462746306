import { Fill, Stroke, Style } from "ol/style";

export default {
  Polygon_geoserver_green: new Style({
    fill: new Fill({
      color: '#ADD8E6'
    }),
    stroke: new Stroke({
      color: 'green',
      width: 1
    }),
  })
};

export const colourExport = (color => {
  return new Style({
    fill: new Fill({
      color: '#ADD8E6'
    }),
    stroke: new Stroke({
      color: color,
      width: 10
    })
  });
});