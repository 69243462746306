import { useContext, useEffect } from "react";
import RMMSContext from "../../context/RMMSContext";
import OLVectorTile from "ol/layer/VectorTile";
import { getApiToken } from "../../constant/helpers";
import VectorTileSource from "ol/source/VectorTile"; //sources

const VectorTileLayer = ({ source, style, zIndex = 0, visbility = 'true', setStyle }) => {
	const { map } = useContext(RMMSContext);

	async function customLoader(tile, url) {
		tile.setLoader(async function (extent, resolution, projection) {
			const tokenObj = await getApiToken();
			fetch(url, {
				method: 'get',
				headers: {
					'Authorization': `Bearer ${tokenObj.accessToken}`
				}
			}).then((response) => {
				if (response.status !== 404) {
					response.arrayBuffer().then(function (data) {
						if (data) {
							const format = tile.getFormat(); // ol/format/MVT configured as source format
							const features = format.readFeatures(data, {
								extent: extent,
								featureProjection: projection
							});
							tile.setFeatures(features);
							map.updateSize();
						}
					});
				}
				else {
					// NOTE: This setting the tile.setFeatures([]) is needed for tiles that are not set, otherwise the tiles wont update
					tile.setFeatures([]);
				}
			});
		});
	}

	useEffect(() => {
		if (!map) return;

		const VectorTileSourceObj = new VectorTileSource({
			format: source.format,
			url: source.url
		});

		VectorTileSourceObj.setTileLoadFunction(customLoader);

		let vectorTileLayer = new OLVectorTile({
			source: VectorTileSourceObj,
			style,
			zIndex
		});

		map.addLayer(vectorTileLayer);
		vectorTileLayer.setZIndex(zIndex);
		// Todo: use another use effect for this for setStyle,style
		vectorTileLayer.setVisible(visbility);
		if (setStyle !== 0) {
			console.log("setstyle set");
			vectorTileLayer.setStyle(setStyle);
		}

		return () => {
			if (map) {
				map.removeLayer(vectorTileLayer);
			}
		};
		// eslint-disable-next-line
	}, [map, setStyle, style]);

	return null;
};

export default VectorTileLayer;;
