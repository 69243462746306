import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import store from "./common/Store";
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import Icon from './components/common/Icons';
import { colorCodes } from './constant/colors';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './index.css';

const CloseButton = ({ closeToast }) => (
  <Icon className="h-100 center-flex" onClick={closeToast} name="cross" fill={colorCodes.WHITE_1} width="11" height="11.054" viewBox="0 0 11 11.054" />
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer autoClose={2000} closeButton={CloseButton} icon={false}
        className='waywize-toast-container' toastClassName='waywize-toast-content'
        position='top-center' pauseOnHover={false} hideProgressBar={true} />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
