import {
  SET_ALL_STRATEGIES, SET_STRATEGY_PROCESSING, STRATEGY_DELETED,
  STORE_MEASUREMENT_LIST_OBJECT, SET_STRATEGY_ID
} from "../actions/strategies"

const initialState = {
  isProcessing: false,
  strategyId: false,
  strategies: [],
  strategyObjectList: []
};

const strategies = (state = initialState, action) => {
  switch (action.type) {
    case SET_STRATEGY_PROCESSING:
    case SET_STRATEGY_ID:
    case SET_ALL_STRATEGIES:
    case STORE_MEASUREMENT_LIST_OBJECT:
      return Object.assign({}, state, action.payload);
    case STRATEGY_DELETED: {
      let allStrategies = (state.strategies).filter(a => a.id !== action.strategyId)
      return {
        ...state,
        strategies: allStrategies
      }
    }
    default:
      return state;
  }
};

export default strategies;