import { useState, useEffect } from "react"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { Chart } from 'chart.js'
import { useTranslation } from 'react-i18next'
import LabelInput from "../../../components/common/LabelInput"
import SpinLoader from "../../../components/common/SpinLoader"
import WaywizeButton from "../../../components/common/WaywizeButton"
import { handleRequest, onlyNumber } from "../../../constant/helpers"
import LabelSelectBox from "../../../components/common/LabelSelectBox"
import { DATE_FORMAT, lineTypeList } from "../../../constant"
import DoughnutChart from "../../../components/common/DoughnutChart"
import {
  changeLineType,
  changeMapOverlay,
  changeMeasurementDate, changeRoadEndDistance, changeRoadName, changeRoadStartDistance,
  changeValue, changeValueCondition, changeVariable
} from "../../../actions/roads"
import CommonTable from "../../../components/common/CommonTable"
import { colorCodes } from "../../../constant/colors"
import Icon from "../../../components/common/Icons"
import { setMeasurementRdArr, showRoadMeasurmentOnMap } from "../../../actions/measurement"
import ResizableWindow from "../../../components/common/ResizableWindow"
import { fetchRoadImages } from "../../../actions/imagePlayer"
import "./index.scss"

const variableList = [
  { value: "RL(d)", label: "RL(d)" },
  { value: "Visual", label: "Visual" },
  { value: "MobilEye", label: "MobilEye" }
];

const columns = [
  { Header: "table.rd_cat", accessor: "road_class", Filter: false },
  { Header: "table.rd_num", accessor: "road", Filter: false },
  {
    Header: "table.sec", accessor: (row) => {
      return ((row.roadname).replace(row.road, "")).replace(row.road_class, "");
    }, Filter: false
  },
  { Header: "table.variable", accessor: "variable", Filter: false },
  { Header: "table.start_dis", accessor: "start_distance", Filter: false },
  { Header: "table.end_dis", accessor: "end_distance", Filter: false },
  { Header: "table.line_type", accessor: "rmms_linetype_code", Filter: false },
  { Header: "table.rd_name", accessor: "roadname", Filter: false },
  { Header: "table.value", accessor: "value", Filter: false }
]

const MeasurementForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseChart, setResponseChart] = useState('');
  const [responsePieChart, setResponsePieChart] = useState('');

  let dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    lineType,
    road_name, start_distance, end_distance,
    measurement_date, valueCondition, variable
  } = useSelector(state => state.roadReducer);

  const value_new = useSelector(state => state.roadReducer.value);
  const { roadArr } = useSelector(state => state.measurement);
  const [chartData, setChartData] = useState('');
  const [pieChartData, setPieChartData] = useState('');
  const [isOpen, setIsOpen] = useState(true)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(changeMapOverlay(variable))
    try {
      const resp = await handleRequest("post", false, {
        query: `
        query Query($roadMeasurementParameter: roadMeasurementParameter) {
          roadMeasurementSearch(roadMeasurementParameter: $roadMeasurementParameter) {
            roadname
            start_distance
            end_distance
            measurement_date
            variable
            value
            road
            section
            road_class
            rmms_linetype
            rmms_linetype_code
          }
        }`,
        variables: {
          roadMeasurementParameter: {
            variable,
            ...(!!road_name && { "roadname": road_name }),
            ...(!!start_distance && { "start_distance": (start_distance) }),
            ...(!!end_distance && { "end_distance": (end_distance) }),
            ...(!!measurement_date && { "measurement_date": moment(measurement_date).format(DATE_FORMAT) }),
            ...(!!value_new && { "value": value_new }),
            ...(!!valueCondition && { "valueCondition": valueCondition }),
            ...(!!lineType && { "rmms_linetype": lineType })
          }
        }
      });

      if (!!resp && !!resp.roadMeasurementSearch) {
        dispatch(setMeasurementRdArr(resp.roadMeasurementSearch))
      } else {
        dispatch(setMeasurementRdArr([]))
      }

      const respChart = await handleRequest("post", false, {
        query: `
        query RoadMeasurementChart($roadMeasurementParameter: roadMeasurementParameter) {
          roadMeasurementChart(roadMeasurementParameter: $roadMeasurementParameter) {
            variable
            variable_class_name
            color
            sum
          }
        }`,
        variables: {
          roadMeasurementParameter: {
            variable,
            ...(!!road_name && { "roadname": road_name }),
            ...(!!value_new && { "value": value_new }),
            ...(!!valueCondition && { "valueCondition": valueCondition }),
            ...(!!lineType && { "rmms_linetype": lineType })
          }
        }
      });
      setResponseChart(!!respChart && !!respChart.roadMeasurementChart ? respChart.roadMeasurementChart : false);

      const respPieChart = await handleRequest("post", false, {
        query: `
        query GetOverLimitValue($variableName: String!) {
          getOverLimitValue(variableName: $variableName) {
            over_limit
            total_length
          }
        }`,
        variables: {
          ...(!!variable && { "variableName": variable })
        }
      })

      setResponsePieChart(!!respPieChart && !!respPieChart.getOverLimitValue ? respPieChart.getOverLimitValue : false);
      setIsLoading(false);
    } catch (err) {
      console.log('Road Submit Search Error:', err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //Setting data
    if (responseChart) {
      const labels = [];
      const color = [];
      const sum = [];
      responseChart.map((element) => {
        labels.push(element.variable_class_name);
        color.push(element.color);
        sum.push(element.sum);
        return element
      });
      const data = {
        labels,
        datasets: [{
          label: variable,
          data: sum,
          backgroundColor: color,
          borderColor: color
        }]
      };
      setChartData(data);
    } else {
      setChartData(false);
    }
    // eslint-disable-next-line
  }, [responseChart]);

  useEffect(() => {
    //Setting data
    if (responsePieChart) {
      const labels = [];
      const color = [];
      const sum = [];
      responsePieChart.map((element) => {
        labels.push(element.over_limit === 0 ? 'Dissapproved' : 'Approved');
        color.push(element.over_limit === 1 ? colorCodes.GREEN_1 : colorCodes.RED_1);
        sum.push(element.total_length);
        return element
      });
      const data = {
        labels,
        datasets: [{
          label: variable,
          data: sum,
          backgroundColor: color,
          borderColor: color
        }]
      };
      setPieChartData(data);
    } else {
      setPieChartData(false);
    }
    // eslint-disable-next-line
  }, [responsePieChart]);

  const isValid = (!!variable);

  Chart.overrides.doughnut.plugins.tooltip = {
    callbacks: {
      label: (tooltipItem) => {
        if (!!tooltipItem && !!tooltipItem.chart && !!tooltipItem.chart.config && !!tooltipItem.chart.config._config && tooltipItem.chart.config._config.options && (tooltipItem.chart.config._config.options.id === 'overlimit-pie-chart')) {
          return tooltipItem.label + ' : ' + tooltipItem.formattedValue
        }
        return tooltipItem.label + ' : ' + tooltipItem.formattedValue + ' (m)'
      },
      title: () => false
    }
  }

  Chart.overrides.doughnut.plugins.legend = {
    ...Chart.overrides.doughnut.plugins.legend,
    // position: 'bottom',
    labels: {
      ...Chart.overrides.doughnut.plugins.legend.labels,
      boxWidth: 20,
      boxHeight: 16,
      font: {
        size: 12,
        family: 'BookSizeFont'
      }
    },
    title: {
      ...Chart.overrides.doughnut.plugins.legend.title,
      color: colorCodes.BLACK_1,
    }
  }


  return (
    <div className="d-flex ai-center">
      <form onSubmit={handleSubmit} className="form-card-container center-flex">
        {isLoading && <SpinLoader />}
        <LabelSelectBox name="variable" label={t('form_input.select_var')} options={variableList}
          value={variable} handleOnChange={e => dispatch(changeVariable(e.value))} />
        <LabelInput label={t('form_input.rd_name')} value={road_name} onChange={e => dispatch(changeRoadName(e.target.value))} placeholder={t('form_input.rd_name_ex')} />
        <LabelSelectBox name="line_type" label={t('form_input.line_type')} options={lineTypeList}
          value={lineType} handleOnChange={e => dispatch(changeLineType(!!e && !!e.value ? e.value : null))} isClearable={true} />
        <LabelInput label={t('form_input.start_dis')} value={start_distance} onChange={e => dispatch(changeRoadStartDistance(onlyNumber(e.target.value)))} placeholder="Ex. 0 (Optional)" />
        <LabelInput label={t('form_input.end_dis')} value={end_distance} onChange={e => dispatch(changeRoadEndDistance(onlyNumber(e.target.value)))} placeholder="Ex. 200 (Optional)" />
        <LabelInput label={t('form_input.measurment_dt')} type="date" value={measurement_date} onChange={e => dispatch(changeMeasurementDate(e.target.value))} placeholder="(Optional)" />

        <LabelSelectBox name="variable" label={t('form_input.value_cond')} options={[
          { value: "GREATER", label: t('form_input.greater') },
          { value: "LESSER", label: t('form_input.lesser') }
        ]}
          value={valueCondition} handleOnChange={e => dispatch(changeValueCondition(e.value))} />
        <LabelInput label={t('form_input.value')} value={value_new} onChange={e => dispatch(changeValue(Math.round(onlyNumber(e.target.value))))} placeholder="Ex. 10 (Optional)" />
        <WaywizeButton type="submit" className="mt-17" title={isLoading ? `${t("button.loading")}...` : t("button.search")} disabled={!isValid} />
      </form>
      {
        ((!!chartData || !!pieChartData) || !!roadArr.length) && <ResizableWindow className='card-shadow-elem chart-and-table' isOpen={isOpen} left={475}>
          <div className={`header-action-tool center-flex ${isOpen ? '' : 'info-hide'}`} onClick={() => setIsOpen(!isOpen)}>
            <Icon name="double-arrow" className="toggle-btn" />
          </div>
          {(chartData || pieChartData) ?
            <div className="chart-content-holder">
              <div className="chart-content-header-title">{variable}</div>
              {!!chartData && <DoughnutChart data={chartData} />}
              {!!pieChartData && <DoughnutChart data={pieChartData} options={{ id: 'overlimit-pie-chart' }} />}
            </div> :
            "No Chart Data"}

          {
            !!roadArr.length &&
            <div className="table-info-holder">
              <CommonTable columns={columns}
                data={roadArr} downloadBtn={true}
                trTitle={t("table.click_to_view_plan_map")}
                onRowClick={(row) => {
                  dispatch(fetchRoadImages(row.original))
                  dispatch(showRoadMeasurmentOnMap(row.original.variable, row.original.roadname, row.original.start_distance, row.original.end_distance))
                }}
                selectedRows={[]} />
            </div>
          }
        </ResizableWindow>
      }
    </div>
  )
}

export default MeasurementForm