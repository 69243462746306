import { useEffect, useRef } from "react"
import Icon from "../Icons"
import "./index.scss"

const CustomPopUp = ({
    className = '',
    handleClose, children, title = 'Title' }) => {
    const wrapperRef = useRef()

    const handleClickOutside = e => {
        if (!!wrapperRef && wrapperRef.current !== null && !wrapperRef.current.contains(e.target)) {
            !!handleClose && handleClose()
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line 
    }, [])


    return (
        <div className="custom-popup-container">
            <div className={`custom-popup-sub-container ${className}`} ref={wrapperRef}>
                {
                    !!title && <div className="custom-popup-header-section">
                        <div className="custom-popup-header-title">{title}</div>
                        <div className="custom-popup-header-action-tool">
                            <div className={`svg-img-holder between-flex`}>
                                <Icon name="cross" width="14" height="14" className="cross-btn" onClick={() => !!handleClose && handleClose()} />
                            </div>
                        </div>
                    </div>
                }
                <div className="custom-popup-wrapper">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default CustomPopUp