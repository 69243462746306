import { toast } from "react-toastify"
import { handleRequest } from "../constant/helpers"
import { setMapLatLong } from "./global"
import { changeMapOverlay } from "./roads"
import { setStrategyId } from "./strategies"

export const PLAN_IS_PROCESSING = "PLAN_IS_PROCESSING"
export const SET_ALL_PLANS = "SET_ALL_PLANS"
export const SET_PLAN_SECTIONS = "SET_PLAN_SECTIONS"
export const SET_SELECTED_PLAN_SECTIONS = "SET_SELECTED_PLAN_SECTIONS"
export const SEC_PLAN_DELETED = "SEC_PLAN_DELETED"
export const M_PLAN_DELETED = "M_PLAN_DELETED"
export const SET_PARENT_PLAN_ID = "SET_PARENT_PLAN_ID"
export const MOVE_PLAN_SUCCESS = "MOVE_PLAN_SUCCESS"
export const EDIT_PLAN_SEC_SUCCESS = "EDIT_PLAN_SEC_SUCCESS"

export const setPlanIsProcessing = isProcessing => ({
    type: PLAN_IS_PROCESSING,
    payload: { isProcessing }
})

export const setAllPlans = plans => ({
    type: SET_ALL_PLANS,
    payload: {
        isProcessing: false,
        plans
    }
})

export const setPlanSections = planSections => ({
    type: SET_PLAN_SECTIONS,
    payload: {
        isProcessing: false,
        planSections
    }
})

export const setSelectedPlanSections = selectedPlanSections => ({
    type: SET_SELECTED_PLAN_SECTIONS,
    payload: {
        isProcessing: false,
        selectedPlanSections
    }
})

const planSecDeleted = planSecId => ({
    type: SEC_PLAN_DELETED,
    planSecId
})

const movePlanSuccess = (id, plan) => ({
    type: MOVE_PLAN_SUCCESS,
    id, plan
})

const editPlanSecSuccess = (secId, planSec) => ({
    type: EDIT_PLAN_SEC_SUCCESS,
    secId, planSec
})

const maintenPlanDeleted = maintenId => ({
    type: M_PLAN_DELETED,
    maintenId
})

export const parentPlanId = ruleId => ({
    type: SET_PARENT_PLAN_ID,
    payload: { ruleId }
})

/**
 * Fetch All plans
 */
export const fetchAllPlans = () => async (dispatch, getState) => {
    dispatch(setPlanIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `
            query {
                getHovedPlans {
                    id
                    address_system_id
                    name
                    rmms_maintenanceplan_rule_id
                    maintenanceplan_year
                    all_unit_cost
                    buget_amount
                    move_to_ramapp
                    backlog_cost
                    modified
                  }
            }`
        })
        if (!!resp && !!resp.getHovedPlans) {
            const allPlans = resp.getHovedPlans
            const { strategyId } = getState().strategies
            dispatch(setAllPlans(resp.getHovedPlans))
            if (!!strategyId) {
                const record = allPlans.find(p => parseInt(p.rmms_maintenanceplan_rule_id) === parseInt(strategyId))
                if (!!record && record.rmms_maintenanceplan_rule_id) {
                    dispatch(fetchPlanSections(record.id, record.rmms_maintenanceplan_rule_id))
                    dispatch(changeMapOverlay('hovedPlan'))
                    dispatch(setStrategyId(false))
                }
            }
        } else {
            dispatch(setAllPlans([]))
        }
        return resp
    } catch (e) {
        dispatch(setPlanIsProcessing(false))
        console.log('fetchAllPlans Error: ', e)
        return e
    }
}


/**
 * Fetch All sections
 * for the selected plan
 * @param {*} id 
 * @param {*} ruleId 
 * @returns 
 */
export const fetchPlanSections = (id, ruleId = false) => async (dispatch) => {
    dispatch(setPlanIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `
            query getHovedPlan($getHovedPlanId: ID!) {
              getHovedPlan(id: $getHovedPlanId) {
                id
                identity
                road_category
                roadNumber
                part
                roadname
                intersection
                start_distance
                end_distance
                rmms_linetype_code
                marking_factor
                description
                aadt
                newPayment
                direction
                status
                line_weight
                unit_cost
                is_selected
                bad_condition
                deadline_date
              }
            }`,
            variables: {
                getHovedPlanId: id
            }
        })

        dispatch(parentPlanId(ruleId))
        if (!!resp && !!resp.getHovedPlan) {
            dispatch(setPlanSections(resp.getHovedPlan))
            const respGetHovedPlan = resp.getHovedPlan
            const selectedPS = respGetHovedPlan.filter(p => !!p && (p.is_selected === true) && (
                (p.status === 0) || (p.status === 1) || (p.status === 2) || (p.status === 3) ||
                (p.status === 4) || (p.status === 5) || (p.status === 6) || (p.status === 7)
            ))
            dispatch(setSelectedPlanSections(selectedPS))
        } else {
            dispatch(setPlanSections([]))
            dispatch(setSelectedPlanSections([]))
        }
        return resp
    } catch (e) {
        dispatch(setPlanIsProcessing(false))
        dispatch(parentPlanId(false))
        console.log('fetchPlanSections Error: ', e)
        return e
    }
}


/**
 * Delete Maintenance Plan
 * @param {*} id 
 * @param {*} ruleId 
 * @returns 
 */
export const deleteMaintenPlan = (id, ruleId) => async (dispatch) => {

    // Prevent to delete default Plan
    if ((Number(id) === 280) || (Number(id) === 281)) {
        toast.error('You can not delete this plan')
        return false
    }

    dispatch(setPlanIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `mutation DeleteHovedPlan($rmmsMaintenanceplanRuleId: ID!) {
            deleteHovedPlan(rmms_maintenanceplan_rule_id: $rmmsMaintenanceplanRuleId) { response }
            }`,
            variables: {
                rmmsMaintenanceplanRuleId: id
            }
        })

        dispatch(setPlanIsProcessing(false))

        if (!!resp && !!resp.deleteHovedPlan && !!resp.deleteHovedPlan.response) {
            if (ruleId === id) {
                dispatch(parentPlanId(false))
            }
            dispatch(maintenPlanDeleted(id))
            toast.success('Deleted Successfully')
            return true
        }
        return false
    } catch (err) {
        console.log('deleteMaintenPlan Error:', err);
        dispatch(setPlanIsProcessing(false))
        return false
    }
}


/**
 * Edit Section Plan
 * @param {*} data 
 * @param {*} successCallback 
 * @param {*} id 
 * @param {*} showFlash 
 * @returns 
 */
export const editSecPlan = (data, successCallback, id, showFlash = true) => async (dispatch) => {
    dispatch(setPlanIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `
            mutation Mutation($data: editHovedPlanInput) {
            editHovedPlan(data: $data) {
                response, unitCost
            }
        }`,
            variables: { data }
        })

        dispatch(setPlanIsProcessing(false))

        if (!!resp && !!resp.editHovedPlan && !!resp.editHovedPlan.response) {
            if (!!successCallback) {
                successCallback()
            }
            if (!!data.description) {
                data.description = data.description.new_description
            }
            data.unit_cost = !!resp.editHovedPlan.unitCost ? resp.editHovedPlan.unitCost : 0
            dispatch(editPlanSecSuccess(id, data))
            if (!!showFlash) {
                toast.success('Updated Successfully')
            }
        }
    } catch (err) {
        console.log('editSecPlan Error:', err);
        dispatch(setPlanIsProcessing(false))
    }
}


/**
 * Move To RamAPP
 * @param {*} id
 * @returns 
 */
export const moveToRamApp = (id, toMove) => async (dispatch) => {
    dispatch(setPlanIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `
            mutation MovedToRamApp($data: movedToRamAppInput) {
                movedToRamApp(data: $data) {
                  id
                  address_system_id
                  name
                  rmms_maintenanceplan_rule_id
                  maintenanceplan_year
                  created
                  modified
                  all_unit_cost
                  buget_amount
                  backlog_cost
                  move_to_ramapp
                }
              }`,
            variables: {
                "data": { id, toMove }
            }
        })

        dispatch(setPlanIsProcessing(false))

        if (!!resp && !!resp.movedToRamApp && !!resp.movedToRamApp.length) {
            dispatch(movePlanSuccess(id, resp.movedToRamApp[0]))
            toast.success('Updated Successfully')
        }
    } catch (err) {
        console.log('moveToRamApp Error:', err);
        dispatch(setPlanIsProcessing(false))
    }
}


/**
 * Delete Section Plan
 * @param {*} id 
 * @returns 
 */
export const deleteSecPlan = id => async (dispatch) => {
    dispatch(setPlanIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `query DeleteHovedPlan($rmmsMaintenanceplanRuleId: ID!) {
            deleteHovedPlan(rmms_maintenanceplan_rule_id: $rmmsMaintenanceplanRuleId) { response }
            }`,
            variables: {
                rmmsMaintenanceplanRuleId: id
            }
        })

        dispatch(setPlanIsProcessing(false))

        if (!!resp && !!resp.deleteHovedPlan && !!resp.deleteHovedPlan.response) {
            dispatch(planSecDeleted(id))
            toast.success('Deleted Successfully')
        }
    } catch (err) {
        console.log('deleteSecPlan Error:', err);
        dispatch(setPlanIsProcessing(false))
    }
}


/**
 * Show Plan On Map
 * @param {*} id 
 * @returns 
 */
export const showPlanOnMap = id => async (dispatch) => {
    dispatch(setPlanIsProcessing(true))
    try {
        const resp = await handleRequest("post", false, {
            query: `query getCoordinatesForSection($getCoordinatesForSectionId: ID!) {
                getCoordinatesForSection(id: $getCoordinatesForSectionId) {
                  x_coordinate
                  y_coordinate
                }
              }`,
            variables: {
                getCoordinatesForSectionId: id
            }
        })

        dispatch(setPlanIsProcessing(false))

        if (!!resp && !!resp.getCoordinatesForSection) {
            const { x_coordinate, y_coordinate } = resp.getCoordinatesForSection
            dispatch(setMapLatLong([x_coordinate, y_coordinate], 15))
        }
    } catch (err) {
        console.log('showPlanOnMap Error:', err);
        dispatch(setPlanIsProcessing(false))
    }
}