import React from 'react'
import { colorCodes } from '../../../constant/colors'

const Edit = ({
    fill = colorCodes.BLUE_3,
    height = 32,
    width = 32,
    style = {},
    viewBox = '0 0 32 32',
    className = '',
    onClick
}) => (
    <svg width={width} height={height} viewBox={viewBox} style={style} className={className} onClick={e => !!onClick && onClick(e)}>
        <g id="edit" transform="translate(-388 -281)">
            <circle id="Ellipse_6" data-name="Ellipse 6" cx="16" cy="16" r="16" transform="translate(388 281)" fill="#f1f3ff" />
            <g id="edit-2" data-name="edit" transform="translate(398.306 290.18)">
                <g id="Group_62" data-name="Group 62" transform="translate(0 0.946)">
                    <g id="Group_61" data-name="Group 61">
                        <path id="Path_77" data-name="Path 77" d="M10.884,39.816a.473.473,0,0,0-.473.473v4.732a.473.473,0,0,1-.473.473H1.42a.473.473,0,0,1-.473-.473V35.557a.473.473,0,0,1,.473-.473H7.1a.473.473,0,1,0,0-.946H1.42A1.42,1.42,0,0,0,0,35.557v9.464a1.42,1.42,0,0,0,1.42,1.42H9.938a1.42,1.42,0,0,0,1.42-1.42V40.289A.473.473,0,0,0,10.884,39.816Z" transform="translate(0 -34.137)" fill={fill} />
                    </g>
                </g>
                <g id="Group_64" data-name="Group 64" transform="translate(2.839 0)">
                    <g id="Group_63" data-name="Group 63" transform="translate(0 0)">
                        <path id="Path_78" data-name="Path 78" d="M112.289.534a1.811,1.811,0,0,0-2.562,0l-6.233,6.233a.478.478,0,0,0-.114.185l-.946,2.839a.473.473,0,0,0,.449.623.481.481,0,0,0,.15-.024l2.839-.946a.473.473,0,0,0,.185-.115L112.289,3.1A1.812,1.812,0,0,0,112.289.534Zm-.669,1.893-6.152,6.152-1.838.614.612-1.836L110.4,1.206a.865.865,0,1,1,1.224,1.222Z" transform="translate(-102.409 -0.004)" fill={fill} />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Edit