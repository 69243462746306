import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { onClickNavMenu, reArrangeIconPos, setActiveNav, setClickedNavs, setMenuState } from "../../../actions/navbar"
import { MOVEABLE_TOP_POS } from "../../../constant"
import Icon from "../Icons"
import "./index.scss"

const MoveAbleWindow = ({
    headerSec = true,
    title = '', className = '', toggleIcon = 'double-arrow',
    selectorId = '', minimize = false, children
}) => {
    const wrapperRef = useRef()
    const dispatch = useDispatch()
    const { clickedNavs, menuState } = useSelector(state => state.navbar)
    const [isOpen, setIsOpen] = useState(true)

    let newPosX = 0, newPosY = 0, startPosX = 0, startPosY = 0;

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setIsOpen(minimize)
        // eslint-disable-next-line
    }, [minimize])

    const toggleState = status => {

        if (!!wrapperRef &&
            (wrapperRef.current !== null)) {
            const elem = wrapperRef.current
            if (!!elem && !!elem.style) {

                // Set the element's new position
                if (!status) {
                    setTimeout(() => {
                        elem.style.left = "0px";
                    }, 1000)
                    reArrangeIconPos()
                } else {
                    elem.style.top = MOVEABLE_TOP_POS + "px";
                    elem.style.left = "70px";
                    if (!!selectorId) {
                        dispatch(onClickNavMenu(selectorId, clickedNavs))
                    }
                }
            }
        }
        setIsOpen(status)
    }

    const handleMouseDown = (e) => {
        if (!!wrapperRef &&
            (wrapperRef.current !== null) &&
            wrapperRef.current !== e.target) {
            return false
        }

        e.preventDefault()

        if (!!wrapperRef &&
            (wrapperRef.current !== null)) {
            const elem = wrapperRef.current
            if (!!elem && !!elem.style) {
                elem.classList.remove("full-transition")
                elem.classList.add("some-transition")
            }
        }
        // Get the starting position of the cursor
        startPosX = e.clientX
        startPosY = e.clientY

        document.addEventListener('mousemove', mouseMove);
        document.addEventListener('mouseup', function () {
            if (!!wrapperRef &&
                (wrapperRef.current !== null)) {
                const elem = wrapperRef.current
                if (!!elem && !!elem.style) {
                    elem.classList.add("full-transition")
                    elem.classList.remove("some-transition")
                }
            }
            document.removeEventListener('mousemove', mouseMove);
        })
    }


    const mouseMove = (e) => {
        // Calculate the new position
        newPosX = startPosX - e.clientX;
        newPosY = startPosY - e.clientY;

        // With each move we also want to update the start X and Y
        startPosX = e.clientX
        startPosY = e.clientY

        if (!!wrapperRef &&
            (wrapperRef.current !== null)) {
            const elem = wrapperRef.current
            if (!!elem && !!elem.style) {

                // Set the element's new position
                elem.style.top = (elem.offsetTop - newPosY) + "px";
                elem.style.left = (elem.offsetLeft - newPosX) + "px";
            }
        }
    }

    const handleOnCross = () => {
        const selectorIdx = clickedNavs.indexOf(selectorId)
        if (selectorIdx > -1) {
            const newClickedNavs = clickedNavs.filter(c => c !== selectorId)
            const newMenuStatus = menuState.filter(c => c.name !== selectorId)
            dispatch(setClickedNavs(newClickedNavs))
            dispatch(setActiveNav(false))
            dispatch(setMenuState([...newMenuStatus]))
        }
    }

    return (
        <div className={`moveable-window-container center-flex full-transition ${isOpen ? '' : 'hide-info-tile'} ${className}`} ref={wrapperRef}>
            <div className={`moveable-window-container-tooltip`}><div className="elipsis-title">{title}</div></div>
            {
                !!headerSec && <div className="moveable-window-header-section">
                    <div className="moveable-window-header-title">{title}</div>
                    <div className="moveable-window-header-action-tool">

                        <div className={`svg-img-holder between-flex ${isOpen ? '' : 'info-hide'}`} onClick={() => !isOpen ? toggleState(!isOpen) : null}>
                            {
                                isOpen ? <>
                                    <Icon name="double-arrow" className="toggle-btn" onClick={() => toggleState(!isOpen)} />
                                    <Icon name="cross" width="14" height="14" className="cross-btn ml-15" onClick={() => handleOnCross()} />
                                </> : <Icon name={toggleIcon} className="toggle-btn" title={title} />
                            }
                        </div>
                    </div>
                </div>
            }
            <div className={`moveable-window-content ${isOpen ? '' : 'hide-info-tile'}`}>
                {children}
            </div>
        </div>
    )
}

export default MoveAbleWindow