import { SET_ROAD_ATTR_ARR } from "../actions/searchRdAttr"

const initialState = {
    roadArr: []
}

const searchRdAttr = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROAD_ATTR_ARR:
            return Object.assign({}, state, action.payload)
        default:
            return state
    }
}

export default searchRdAttr