import {
    EDIT_PLAN_SEC_SUCCESS, MOVE_PLAN_SUCCESS, M_PLAN_DELETED, PLAN_IS_PROCESSING,
    SEC_PLAN_DELETED, SET_ALL_PLANS, SET_PARENT_PLAN_ID, SET_PLAN_SECTIONS, SET_SELECTED_PLAN_SECTIONS
} from "../actions/plans"

const initialState = {
    plans: [],
    ruleId: false,
    planSections: [],
    selectedPlanSections: [],
    isProcessing: false
}

const plans = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_PLANS:
        case PLAN_IS_PROCESSING:
        case SET_PLAN_SECTIONS:
        case SET_SELECTED_PLAN_SECTIONS:
        case SET_PARENT_PLAN_ID:
            return Object.assign({}, state, action.payload)
        case SEC_PLAN_DELETED: {
            let allPlanSections = (state.planSections).filter(a => a.id !== action.planSecId)
            return {
                ...state,
                isProcessing: false,
                planSections: allPlanSections
            }
        }
        case M_PLAN_DELETED: {
            let allPlans = (state.plans).filter(a => a.rmms_maintenanceplan_rule_id !== action.maintenId)
            return {
                ...state,
                isProcessing: false,
                plans: allPlans
            }
        }
        case MOVE_PLAN_SUCCESS: {
            let allPlans = (state.plans).map(a => {
                if (Number(a.id) === Number(action.id)) {
                    return { ...a, ...action.plan }
                } else {
                    return a
                }
            })

            return {
                ...state,
                isProcessing: false,
                plans: allPlans
            }
        }
        case EDIT_PLAN_SEC_SUCCESS: {
            let allPlanSections = (state.planSections).map(a => {
                if (a.id === action.secId) {
                    return { ...a, ...action.planSec }
                } else {
                    return a
                }
            })

            return {
                ...state,
                isProcessing: false,
                planSections: allPlanSections
            }
        }
        default:
            return state
    }
}

export default plans