import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'
import { deleteStrategy, fetchAllStrategies, setStrategyId, storeMeasurementList } from "../../../actions/strategies"
import CommonTable from "../../../components/common/CommonTable"
import PopUp from "../../../components/common/PopUp"
import SpinLoader from "../../../components/common/SpinLoader"
import WaywizeButton from "../../../components/common/WaywizeButton"
import { handleRequest } from "../../../constant/helpers"
import { UnitCosts } from "../../unitCosts"
import AddStrategy from "./AddStrategy"
import EditStrategy from "./EditStrategy"
import { changeMapOverlay } from "../../../actions/roads"
import Icon from '../../../components/common/Icons'
import { colorCodes } from "../../../constant/colors"
import CustomPopUp from "../../../components/common/CustomPopUp"
import { toast } from "react-toastify"
import { onClickNavMenu } from "../../../actions/navbar"
import "./index.scss"

const columns = [
  { Header: "table.name", accessor: "name", Filter: false },
  { Header: "table.budget", accessor: "budget", Filter: false }
];

const Strategies = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showUC, setShowUC] = useState(false)
  const [formType, setFormType] = useState(false)
  const [strategyInfo, setStrategyInfo] = useState({})

  // Getting all the state values
  const { strategies, isProcessing } = useSelector(state => state.strategies)
  const { clickedNavs } = useSelector(state => state.navbar)
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchAllStrategies());
    // eslint-disable-next-line
  }, [])

  const editStrategyFn = (info) => {
    setStrategyInfo(info)
    setFormType('edit')
    setShow(true)
  }

  const handleOnHide = () => {
    setShow(false)
    setFormType(false)
  }

  const onSuccess = () => {
    setShow(false)
    dispatch(fetchAllStrategies())
  }

  const onSelectOption = async (id) => {
    setIsLoading(true);
    try {
      const dateObj = new Date()
      const resp = await handleRequest("post", false, {
        query: `
        query SelectCalculationItem($data: selectParameter) {
          selectCalculationItem(data: $data) {
            totalCost
            selected {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
            notSelected {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
            goodCondition {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
          }
        }`,
        variables: {
          data: {
            id,
            finalPlan: false,
            maintenanceplanYear: dateObj.getFullYear()
          }
        }
      });
      dispatch(storeMeasurementList(resp.selectCalculationItem));
      dispatch(changeMapOverlay("strategy"));
      setIsLoading(false);
      onSuccess();
    } catch (err) {
      console.log('On Select Strategy Error:', err);
      setIsLoading(false);
    }
  };

  const onCustomBtnClick = async (id) => {
    setIsLoading(true);
    try {
      const dateObj = new Date()
      const resp = await handleRequest("post", false, {
        query: `
        query SelectCalculationItem($data: selectParameter) {
          selectCalculationItem(data: $data) {
            totalCost
            selected {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
            notSelected {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
            goodCondition {
              roadname
              start_distance
              end_distance
              rmms_linetype_id
              length
            }
          }
        }`,
        variables: {
          data: {
            id,
            finalPlan: true,
            maintenanceplanYear: dateObj.getFullYear()
          }
        }
      });

      console.log('checkResp - WIP ', resp)
      if (!!resp && !!resp.selectCalculationItem) {
        dispatch(onClickNavMenu('maintenance_plan', clickedNavs))
        dispatch(setStrategyId(id))
        dispatch(changeMapOverlay("strategy"))
        toast.success('Added to Maintenance Plan')
      } else if (!!resp.data && !!resp.data.errors && !!resp.data.errors.length && !!resp.data.errors[0].message) {
        toast.error(resp.data.errors[0].message)
      }
      setIsLoading(false)
    } catch (err) {
      console.log('On onCustomBtnClick Error:', err);
      setIsLoading(false)
    }
  }

  return (
    <>
      {((!!isProcessing && !show) || !!isLoading) && <SpinLoader />}
      <div className="between-flex mb-20 mt-10 ml-25 mr-25 stg-btn-holder">
        <WaywizeButton onClick={() => setShowUC(true)} title={t('button.unit_cost')} className="unit-cost-btn" />
        <WaywizeButton onClick={() => setShow(true)} title={t('button.add_new')} className="add-strategy-btn center-flex">
          <Icon height={25} width={25} viewBox="0 0 25 25" name="plus" fill={colorCodes.BLUE_3} />
          <div>{t('button.add_new')}</div>
        </WaywizeButton>
      </div>
      {
        !!show && <PopUp title={`${formType === 'edit' ? t('strategy.edit_strategy') : t('strategy.add_new')}`} show={show} onHide={() => handleOnHide()}>
          {
            formType === 'edit' ?
              <EditStrategy isProcessing={isProcessing} info={strategyInfo} onSuccess={() => setShow(false)} /> :
              <AddStrategy isProcessing={isProcessing} onSuccess={onSuccess} />
          }
        </PopUp>
      }

      {
        !!showUC && <CustomPopUp title={t('button.unit_cost')} className="unit-cost-popup" handleClose={() => setShowUC(false)}>
          <UnitCosts inPopUp={true} />
        </CustomPopUp>
      }
      {
        !!strategies.length ? <CommonTable columns={columns}
          data={strategies}
          editFn={(info) => editStrategyFn(info.original || {})}
          customFn={r => !!r && !!r.original && !!r.original.id && onCustomBtnClick(r.original.id)}
          customFnTxt="table.add_to_mntn"
          showActiveRow={true}
          selectFn={r => !!r && !!r.original && !!r.original.id && onSelectOption(r.original.id)}
          deleteFn={r => !!r && !!r.original && !!r.original.id && dispatch(deleteStrategy(r.original.id))}
          deleteMsg="This strategy will be permanently deleted from the data"
          actionBtn={true}
          selectedRows={[]} /> :
          <h3 className="center-flex h3-fetching-text">{!!isProcessing ? `${t('strategy.fetching')}...` : t('strategy.no_records')}</h3>
      }
    </>
  );
};

export default Strategies;