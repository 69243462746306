const { REACT_APP_B2C_AUTHORITY_DOMAIN, REACT_APP_B2C_TENANT_ID, REACT_APP_B2C_APP_ID, REACT_APP_B2C_API_SCOPE, REACT_APP_UI_URL } = require("../constant");

const authorityDomain = REACT_APP_B2C_AUTHORITY_DOMAIN;
const tenantId = REACT_APP_B2C_TENANT_ID;

const config = {
    appId: REACT_APP_B2C_APP_ID,
    graphScopes: ["https://graph.microsoft.com/User.Read"],
    apiScopes: [REACT_APP_B2C_API_SCOPE],
    b2cPolicies: {
        names: {
            signUpSignIn: "B2C_1A_RMMS_SIGNUPSIGNIN ",
            forgotPassword: "B2C_1A_RMMS_PASSWORDRESET",
            editProfile: "B2C_1A_RMMS_PROFILEEDIT"
        },
        authorities: {
            signUpSignIn: {
                authority: `https://${authorityDomain}/${tenantId}/B2C_1A_RMMS_SIGNUPSIGNIN`,
            },
            editProfile: {
                authority: `https://${authorityDomain}/${tenantId}/B2C_1A_RMMS_PROFILEEDIT`
            },
            forgotPassword: {
                authority: `https://${authorityDomain}/${tenantId}/B2C_1A_RMMS_PASSWORDRESET`
            }
        },
        authorityDomain
    }
};

module.exports = {
    apiScopes: config.apiScopes,
    msalConfig: {
        auth: {
            clientId: REACT_APP_B2C_APP_ID,
            authority: config.b2cPolicies.authorities.signUpSignIn.authority,
            knownAuthorities: [config.b2cPolicies.authorityDomain],
            redirectUri: REACT_APP_UI_URL,
            postLogoutRedirectUri: REACT_APP_UI_URL
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        }
    }
};
